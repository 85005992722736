import React, { Component } from 'react';
import { Accordion } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { podcastActionCreators } from '../../../stores/podcast-store';
import Collapsible from 'react-collapsible';
import PodcastTable from '../../podcasts/PodcastTable';
import SearchPodcasts from '../../podcasts/SearchPodcasts';
import PodcastGenreDraggableComponent from '../../podcasts/podcast-genre-dnd';
import logo from '../../../images/oMEJHyoQ.png';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import Select from 'react-select';

class SlowPodcast extends Component {
    static displayName = SlowPodcast.name;

    constructor(props) {
        super(props);

        let storedGenreRankings = localStorage.getItem("PodcastGenreRankings");
        if (storedGenreRankings) {
            try {
                storedGenreRankings = JSON.parse(storedGenreRankings)

                if (storedGenreRankings && (!storedGenreRankings[0] || storedGenreRankings.find(it => !it.Genre || !it.Genre.Name || !it.Ranking))) {
                    //the object isn't how it should be
                    storedGenreRankings = null
                }

            } catch (e) {
                //likely not a proper json object, but regardless, just don't use it
                storedGenreRankings = null
            }
        }

        this.state = {
            loading: true,
            gettingSimilarPodcasts: false,
            gettingPodcastsByGenre: false,
            genreOptions: [],
            podcastsByGenreError: '',
            similarPodcastsError: '',
            selectOptions: [],
            displayGenres: [...new Set(["True Crime", "Society & Culture", "Business", "Comedy", "Science", "Sports", "Technology", "Health & Fitness", "History", "Leisure", "News"].concat(storedGenreRankings ? storedGenreRankings.map(it => it.Genre.Name) : []))],
            genreInputError: '', //error that will display on the genre input
            genreErrors: [], //error that will display on a bubble genre option
            numPodcasts: 1,
            newGenre: '',
            recommendations: {
                podcast: {
                    podcastsByGenre: [],
                    similarPodcasts: []
                }
            },
            inputs: {
                Mood: {
                    Happiness: 0,
                    Excitement: 0,
                    Anger: 0
                },
                ActivityInterests: {
                    Sports: 0,
                    BarsBreweries: 0,
                    Nature: 0
                },
                PodcastPreferences: {
                    GenreRankings: storedGenreRankings ? storedGenreRankings :
                        [
                            {
                                Genre: { Name: "True Crime" }, Ranking: 1
                            },
                            {
                                Genre: { Name: "Comedy" }, Ranking: 2
                            },
                            {
                                Genre: { Name: "Sports" }, Ranking: 3
                            },
                        ],
                    FavoritePodcasts: [
                        { Title: "", Id: "" }
                    ]
                }
            },
            nextSection: true,
            pageIndex: 0
        };

        this.handleMoodChange = this.handleMoodChange.bind(this)
        this.handleGenreRankingChange = this.handleGenreRankingChange.bind(this)
        this.handleFavoritePodcastsChange = this.handleFavoritePodcastsChange.bind(this)
        this.getGenres = this.getGenres.bind(this)
        this.getSimilarPodcasts = this.getSimilarPodcasts.bind(this)
        this.getPodcastsByGenre = this.getPodcastsByGenre.bind(this)
        this.addPodcast = this.addPodcast.bind(this)
        this.handleNewGenreChange = this.handleNewGenreChange.bind(this);
        this.onInsert = this.onInsert.bind(this)
        this.removeGenre = this.removeGenre.bind(this)
        this.closeGenresError = this.closeGenresError.bind(this)
        this.next = this.next.bind(this);
        this.changeCriteria = this.changeCriteria.bind(this);
    }

    componentDidMount() {
        if (this.props.title) { document.title = this.props.title }

        this.getGenres();
    }

    getGenres() {
        this.props.actions.getPodcastGenres()
            .then(() => {
                let genres = [...this.props.podcast.podcastGenres.filter(it => it.name.toLowerCase() !== "podcasts")];

                //set IDs
                let existingGenres = [...this.state.inputs.PodcastPreferences.GenreRankings];
                let genreNames = genres.map(a => a.name.toLowerCase());
                for (const genre of existingGenres) {
                    if (genreNames.includes(genre.Genre.Name.toLowerCase())) {
                        const genreId = genres.find(it => it.name.toLowerCase() === genre.Genre.Name.toLowerCase()).id;
                        genre.Genre.Id = genreId
                    }
                }

                let selectOptions = [];
                genres.forEach(genre => {
                    selectOptions.push({ label: genre.name, value: genre.name })
                })
                this.setState({
                    genreOptions: genres,
                    selectOptions
                })
            })
            .catch(err => {
                if (err === 'Aborted') {
                    return;
                }
                console.log(err)
            });
    }

    handleMoodChange(field, e) {
        let Mood = this.state.inputs.Mood;

        let value = ""
        const isCheckbox = e.target.type === 'checkbox';
        const isSpan = e.target.tagName.toLowerCase() === 'span';
        if (isCheckbox) {
            value = e.target.checked;
        } else if (isSpan) {
            value = e.target.textContent;
        } else {
            value = e.target.value;
        }
        const maxLength = e.target.maxLength;
        const finalValue = (!isCheckbox && !isSpan && maxLength && maxLength > 0) ? value.slice(0, maxLength) : value;
        Mood[field] = parseInt(finalValue);
        this.setState({ Mood });
    }

    removeGenre(id, name) {
        let tempArray = this.state.inputs.PodcastPreferences.GenreRankings;

        let matchingGenre;
        if (id && id !== "0") {
            matchingGenre = tempArray.findIndex(it => it.Genre.Id === id)
        } else {
            matchingGenre = tempArray.findIndex(it => it.Genre.Name.toLowerCase() === name.toLowerCase())
        }

        if (matchingGenre !== undefined && matchingGenre !== -1) {
            tempArray.splice(matchingGenre, 1);
            //rank
            tempArray.sort((a, b) => a.Ranking > b.Ranking).forEach((item, index) => {
                item.Ranking = index + 1;
            })
            this.setState({ tempArray })
        }

        this.setState({ genreErrors: [], genreInputError: '' })
    }

    addPodcast() {
        this.setState(prevState => ({ numPodcasts: prevState.numPodcasts + 1 }))
    }

    // HANDLE NEW GENRE NAME CHANGE
    handleNewGenreChange(selectedOption) {
        this.setState({
            selectedOption,
            newGenre: selectedOption.label
        });
    }

    // ADD TO THE GRID
    onInsert(genre, fromInputElement, e) {
        if (e) { e.preventDefault() }
        if (genre.length > 0) {
            //don't allow duplicates
            if (this.state.inputs.PodcastPreferences.GenreRankings.find(it => it.Genre.Name.toLowerCase() === genre.toLowerCase())) {
                if (fromInputElement) {
                    this.setState({ genreInputError: "This genre is already ranked." });
                    return;
                }
                else if (this.state.displayGenres.find(it => it.toLowerCase() === genre.toLowerCase())) {
                    let genreErrors = this.state.genreErrors;
                    genreErrors.push({ genre: genre.toLowerCase(), error: "This genre is already ranked.", show: true })
                    this.setState({ genreErrors });
                    return;
                }
            }
            else if (this.state.inputs.PodcastPreferences.GenreRankings.length >= 3) {
                if (fromInputElement) {
                    this.setState({ genreInputError: "You may only select up to 3 genres. Please remove an existing selection and try again." });
                    return;
                }
                else if (this.state.displayGenres.find(it => it.toLowerCase() === genre.toLowerCase())) {
                    let genreErrors = this.state.genreErrors;
                    genreErrors.push({ genre: genre.toLowerCase(), error: "You may only select up to 3 genres. Please remove an existing selection and try again.", show: true })
                    this.setState({ genreErrors });
                    return;
                }
            }

            //add to displayed genres
            let displayGenres = this.state.displayGenres;
            if (!this.state.displayGenres.find(it => it.toLowerCase() === genre.toLowerCase())) { displayGenres.push(genre) }

            const fullGenre = {
                Ranking: this.state.inputs.PodcastPreferences.GenreRankings.length + 1, Genre: { Name: genre }
            }

            if (this.state.genreOptions.find(it => it.name.toLowerCase() === genre.toLowerCase())) {
                fullGenre.Genre.Id = this.state.genreOptions.find(it => it.name.toLowerCase() === genre.toLowerCase()).Id;
            }

            this.handleGenreRankingChange([...this.state.inputs.PodcastPreferences.GenreRankings, fullGenre])

            //reset
            this.setState({
                newGenre: '',
                selectedOption: '',
                displayGenres
            })
        }
    }

    closeGenresError(genre) {
        let genreErrors = this.state.genreErrors
        const error = genreErrors.findIndex(it => it.genre.toLowerCase() === genre.toLowerCase())
        genreErrors.splice(error, 1)
        this.setState({ genreErrors })
    }

    closeGenresInputError() {
        this.setState({ genreInputError: '' })
    }

    handleGenreRankingChange(genreRankings) {
        let podcastPreferences = this.state.inputs.PodcastPreferences;
        podcastPreferences.GenreRankings = genreRankings;
        this.setState({ podcastPreferences, genreError: "", genreErrors: [], genreInputError: '' })
    }

    handleFavoritePodcastsChange(index, id, title) {
        let favoritePodcasts = this.state.inputs.PodcastPreferences.FavoritePodcasts;
        if (!favoritePodcasts[index]) {
            const objsNeeded = (index + 1) - favoritePodcasts.length;
            for (let i = 0; i < objsNeeded; i++) {
                favoritePodcasts.push({})
            }
        }
        favoritePodcasts[index].Title = title;
        favoritePodcasts[index].Id = id || '';
        this.setState({ favoritePodcasts })
    }

    handleValidation() {
        //nothing for now
        return true;
    }

    getPodcastsByGenre() {
        if (!this.state.inputs.PodcastPreferences.GenreRankings.length || !this.state.inputs.PodcastPreferences.GenreRankings.find(it => it.Ranking || (it.Genre && it.Genre.Name))) {
            this.setState({
                podcastsByGenreError: 'You must provide your favorite genre(s) in order to be recommended podcasts based on the genre(s).',
                recommendations: {
                    podcast: {
                        ...this.state.recommendations.podcast,
                        podcastsByGenre: []
                    }
                }
            })
            return;
        }

        this.setState({
            podcastsByGenreError: '',
            gettingPodcastsByGenre: true,
            recommendations: {
                podcast: {
                    ...this.state.recommendations.podcast,
                    podcastsByGenre: []
                }
            },
        }, () => {
            const genreRankings = JSON.parse(JSON.stringify(this.state.inputs.PodcastPreferences.GenreRankings)) //make a deep copy so as to not update the state when updating the variable
            this.props.actions.getPodcastsByGenre(genreRankings)
                .then(() => {
                    let podcastRecommendations = [...this.props.podcast.podcastsByGenre];
                    if (podcastRecommendations.length === 0) { this.setState({ podcastsByGenreError: "Unable to find any recommendations", gettingPodcastsByGenre: false }); return; }
                    let recommendations = this.state.recommendations;
                    recommendations.podcast.podcastsByGenre = podcastRecommendations;
                    this.setState({
                        gettingPodcastsByGenre: false,
                        recommendations
                    })

                    localStorage.setItem("PodcastGenreRankings", JSON.stringify(this.state.inputs.PodcastPreferences.GenreRankings))
                })
                .catch(err => {
                    if (err === 'Aborted') {
                        return;
                    }
                    this.setState({
                        gettingPodcastsByGenre: false,
                        podcastsByGenreError: err
                    });
                });
        })
    }

    getSimilarPodcasts() {
        if (!this.state.inputs.PodcastPreferences.FavoritePodcasts.length || !this.state.inputs.PodcastPreferences.FavoritePodcasts.find(it => it.Title || it.Id)) {
            this.setState({
                similarPodcastsError: 'You must provide your favorite podcast(s) in order to be recommended similar podcasts.',
                recommendations: {
                    podcast: {
                        ...this.state.recommendations.podcast,
                        similarPodcasts: []
                    }
                }
            })
            return;
        }

        this.setState({
            similarPodcastsError: '',
            gettingSimilarPodcasts: true,
            recommendations: {
                podcast: {
                    ...this.state.recommendations.podcast,
                    similarPodcasts: []
                }
            },
        }, () => {
            const favoritePodcasts = JSON.parse(JSON.stringify(this.state.inputs.PodcastPreferences.FavoritePodcasts)) //make a deep copy so as to not update the state when updating the variable
            this.props.actions.getSimilarPodcasts(favoritePodcasts)
                .then(() => {
                    let similarPodcasts = [...this.props.podcast.similarPodcasts];
                    if (similarPodcasts.length === 0) { this.setState({ similarPodcastsError: "Unable to find any similar podcasts", gettingSimilarPodcasts: false }); return; }
                    let recommendations = this.state.recommendations;
                    recommendations.podcast.similarPodcasts = similarPodcasts;
                    this.setState({
                        gettingSimilarPodcasts: false,
                        recommendations
                    })

                    localStorage.setItem("PodcastGenreRankings", JSON.stringify(this.state.inputs.PodcastPreferences.GenreRankings))
                })
                .catch(err => {
                    if (err === 'Aborted') {
                        return;
                    }
                    this.setState({
                        gettingSimilarPodcasts: false,
                        similarPodcastsError: err
                    });
                });
        })
    }

    async getRecommendations(e) {
        e.preventDefault();

        if (!this.handleValidation()) {
            return;
        }

        this.next();

        this.getSimilarPodcasts();
        this.getPodcastsByGenre();
    }

    next(index) {
        const self = this;
        const { changingCriteria } = this.state;

        this.setState({ transitioning: true }, () => {
            let sections = document.getElementsByClassName('podcast-section');
            if (sections && sections.length) {
                if (!index && index !== 0) { index = sections.length - 1; }
                sections = Array.from(sections);
                const currentContent = sections[index];

                if (currentContent) {
                    currentContent.classList.remove('fade-in-delayed-2', 'fade-in-delayed-1');
                    currentContent.classList.add('fade-out');
                }

                const nextButton = document.getElementsByClassName('next-button');
                let cover = document.getElementsByClassName('cover');
                let newCoverElement;
                if (cover && cover.length) {
                    cover = Array.from(cover);
                    newCoverElement = cover.find(el => el.classList.contains('d-none'));
                }
                if (nextButton && nextButton.length) {
                    if (nextButton[0].classList.contains('fade-in-delayed-2')) {
                        nextButton[0].classList.remove('fade-in-delayed-2');
                        nextButton[0].classList.add(!changingCriteria ? 'fade-out-and-in' : 'fade-out-and-in-fast');
                    } else {
                        nextButton[0].classList.remove("fade-out-and-in", "fade-out-and-in-fast");
                        setTimeout(function () {
                            nextButton[0].classList.add(!newCoverElement || changingCriteria ? 'fade-out-and-in-fast' : "fade-out-and-in");
                        }, 100);
                    }
                }

                if (newCoverElement && !changingCriteria) {
                    newCoverElement.classList.remove('d-none');
                    newCoverElement.classList.add('fade-in-and-out-delayed');
                }

                if (sections && sections.length && sections[index + 1]) {
                    const nextSection = sections[index + 1]
                    nextSection.classList.remove('d-none');
                    nextSection.classList.add(!changingCriteria ? 'fade-in-delayed-2' : 'fade-in-delayed-1');
                }

                if (sections.length === index + 2) {
                    setTimeout(function () {
                        if (currentContent) {
                            currentContent.classList.add('d-none');
                        }
                        self.setState({ transitioning: false, ready: true, changingCriteria: false, pageIndex: index + 1 })
                    }, 2000);
                } else {
                    setTimeout(function () {
                        if (currentContent) {
                            currentContent.classList.add('d-none');
                        }
                        self.setState({ transitioning: false, ready: false, nextSection: sections.length > index + 1, pageIndex: index + 1 })
                    }, 2000);
                }
            } else {
                setTimeout(function () {
                    self.setState({ transitioning: false, ready: false, nextSection: false, pageIndex: index + 1 })
                }, 2000);
            }
        })
    }

    changeCriteria() {
        const self = this;
        this.setState({ changingCriteria: true }, () => {
            const resultSection = document.getElementById('result-section');
            if (resultSection) {
                const resultFades = resultSection.getElementsByClassName('fade-in-delayed-1');
                for (const el of resultFades) {
                    el.classList.remove('fade-in-delayed-1');
                    el.classList.add('fade-out');
                }

                const nextButton = document.getElementsByClassName('next-button');
                if (nextButton && nextButton.length) {
                    nextButton[0].classList.remove("fade-out-and-in", 'fade-out-and-in-fast', 'fade-out-and-in');
                    setTimeout(function () {
                        nextButton[0].classList.add('fade-out-and-in-fast');
                    }, 100);
                }

                let sections = document.getElementsByClassName('podcast-section');
                if (sections && sections.length) {
                    for (const section of sections) {
                        section.classList.add('d-none');
                    }
                    setTimeout(function () {
                        self.setState({
                            similarPodcastsError: '',
                            gettingSimilarPodcasts: false,
                            podcastsByGenreError: '',
                            gettingPodcastsByGenre: false,
                            recommendations: {
                                podcast: {
                                    ...self.state.recommendations.podcast,
                                    similarPodcasts: [],
                                    podcastsByGenre: []
                                }
                            },
                        }, () => {
                            self.next(-1);
                        });
                    }, 2000);
                }

            }
        })
    }

    render() {

        const renderTooltip = (info, genre) => (
            <Tooltip id="button-tooltip" hidden={genre ? !this.state.genreErrors.find(it => it.genre.toLowerCase() === genre.toLowerCase()) : !this.state.genreInputError}>
                <span>{info}</span>
                <br />
                <button type="button" aria-label="Close" className="btn btn-secondary btn-sm btn-block p-0 mt-1" onClick={() => genre ? this.closeGenresError(genre) : this.closeGenresInputError()}>
                    <span aria-hidden="true">Close</span>
                </button>
            </Tooltip>
        );

        const customStyles = {
            option: (base, state) => ({
                ...base,
                fontSize: '1.0em',
                color: '#0792A0',
                background: state.isFocused ? '#49759d30' : 'inherit',
            }),
            container: (base) => ({
                ...base,
                minWidth: '200px',
                maxWidth: '250px',
                margin: 'auto',
                display: 'inline-block'
            }),
            control: (base) => ({
                ...base,
                background: '#f1f1f1',
                border: '1px solid #d8d8d8',
                borderRadius: '0 0 5px 5px',
                minWidth: '200px',
                maxWidth: '250px',
                margin: 'auto'
            }),
            menu: (base) => ({
                ...base,
                width: '215px',
                marginTop: '1px',
                marginBottom: '80px',
                position: 'relative'
            }),
            menuList: (base) => ({
                ...base,
                marginTop: '1px',
                maxHeight: '300px'
            }),
            indicatorSeparator: (base) => ({
                ...base,
                display: "none"
            }),
            valueContainer: (base) => ({
                ...base,
                textAlign: 'left'
            })
        }

        return (
            <div>
                <img id="listen-notes-logo" src={logo} alt="Powered by Listen Notes" />
                <div id="podcasts" className="text-color-burlywood">
                    <div id="cover-container">
                        <h1 className="cover podcast component-header fade-in-and-out">PODCASTS</h1>
                        <div className="cover fade-in-and-out-delayed"><h1 className="circle-container">1</h1><h2>Choose up to 3 genres to be used in gathering your recommendations</h2></div>
                        <div className="cover d-none"><h1 className="circle-container">2</h1><h2>Choose up to 3 of your favorite podcasts</h2></div>
                    </div>
                    <div id="content" className="fade-in-delayed-2">
                        <div className="center-form">
                            <ul className="padding-inline-start-0 sections">
                                <div className="podcast-section">
                                    <h2 className="component-header">PODCASTS</h2>
                                    <div className="text-center criteria-info">
                                        <h6 className="mb-3 font-italic"><strong>Choose up to 3 genres to be used in gathering your recommendations</strong></h6>
                                        <PodcastGenreDraggableComponent
                                            name="podcast-genre"
                                            onChange={this.handleGenreRankingChange}
                                            items={this.state.inputs.PodcastPreferences.GenreRankings}
                                            genreOptions={this.state.genreOptions}
                                            removeGenre={this.removeGenre}
                                        />
                                        <div className="mx-auto mt-2" style={{ maxWidth: '500px' }}>
                                            {
                                                this.state.displayGenres.sort().map((option, i) =>
                                                    <OverlayTrigger
                                                        key={i}
                                                        show={this.state.genreErrors.find(error => error.genre.toLowerCase() === option.toLowerCase()) !== undefined}
                                                        placement="bottom"
                                                        overlay={renderTooltip(this.state.genreErrors.find(error => error.genre.toLowerCase() === option.toLowerCase()) ? this.state.genreErrors.find(error => error.genre.toLowerCase() === option.toLowerCase()).error : '', option)}
                                                    >
                                                        <button type="button"
                                                            className={`btn badge p-2 m-1${this.state.inputs.PodcastPreferences.GenreRankings.find(ranking => ranking.Genre.Name.toLowerCase() === option.toLowerCase()) ? ' badge-info text-light' : ' badge-light'}`}
                                                            onClick={() => this.state.inputs.PodcastPreferences.GenreRankings.find(ranking => ranking.Genre.Name.toLowerCase() === option.toLowerCase()) ? this.removeGenre(null, option) : this.onInsert(option)}>{option.toLowerCase()} <span
                                                                className={`fa${this.state.inputs.PodcastPreferences.GenreRankings.find(ranking => ranking.Genre.Name.toLowerCase() === option.toLowerCase()) ? ' fa-minus text-danger' : ' fa-plus text-primary'}`} />
                                                        </button>
                                                    </OverlayTrigger>
                                                )
                                            }
                                            <div className="mt-1">
                                                <span className="h6 font-italic text-primary">Additional Genres</span><br />
                                                <form onSubmit={(e) => this.onInsert(this.state.newGenre, true, e)}>
                                                    <OverlayTrigger
                                                        show={this.state.genreInputError !== ''}
                                                        placement="bottom"
                                                        overlay={renderTooltip(this.state.genreInputError)}
                                                    >
                                                        <Select
                                                            menuPortalTarget={document.querySelector('body')}
                                                            options={this.state.selectOptions.filter(genre => !this.state.inputs.PodcastPreferences.GenreRankings.map(a => a.Genre.Name.toLowerCase()).includes(genre.label.toLowerCase()) && !this.state.displayGenres.map(a => a.toLowerCase()).includes(genre.label.toLowerCase()))}
                                                            noOptionsMessage={() => { return (<span>No genres found</span>) }}
                                                            placeholder={"Search..."}
                                                            styles={customStyles}
                                                            onChange={this.handleNewGenreChange}
                                                            value={this.state.selectedOption}
                                                        />
                                                    </OverlayTrigger>
                                                    <button type="button" id={'insert-button'} disabled={!this.state.newGenre} className="button btn-outline-primary ml-2" onClick={() => this.onInsert(this.state.newGenre, true)}>Insert</button>
                                                </form>

                                            </div>
                                            {this.state.genreError &&
                                                <p className="text-danger font-weight-bold">{this.state.genreError}</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="podcast-section d-none">
                                    <h2 className="component-header">PODCASTS</h2>
                                    <div className="text-center criteria-info">
                                        <h6 className="mb-3 font-italic"><strong>Choose up to 3 of your favorite podcasts</strong></h6>
                                        <div>
                                            {Array.from({ length: this.state.numPodcasts }, (_, i) =>
                                                <div key={i} className="mb-1">
                                                    <SearchPodcasts
                                                        index={i}
                                                        onChange={this.handleFavoritePodcastsChange.bind(this)}
                                                        value={this.state.inputs.PodcastPreferences.FavoritePodcasts[i] ? this.state.inputs.PodcastPreferences.FavoritePodcasts[i].Title : ""}
                                                        id={this.state.inputs.PodcastPreferences.FavoritePodcasts[i] ? this.state.inputs.PodcastPreferences.FavoritePodcasts[i].Id : ""}
                                                        searchPodcasts={this.props.actions.searchPodcasts}
                                                        podcasts={this.props.podcast.podcasts}
                                                    />
                                                </div>
                                            )}
                                            <button type="button" disabled={this.state.numPodcasts >= 3} className="btn btn-sm btn-primary" onClick={this.addPodcast}>Add Another</button>
                                            {
                                                this.state.numPodcasts >= 3 &&
                                                <p>(Max of 3 allowed)</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </ul>
                            <div className="fade-in-delayed-2 next-button">
                                {this.state.ready ?
                                    <button className="btn btn-primary btn-lg btn-block" type="button" onClick={this.getRecommendations.bind(this)} disabled={this.state.transitioning || this.state.gettingPodcastsByGenre || this.state.gettingSimilarPodcasts}>Recommend Me!</button>
                                    :
                                    this.state.nextSection ?
                                        <button type="button" className="btn btn-primary btn-lg btn-block shadow-sm" onClick={() => this.next(this.state.pageIndex)} disabled={this.state.transitioning}>Next</button>
                                        :
                                        this.state.recommendations.podcast.podcastsByGenre.length || this.state.podcastsByGenreError ?
                                            <button type="button" className="btn btn-primary btn-lg btn-block shadow-sm" onClick={this.changeCriteria} disabled={this.state.transitioning}>Change Criteria</button>
                                            : null
                                }
                            </div>
                        </div>
                    </div>
                </div >

                <div id="result-section" className="podcast">
                    {
                        (this.state.gettingSimilarPodcasts || this.state.gettingPodcastsByGenre || this.state.recommendations.podcast.similarPodcasts.length || this.state.recommendations.podcast.podcastsByGenre.length || this.state.similarPodcastsError || this.state.podcastsByGenreError) &&
                        <div className="row justify-content-center text-monospace fade-in-delayed-1">
                            <div className="table-responsive fake-table">
                                <div className="justify-content-center">
                                    <Accordion defaultActiveKey="0">
                                        {
                                            <PodcastTable
                                                eventKey="0"
                                                retrieving={this.state.gettingSimilarPodcasts}
                                                error={this.state.similarPodcastsError && this.state.similarPodcastsError}
                                                podcast={this.state.recommendations.podcast.similarPodcasts}
                                                title="Podcasts similar to your favorites"
                                                refresh={this.getSimilarPodcasts}
                                                genreOptions={this.state.genreOptions}
                                            />
                                        }
                                        {
                                            <PodcastTable
                                                eventKey="1"
                                                retrieving={this.state.gettingPodcastsByGenre}
                                                error={this.state.podcastsByGenreError && this.state.podcastsByGenreError}
                                                podcast={this.state.recommendations.podcast.podcastsByGenre}
                                                title="Recommended podcasts based on your favorite genres"
                                                refresh={this.getPodcastsByGenre}
                                                genreOptions={this.state.genreOptions}
                                            />
                                        }
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <br />
                <br />
            </div >
        );
    }
}

export default connect(
    (state) => {
        const { podcast } = state;
        return {
            podcast
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, podcastActionCreators), dispatch)
        }
    }
)(SlowPodcast)