import { publicRequest } from '../services/request.service'

const API_URL = window.env ? window.env.API_SERVER : '';
const NO_CONTENT = 2;
const REQUEST_FAILURE = 1;
const REQUEST_SUCCESS = 0;

const podcastGenresSuccess = "PODCAST_GENRES_SUCCESS";
const podcastGenresFailure = "PODCAST_GENRES_FAILURE";

const searchPodcastsSuccess = "SEARCH_PODCAST_SUCCESS";
const searchPodcastsFailure = "SEARCH_PODCAST_FAILURE";

const similarPodcastsSuccess = "SIMILAR_PODCASTS_SUCCESS";
const similarPodcastsFailure = "SIMILAR_PODCASTS_FAILURE";

const podcastsByGenreSuccess = "PODCASTS_BY_GENRE_SUCCESS";
const podcastsByGenreFailure = "PODCASTS_BY_GENRE_FAILURE";

const initialState = {
    podcastGenres: [],
    podcastGenresError: '',
    podcasts: [],
    podcastsError: '',
    similarPodcasts: [],
    similarPodcastsError: '',
    podcastsByGenre: [],
    podcastsByGenreError: ''
};

export const podcastActionCreators = {

    receivePodcastGenres: resObj => ({
        type: podcastGenresSuccess,
        podcastGenres: resObj
    }),

    podcastGenresError: (message) => ({
        type: podcastGenresFailure,
        podcastGenresError: message
    }),

    getPodcastGenres: () => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_PODCAST_API_URL) + '/Podcast/api/GetPodcastGenres', 'GET', headers, '', '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(podcastActionCreators.podcastGenresError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(podcastActionCreators.receivePodcastGenres(result[1].genres))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(podcastActionCreators.receivePodcastGenres([]))
                        return Promise.reject('No genres found');
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receivePodcasts: resObj => ({
        type: searchPodcastsSuccess,
        podcasts: resObj
    }),

    podcastsError: (message) => ({
        type: searchPodcastsFailure,
        podcastsError: message
    }),

    searchPodcasts: (params) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_PODCAST_API_URL) + '/Podcast/api/SearchPodcasts', 'GET', headers, '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(podcastActionCreators.podcastsError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(podcastActionCreators.receivePodcasts(result[1].podcasts))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(podcastActionCreators.receivePodcasts([]))
                        return Promise.reject('No genres found');
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveSimilarPodcasts: resObj => ({
        type: similarPodcastsSuccess,
        similarPodcasts: resObj
    }),

    similarPodcastsError: (message) => ({
        type: similarPodcastsFailure,
        similarPodcastsError: message
    }),

    getSimilarPodcasts: (body) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_PODCAST_API_URL) + '/Podcast/api/getSimilarPodcasts', 'POST', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(podcastActionCreators.similarPodcastsError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(podcastActionCreators.receiveSimilarPodcasts(result[1].recommendations))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(podcastActionCreators.receiveSimilarPodcasts([]))
                        return Promise.reject('No recommendations found');
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receivePodcastsByGenre: resObj => ({
        type: podcastsByGenreSuccess,
        podcastsByGenre: resObj
    }),

    podcastsByGenreError: (message) => ({
        type: podcastsByGenreFailure,
        podcastsByGenreError: message
    }),

    getPodcastsByGenre: (body) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_PODCAST_API_URL) + '/Podcast/api/GetPodcastsByGenres', 'POST', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(podcastActionCreators.podcastsByGenreError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(podcastActionCreators.receivePodcastsByGenre(result[1].podcasts))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(podcastActionCreators.receivePodcastsByGenre([]))
                        return Promise.reject('No recommendations found');
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    }
};


export const reducer = (state, action) => {

    state = state || initialState;
    switch (action.type) {
        case podcastGenresSuccess:
            return Object.assign({}, state, {
                podcastGenres: action.podcastGenres,
                podcastGenresError: action.podcastGenresError
            })
        case podcastGenresFailure:
            return Object.assign({}, state, {
                podcastGenresError: action.podcastGenresError
            })
        case searchPodcastsSuccess:
            return Object.assign({}, state, {
                podcasts: action.podcasts,
                podcastsError: action.podcastsError
            })
        case searchPodcastsFailure:
            return Object.assign({}, state, {
                podcastsError: action.podcastsError
            })
        case similarPodcastsSuccess:
            return Object.assign({}, state, {
                similarPodcasts: action.similarPodcasts,
                similarPodcastsError: action.similarPodcastsError
            })
        case similarPodcastsFailure:
            return Object.assign({}, state, {
                similarPodcastsError: action.similarPodcastsError
            })
        case podcastsByGenreSuccess:
            return Object.assign({}, state, {
                podcastsByGenre: action.podcastsByGenre,
                podcastsByGenreError: action.podcastsByGenreError
            })
        case podcastsByGenreFailure:
            return Object.assign({}, state, {
                podcastsByGenreError: action.podcastsByGenreError
            })
        default:
            return state
    }
};