import * as Music from './stores/music-store';
import * as Podcast from './stores/podcast-store';
import * as Television from './stores/television-store';
import * as Activity from './stores/activity-store';
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

export default (history) => combineReducers({
    router: connectRouter(history),
    music: Music.reducer,
    podcast: Podcast.reducer,
    television: Television.reducer,
    activity: Activity.reducer
});