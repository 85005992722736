import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

// STYLE TO SHOW WHEN ITEM IS BEING DRAGGED
const getItemStyle = (isDragging, draggableStyle, pendingDeletion) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    // change background colour if dragging
    background: isDragging ? '#34495e' : 'white',
    color: isDragging ? 'white' : '#34495e',
    border: pendingDeletion ? 'red' : 'none',
    // styles we need to apply on draggables
    ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? '#8b9fb3' : '#666666',
});


class TelevisionGenreDraggableComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
        this.onDragEnd = this.onDragEnd.bind(this);
    }


    // WHEN DRAGGED ITEM HAS BEEN PLACED
    onDragEnd(result) {
        if (!result.destination) {
            return;
        }
        let newItems = [...this.props.items];
        let [removed] = newItems.splice(result.source.index, 1);

        newItems.splice(result.destination.index, 0, removed);
        // Reorder the Ranking value too!
        newItems.forEach((element, index) => {
            element.Ranking = index + 1
        });
        this.props.onChange(newItems);
    }

    render() {
        const { items } = this.props;

        const renderTooltip = (info) => (
            <Tooltip id="button-tooltip">
                {info}
            </Tooltip>
        );

        return (
            <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="genreDroppable">
                    {(provided, snapshot) => (
                        <div className="row">
                            <div className="col-auto m-auto">
                                <table
                                    className="table table-dark table-striped table-bordered television-genre-dnd"
                                    ref={provided.innerRef}
                                    style={getListStyle(snapshot.isDraggingOver)}
                                >
                                    <thead className="thead-dark" style={getListStyle(snapshot.isDraggingOver)}>
                                        <tr>
                                            <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 150, hide: 120 }}
                                                overlay={renderTooltip("Rankings influence the genre distribution of your recommendations. Drag & drop to adjust.")}
                                            >
                                                <th scope="col" id={'ranking-column'}>
                                                    <span>Ranking <span className="fa fa-info-circle" /></span>
                                                </th>
                                            </OverlayTrigger>
                                            <th scope="col" id={'genrename-column'}>Genre</th>
                                            <th scope="col" id={'grabber-column'}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {items.sort((a, b) => a.Ranking - b.Ranking).map((item, index) => (
                                            <Draggable key={`${item.Genre.Name}`} draggableId={`${item.Genre.Name}-${index}`} index={index}>
                                                {(provided, snapshot) => (
                                                    <tr
                                                        id={`item.Genre.Name-${index}`}
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style
                                                        )}
                                                    >
                                                        <td>{item.Ranking}</td>
                                                        <td>{item.Genre.Name}</td>
                                                        <td>
                                                            <button type="button"
                                                                className={`btn btn-sm btn-danger`}
                                                                onClick={() => this.props.removeGenre(item.Genre.TMDbId, item.Genre.Name)}
                                                            >Remove</button>
                                                        </td>
                                                    </tr>
                                                )}
                                            </Draggable>
                                        ))}

                                        {provided.placeholder}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        );
    }
}

export default TelevisionGenreDraggableComponent;