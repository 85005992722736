import React from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { TiRefresh } from 'react-icons/ti';
import { Spinner } from 'react-bootstrap';

class TvShowTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <Card style={{ overflowX: 'auto' }}>
                <Accordion.Toggle as={Card.Header} variant="link" eventKey={this.props.eventKey} className="bg-dark text-center">
                    <button type="button" className="btn btn-link text-light">{this.props.title}</button>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={this.props.eventKey}>
                    <React.Fragment>
                        {
                            this.props.error &&
                            <React.Fragment>
                                <span className="d-block" style={{ zIndex: '2' }}><TiRefresh size={35} onClick={this.props.refresh} className="refresh-icon text-primary" /></span>
                            </React.Fragment>
                        }
                        {
                            this.props.retrieving ?
                                <div className="spinner-collapse text-center">
                                    <Spinner animation="border" variant="primary" />
                                </div>
                                :
                                <Card.Body className="text-center align-middle bg-light py-1">
                                    {
                                        this.props.error ?
                                            <p className="card-text m-auto error-message">{this.props.error}</p>
                                            :
                                            <div className="row justify-content-center">
                                                <div className="table-responsive">
                                                    <table className="table table-sm table-primary table-striped table-bordered">
                                                        <thead>
                                                            <tr className="bg-primary text-light">
                                                                <th scope="col"><TiRefresh size={30} onClick={this.props.refresh} className="refresh-icon" />Show Title</th>
                                                                <th scope="col">Show Genre(s)</th>
                                                                <th scope="col">Trailer</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="text-monospace font-weight-bold">
                                                            {this.props.shows.map((option, i) =>
                                                                <tr key={i}>
                                                                    <td><div className="television-title-cell">{option.Name}</div></td>
                                                                    {
                                                                        this.props.genreOptions.find(it => option.GenreIds.includes(it.Id)) ?
                                                                            <td><div className="genres-cell">{this.props.genreOptions.filter(it => option.GenreIds.includes(it.Id)).map(obj => obj.Name).join(", ")}</div></td>
                                                                            :
                                                                            <td><div className="genres-cell"></div></td>
                                                                    }
                                                                    {
                                                                        option.VideoKey ?
                                                                            <td>
                                                                                <iframe title={`youtube-tv-${i}`} width="300" height="200"
                                                                                    src={`https://www.youtube.com/embed/${option.VideoKey}`}>
                                                                                </iframe>
                                                                            </td>
                                                                            :
                                                                            <td>N/A</td>
                                                                    }
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                    }
                                </Card.Body>
                        }
                    </React.Fragment>
                </Accordion.Collapse>
            </Card>
        )
    }
}
export default TvShowTable;