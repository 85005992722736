import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';

export class Layout extends Component {
    static displayName = Layout.name;

    render() {
        return (
            <div>
                <NavMenu handleNav={this.props.handleNav} />
                <Container id="full-content">
                    {this.props.children}
                </Container>
                <br />
            </div>
        );
    }
}
