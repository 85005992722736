import { Modal, Button } from 'react-bootstrap';
import React from 'react';
import { Spinner } from 'react-bootstrap';
import Collapsible from 'react-collapsible';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

class AboutArtistModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    getFollowerCountDisplay(num) {
        let count
        // Nine Zeroes for Billions 
        if (Math.abs(Number(num)) >= 1.0e+9) {
            count = Math.abs(Number(num)) / 1.0e+9
            //round two decimals
            count = (Math.round(count * 10) / 10).toString()
            count += "B"
        }
        // Six Zeroes for Millions 
        else if (Math.abs(Number(num)) >= 1.0e+6) {
            count = Math.abs(Number(num)) / 1.0e+6
            //round two decimals
            count = (Math.round(count * 10) / 10).toString()
            count += "M"
        }
        // Three Zeroes for Thousands
        else if (Math.abs(Number(num)) >= 1.0e+3) {
            count = Math.abs(Number(num)) / 1.0e+3
            //round two decimals
            count = (Math.round(count * 10) / 10).toString()
            count += "K"
        }

        return count
    }

    render() {
        const { modalArtist } = this.props;

        return (


            <Modal
                show={modalArtist !== null}
                onHide={() => this.props.handleCloseAboutArtist(modalArtist.artistId)}
                style={{ border: "none" }} >
                {modalArtist.fetching ?
                    <div className="spinner-collapse text-center no-border">
                        <Spinner animation="border" variant="primary" />
                    </div>
                    :
                    modalArtist.error ?
                        <Modal.Header closeButton>
                            <Modal.Body>Error ... {modalArtist.error}</Modal.Body>
                        </Modal.Header>
                        :
                        <React.Fragment>
                            <Modal.Header closeButton>
                                <Modal.Title style={{ lineHeight: "1" }} className="artist-modal-title">
                                    <div className="widget theme-light detail ">
                                        <a href={this.props.iOS ? modalArtist.artist.Uri : `https://open.spotify.com/artist/${modalArtist.artist.Id}`} target="_blank">
                                            <div className="img" style={{ borderRadius: "30px", backgroundImage: `url(${modalArtist.artist.Images[0].Url})` }} />
                                        </a>
                                        <div className="bd">
                                            <h1 className="font-weight-bold">
                                                <a href={this.props.iOS ? modalArtist.artist.Uri : `https://open.spotify.com/artist/${modalArtist.artist.Id}`} target="_blank">
                                                    {modalArtist.artist.Name}
                                                </a>
                                            </h1>
                                            {
                                                this.props.loggedIn ?
                                                    <button
                                                        title={`${this.props.userFollows.find(artistId => artistId === modalArtist.artist.Id) ? "Unfollow" : "Follow"} on Spotify`}
                                                        className="spotify-follow"
                                                        onClick={() => this.props.userFollows.find(artistId => artistId === modalArtist.artist.Id) ? this.props.unfollowArtist(modalArtist.artist.Id) : this.props.followArtist(modalArtist.artist.Id)}>
                                                        <span className="fa fa-spotify mr-2 spotify-icon" />
                                                        <span>{this.props.userFollows.find(artistId => artistId === modalArtist.artist.Id) ? "Unfollow" : "Follow"}</span>
                                                    </button>
                                                    :
                                                    <button onClick={this.props.openSpotify}>
                                                        <span className="fa fa-spotify mr-2 spotify-icon" />
                                                        <span>Login to follow</span>
                                                    </button>
                                            }
                                            <div className="count" data-followers={`{$modalArtist.artist.Followers.Total}`}>
                                                <div className="count-num front">{this.getFollowerCountDisplay(modalArtist.artist.Followers.Total)}</div>
                                                <div className="count-num back">+1</div>
                                            </div>
                                        </div>
                                    </div>
                                    <em className="h6 text-dark">{modalArtist.artist.Genres.join(", ")}</em>
                                </Modal.Title>
                            </Modal.Header>

                            <Modal.Body className="text-center dark-carousel pb-0 px-0">
                                <Collapsible
                                    trigger="Top Tracks"
                                    open={true}>
                                    {
                                        modalArtist.artist.ExternalUrls && modalArtist.artist.ExternalUrls.spotify &&
                                        <iframe className='card-img-top' src={modalArtist.artist.ExternalUrls.spotify.replace("/artist", "/embed/artist")} width="300" height="450" frameBorder="0" allow="encrypted-media"></iframe>
                                    }
                                </Collapsible>
                                <Collapsible
                                    trigger="Albums"
                                    open={false}>
                                    {
                                        modalArtist.artist.Albums &&
                                        <Carousel autoPlay={false} infiniteLoop={true} showStatus={false} showThumbs={true}>
                                            {
                                                [...new Map(modalArtist.artist.Albums.Items.map(item => [item['Name'], item])).values()].slice(0, 8).map((album, i) =>
                                                    <div key={i} className="text-dark col">
                                                        <em className="h6">{album.Name}</em>
                                                        <br />
                                                        <iframe key={i} src={album.ExternalUrls.spotify.replace("/album", "/embed/album")} width="300" height="450" frameBorder="0" allow="encrypted-media"></iframe>
                                                        <br />
                                                    </div>
                                                )
                                            }
                                        </Carousel>
                                    }
                                </Collapsible>
                            </Modal.Body>

                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => this.props.handleCloseAboutArtist(modalArtist.artistId)}>Close</Button>
                            </Modal.Footer>
                        </React.Fragment>
                }
            </Modal>

        )
    }
}
export default AboutArtistModal;