import { Modal, Button } from 'react-bootstrap';
import React from 'react';
import { Spinner } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

class ChartsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    getChartDisplayName(chartName) {
        switch (chartName) {
            case 'Top Songs - USA': return 'Top 50 - USA (Weekly)'
            case 'Top 50 - USA': return 'Top 50 - USA (Daily)'
            case 'Top Songs - Global': return 'Top 50 - Global (Weekly)'
            case 'Top 50 - Global': return 'Top 50 - Global (Daily)'
            default: return chartName;
        }
    }

    render() {
        const { data, show, fetching, error, title } = this.props;

        return (

            <Modal
                show={show}
                onHide={this.props.handleClose.bind(this)}
                style={{ border: "none" }} >
                {fetching ?
                    <div className="spinner-collapse text-center no-border">
                        <Spinner animation="border" variant="primary" />
                    </div>
                    :
                    error ?
                        <Modal.Header closeButton>
                            <Modal.Body>Error ... {error}</Modal.Body>
                        </Modal.Header>
                        :
                        <React.Fragment>
                            <Modal.Header closeButton>
                                <Modal.Title className="text-center">{title}</Modal.Title>
                            </Modal.Header>

                            <Modal.Body className="text-center text-dark dark-carousel pb-0 px-0 text-center">
                                <Carousel autoPlay={false} infiniteLoop={true} showStatus={false} showThumbs={true}>
                                    {
                                        data.map((chart, i) =>
                                            <div key={i} className="bd col">
                                                <a className="btn spotify-link mb-0 float-none" href={this.props.iOS ? chart.Uri : `https://open.spotify.com/playlist/${chart.Id}`} target="_blank" rel="noopener noreferrer"><span className="fa fa-spotify mr-2 spotify-icon" /><span>{this.getChartDisplayName(chart.Name)}</span></a>
                                                <br />
                                                <small className="text-muted">{ReactHtmlParser(chart.Description)}</small>
                                                {
                                                    chart.ExternalUrls && chart.ExternalUrls.spotify &&
                                                    <iframe className='card-img-top' src={chart.ExternalUrls.spotify.replace("/playlist", "/embed/playlist")} width="300" height="450" frameBorder="0" allow="encrypted-media"></iframe>
                                                }
                                            </div>
                                        )
                                    }
                                </Carousel>
                            </Modal.Body>

                            <Modal.Footer>
                                <Button variant="secondary" onClick={this.props.handleClose.bind(this)}>Close</Button>
                            </Modal.Footer>
                        </React.Fragment>
                }
            </Modal>

        )
    }
}
export default ChartsModal;