import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { activityActionCreators } from '../../stores/activity-store';

class Activity extends Component {
    static displayName = Activity.name;

    constructor(props) {
        super(props);
        this.state = {
            inDevelopment: true,
            loading: true,
            fetching: false,
            recommendations: {
                activities: [],
                error: ''
            },
            inputs: {
                Mood: {
                    Happiness: 0,
                    Excitement: 0,
                    Anger: 0
                },
                ActivityInterests: {
                    Sports: 0,
                    BarsBreweries: 0,
                    Nature: 0
                }
            }
        };

        this.handleMoodChange = this.handleMoodChange.bind(this)
        this.handleActivityChange = this.handleActivityChange.bind(this)
    }

    componentDidMount() {
        if (this.props.title) { document.title = this.props.title }
    }

    handleMoodChange(field, e) {
        let Mood = this.state.inputs.Mood;

        let value = ""
        const isCheckbox = e.target.type === 'checkbox';
        const isSpan = e.target.tagName.toLowerCase() === 'span';
        if (isCheckbox) {
            value = e.target.checked;
        } else if (isSpan) {
            value = e.target.textContent;
        } else {
            value = e.target.value;
        }
        const maxLength = e.target.maxLength;
        const finalValue = (!isCheckbox && !isSpan && maxLength && maxLength > 0) ? value.slice(0, maxLength) : value;
        Mood[field] = parseInt(finalValue);
        this.setState({ Mood });
    }

    handleActivityChange(field, e) {
        let activity = this.state.inputs.ActivityInterests;

        let value = ""
        const isCheckbox = e.target.type === 'checkbox';
        const isSpan = e.target.tagName.toLowerCase() === 'span';
        if (isCheckbox) {
            value = e.target.checked;
        } else if (isSpan) {
            value = e.target.textContent;
        } else {
            value = e.target.value;
        }
        const maxLength = e.target.maxLength;
        const finalValue = (!isCheckbox && !isSpan && maxLength && maxLength > 0) ? value.slice(0, maxLength) : value;
        activity[field] = parseInt(finalValue);
        this.setState({ activity });
    }

    handleValidation() {
        //nothing for now
        return true;
    }

    async getRecommendations(e) {
        e.preventDefault();

        if (!this.handleValidation()) {
            return;
        }

        this.setState({
            recommendations: {
                activities: "",
                error: ""
            },
            fetching: true
        }, () => {
            const inputs = this.state.inputs
            this.props.actions.getActivityRecommendations(inputs)
                .then(() => {
                    let activityRecommendations = [...this.props.activity.activityRecommendations];
                    let recommendations = this.state.recommendations;
                    recommendations.activities = activityRecommendations;
                    this.setState({
                        fetching: false,
                        recommendations
                    })
                })
                .catch(err => {
                    if (err === 'Aborted') {
                        return;
                    }
                    this.setState({
                        fetching: false,
                    });
                });
        })

    }

    render() {
        return (
            <div>
                <div className="text-color-burlywood">
                    <h2 className="component-header">ACTIVITY</h2>
                    {
                        this.state.inDevelopment ?
                            <h3 className="text-center lead">In Development...</h3>
                            :
                            <>
                                <p className="text-center lead">If you need some help figuring out what to do, you've come to the right spot!</p>
                                <form className="center-form" onSubmit={this.getRecommendations.bind(this)}>
                                    <ul className="padding-inline-start-0">
                                        <li><strong>Mood</strong></li>
                                        <ol>
                                            <li>Happiness</li>
                                            <li>Excitement</li>
                                            <li>Anger</li>
                                        </ol>
                                        <hr />
                                        <li><strong>Activity Interests</strong></li>
                                        <ol>
                                            <li>Sports</li>
                                            <li>Bars/Breweries</li>
                                            <li>Outdoors/Nature</li>
                                        </ol>
                                    </ul>
                                    <hr />
                                    <button className="btn btn-primary btn-lg btn-block" type="submit" disabled={this.state.fetching}>Get Recommendations</button>
                                </form>
                            </>
                    }
                </div>
                {
                    this.state.fetching ? <p className="text-center"><em>Retrieving data...</em></p> :

                        this.state.recommendations.error !== "" ?
                            <p className="bold text-center">
                                Error: {this.state.recommendations.error}
                            </p>
                            : this.state.recommendations.activities.length > 0 &&
                            <React.Fragment>
                                <br />
                                <div className="row justify-content-center">
                                    <div className="col-auto">
                                        <table className="table table-dark table-striped table-bordered">
                                            <thead className="thead-dark">
                                                <tr>
                                                    <th scope="col">Artist Name</th>
                                                    <th scope="col">Song Title</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.recommendations.activities.sort((a, b) => a.Sequence - b.Sequence).map((option, i) =>
                                                    <tr key={i}>
                                                        <td>{option.ArtistName}</td>
                                                        <td>{option.SongTitle}</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </React.Fragment>
                }
                <br />
            </div>
        );
    }
}

export default connect(
    (state) => {
        const { activity } = state;
        return {
            activity
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, activityActionCreators), dispatch)
        }
    }
)(Activity)