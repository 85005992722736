import React, { Component } from 'react';
import { Accordion } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Collapsible from 'react-collapsible';
import SearchActors from '../../television/SearchActors';
import SearchMovies from '../../television/SearchMovies';
import SearchShows from '../../television/SearchShows';
import MovieTable from '../../television/MovieTable';
import { televisionActionCreators } from '../../../stores/television-store';
import TvShowTable from '../../television/TvShowTable';
import TelevisionGenreDraggableComponent from '../../television/television-genre-dnd';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import Select from 'react-select';

export class SlowTelevision extends Component {
    static displayName = SlowTelevision.name;

    constructor(props) {
        super(props);

        let storedMovieGenreRankings = localStorage.getItem("MovieGenreRankings");
        if (storedMovieGenreRankings) {
            try {
                storedMovieGenreRankings = JSON.parse(storedMovieGenreRankings)

                if (storedMovieGenreRankings && (!storedMovieGenreRankings[0] || storedMovieGenreRankings.find(it => !it.Genre || !it.Genre.Name || !it.Ranking))) {
                    //the object isn't how it should be
                    storedMovieGenreRankings = null
                }

            } catch (e) {
                //likely not a proper json object, but regardless, just don't use it
                storedMovieGenreRankings = null
            }
        }

        let storedTvShowGenreRankings = localStorage.getItem("TvShowGenreRankings");
        if (storedTvShowGenreRankings) {
            try {
                storedTvShowGenreRankings = JSON.parse(storedTvShowGenreRankings)

                if (storedTvShowGenreRankings && (!storedTvShowGenreRankings[0] || storedTvShowGenreRankings.find(it => !it.Genre || !it.Genre.Name || !it.Ranking))) {
                    //the object isn't how it should be
                    storedTvShowGenreRankings = null
                }

            } catch (e) {
                //likely not a proper json object, but regardless, just don't use it
                storedTvShowGenreRankings = null
            }
        }

        this.state = {
            movieGenreOptions: [],
            tvShowGenreOptions: [],
            fetchingSimilarMovies: false,
            fetchingSimilarMoviesError: '',
            fetchingMoviesByGenre: false,
            fetchingMoviesByGenreError: '',
            fetchingMoviesByActor: false,
            fetchingMoviesByActorError: '',
            fetchingSimilarShows: false,
            fetchingSimilarShowsError: '',
            fetchingShowsByGenre: false,
            fetchingShowsByGenreError: '',
            numActors: 1,
            numMovies: 1,
            numTvShows: 1,
            movieSelectOptions: [],
            displayMovieGenres: [...new Set(["Action", "Animation", "Fantasy", "Mystery", "Science Fiction", "War", "Comedy", "Crime", "Documentary", "Drama", "Horror", "Romance"].concat(storedMovieGenreRankings ? storedMovieGenreRankings.map(it => it.Genre.Name) : []))],
            newMovieGenre: '',
            displayTvShowGenres: [...new Set(["Action & Adventure", "Animation", "Comedy", "Crime", "Documentary", "Drama", "News", "Reality", "Sci-fi & Fantasy"].concat(storedTvShowGenreRankings ? storedTvShowGenreRankings.map(it => it.Genre.Name) : []))],
            newTvShowGenre: '',
            tvShowSelectOptions: [],
            movieGenreInputError: '', //error that will display on the genre input
            movieGenreErrors: [], //error that will display on a bubble genre option
            tvShowGenreInputError: '', //error that will display on the genre input
            tvShowGenreErrors: [], //error that will display on a bubble genre option
            recommendations: {
                similarMovies: [],
                similarShows: [],
                moviesByGenre: [],
                showsByGenre: [],
                moviesByActor: []
            },
            inputs: {
                ActivityInterests: {
                    Sports: 0,
                    BarsBreweries: 0,
                    Nature: 0
                },
                MoviePreferences: {
                    GenreRankings: storedMovieGenreRankings ? storedMovieGenreRankings :
                        [
                            {
                                Genre: { Name: "Action", TMDbId: 0 }, Ranking: 1
                            },
                            {
                                Genre: { Name: "Comedy", TMDbId: 0 }, Ranking: 2
                            },
                            {
                                Genre: { Name: "Drama", TMDbId: 0 }, Ranking: 3
                            },
                            {
                                Genre: { Name: "Horror", TMDbId: 0 }, Ranking: 4
                            },
                            {
                                Genre: { Name: "Science Fiction", TMDbId: 0 }, Ranking: 5
                            },
                        ],
                    FavoriteActors: [
                        { Name: "", TMDbId: 0 }
                    ],
                    FavoriteMovies: [
                        { Title: "", TMDbId: 0 }
                    ]
                },
                TvShowPreferences: {
                    GenreRankings: storedTvShowGenreRankings ? storedTvShowGenreRankings :
                        [
                            {
                                Genre: { Name: "Action & Adventure", TMDbId: 0 }, Ranking: 1
                            },
                            {
                                Genre: { Name: "Animation", TMDbId: 0 }, Ranking: 2
                            },
                            {
                                Genre: { Name: "Comedy", TMDbId: 0 }, Ranking: 3
                            },
                            {
                                Genre: { Name: "Drama", TMDbId: 0 }, Ranking: 4
                            },
                            {
                                Genre: { Name: "Reality", TMDbId: 0 }, Ranking: 5
                            },
                        ],
                    FavoriteShows: [
                        { Title: "", TMDbId: 0 }
                    ]
                }
            },
            nextSection: true,
            pageIndex: 0
        };

        this.getMovieGenres = this.getMovieGenres.bind(this);
        this.handleMovieGenreRankingChange = this.handleMovieGenreRankingChange.bind(this);
        this.handleTvShowGenreRankingChange = this.handleTvShowGenreRankingChange.bind(this);
        this.handleFavoriteMovieActorsChange = this.handleFavoriteMovieActorsChange.bind(this);
        this.handleFavoriteMoviesChange = this.handleFavoriteMoviesChange.bind(this);
        this.handleFavoriteTvShowsChange = this.handleFavoriteTvShowsChange.bind(this);
        this.getSimilarMovies = this.getSimilarMovies.bind(this);
        this.getSimilarShows = this.getSimilarShows.bind(this);
        this.getMoviesByGenre = this.getMoviesByGenre.bind(this);
        this.getMoviesByActor = this.getMoviesByActor.bind(this);
        this.getShowsByGenre = this.getShowsByGenre.bind(this);
        this.handleNewMovieGenreChange = this.handleNewMovieGenreChange.bind(this);
        this.onInsertMovieGenre = this.onInsertMovieGenre.bind(this)
        this.removeMovieGenre = this.removeMovieGenre.bind(this)
        this.handleNewTvShowGenreChange = this.handleNewTvShowGenreChange.bind(this);
        this.onInsertTvShowGenre = this.onInsertTvShowGenre.bind(this)
        this.removeTvShowGenre = this.removeTvShowGenre.bind(this)
        this.addActor = this.addActor.bind(this)
        this.addMovie = this.addMovie.bind(this)
        this.addTvShow = this.addTvShow.bind(this)
        this.closeMovieGenresError = this.closeMovieGenresError.bind(this)
        this.closeTvShowGenresError = this.closeTvShowGenresError.bind(this)
        this.next = this.next.bind(this);
        this.changeCriteria = this.changeCriteria.bind(this);
    }

    componentDidMount() {
        if (this.props.title) { document.title = this.props.title }

        this.getMovieGenres();
        this.getTvShowGenres();
    }

    getMovieGenres() {
        this.props.actions.getMovieGenres()
            .then(() => {
                let genres = [...this.props.television.movieGenres];
                let defaultGenres = [...this.state.inputs.MoviePreferences.GenreRankings]
                defaultGenres.filter(genre => genre.Genre.TMDbId === 0).map(genre => {
                    let matchingGenre = genres.find(it => it.Name.toLowerCase() === genre.Genre.Name.toLowerCase());
                    if (matchingGenre) {
                        genre.Genre.TMDbId = matchingGenre.Id
                    }
                })

                let selectOptions = [];
                genres.forEach(genre => {
                    selectOptions.push({ label: genre.Name, value: genre.Name })
                })

                this.setState({
                    movieGenreOptions: genres,
                    movieSelectOptions: selectOptions,
                    defaultGenres
                })
            })
            .catch(err => {
                if (err === 'Aborted') {
                    return;
                }
            });
    }

    getTvShowGenres() {
        this.props.actions.getTvShowGenres()
            .then(() => {
                let genres = [...this.props.television.tvShowGenres];
                let defaultGenres = [...this.state.inputs.TvShowPreferences.GenreRankings]
                defaultGenres.filter(genre => genre.Genre.TMDbId === 0).map(genre => {
                    let matchingGenre = genres.find(it => it.Name.toLowerCase() === genre.Genre.Name.toLowerCase());
                    if (matchingGenre) {
                        genre.Genre.TMDbId = matchingGenre.Id
                    }
                })

                let selectOptions = [];
                genres.forEach(genre => {
                    selectOptions.push({ label: genre.Name, value: genre.Name })
                })

                this.setState({
                    tvShowGenreOptions: genres,
                    tvShowSelectOptions: selectOptions,
                    defaultGenres
                })
            })
            .catch(err => {
                if (err === 'Aborted') {
                    return;
                }
            });
    }

    addActor() {
        this.setState(prevState => ({ numActors: prevState.numActors + 1 }))
    }

    addMovie() {
        this.setState(prevState => ({ numMovies: prevState.numMovies + 1 }))
    }

    addTvShow() {
        this.setState(prevState => ({ numTvShows: prevState.numTvShows + 1 }))
    }

    handleMovieGenreRankingChange(genreRankings) {
        let moviePreferences = this.state.inputs.MoviePreferences
        moviePreferences.GenreRankings = genreRankings;
        this.setState({ moviePreferences, movieGenreError: "", movieGenreErrors: [], movieGenreInputError: '' })
    }

    // HANDLE NEW GENRE NAME CHANGE
    handleNewMovieGenreChange(selectedOption) {
        this.setState({
            movieSelectedOption: selectedOption,
            newMovieGenre: selectedOption.label
        });
    }

    onInsertMovieGenre(genre, fromInputElement, e) {
        if (e) { e.preventDefault() }

        if (genre.length > 0) {
            //don't allow duplicates
            if (this.state.inputs.MoviePreferences.GenreRankings.find(it => it.Genre.Name.toLowerCase() === genre.toLowerCase())) {
                if (fromInputElement) {
                    this.setState({ movieGenreInputError: "This genre is already ranked." });
                    return;
                } else if (this.state.displayMovieGenres.find(it => it.toLowerCase() === genre.toLowerCase())) {
                    let genreErrors = this.state.movieGenreErrors;
                    genreErrors.push({ genre: genre.toLowerCase(), error: "This genre is already ranked.", show: true })
                    this.setState({ genreErrors });
                    return;
                }
            }
            else if (this.state.inputs.MoviePreferences.GenreRankings.length >= 5) {
                if (fromInputElement) {
                    this.setState({ movieGenreInputError: "You may only select up to 5 genres. Please remove an existing selection and try again." });
                    return;
                }
                else if (this.state.displayMovieGenres.find(it => it.toLowerCase() === genre.toLowerCase())) {
                    let genreErrors = this.state.movieGenreErrors;
                    genreErrors.push({ genre: genre.toLowerCase(), error: "You may only select up to 5 genres. Please remove an existing selection and try again.", show: true })
                    this.setState({ genreErrors });
                    return;
                }
            }

            //add to displayed genres
            let displayGenres = this.state.displayMovieGenres;
            if (!this.state.displayMovieGenres.find(it => it.toLowerCase() === genre.toLowerCase())) { displayGenres.push(genre) }

            const fullGenre = {
                Ranking: this.state.inputs.MoviePreferences.GenreRankings.length + 1, Genre: { Name: genre }
            }

            if (this.state.movieGenreOptions.find(it => it.Name.toLowerCase() === genre.toLowerCase())) {
                fullGenre.Genre.TMDbId = this.state.movieGenreOptions.find(it => it.Name.toLowerCase() === genre.toLowerCase()).Id;
            }

            this.handleMovieGenreRankingChange([...this.state.inputs.MoviePreferences.GenreRankings, fullGenre])

            //reset
            this.setState({
                newMovieGenre: '',
                movieSelectedOption: '',
                displayGenres
            })
        }

    }

    closeMovieGenresError(genre) {
        let genreErrors = this.state.movieGenreErrors
        const error = genreErrors.findIndex(it => it.genre.toLowerCase() === genre.toLowerCase())
        genreErrors.splice(error, 1)
        this.setState({ genreErrors })
    }

    closeMovieGenresInputError() {
        this.setState({ movieGenreInputError: '' })
    }

    removeMovieGenre(id, name) {
        let tempArray = this.state.inputs.MoviePreferences.GenreRankings;

        let matchingGenre;
        if (id && id !== "0") {
            matchingGenre = tempArray.findIndex(it => it.Genre.TMDbId === id)
        } else {
            matchingGenre = tempArray.findIndex(it => it.Genre.Name.toLowerCase() === name.toLowerCase())
        }

        if (matchingGenre !== undefined && matchingGenre !== -1) {
            tempArray.splice(matchingGenre, 1);
            //rank
            tempArray.sort((a, b) => a.Ranking > b.Ranking).forEach((item, index) => {
                item.Ranking = index + 1;
            })
            this.setState({ tempArray })
        }

        this.setState({ movieGenreErrors: [], movieGenreInputError: '' })
    }

    handleTvShowGenreRankingChange(genreRankings) {
        let genreRankingsState = this.state.inputs.TvShowPreferences;
        genreRankingsState.GenreRankings = genreRankings
        this.setState({ genreRankingsState, tvShowGenreError: "", tvShowGenreErrors: [], tvShowGenreInputError: '' })
    }

    closeTvShowGenresError(genre) {
        let genreErrors = this.state.tvShowGenreErrors;
        const error = genreErrors.findIndex(it => it.genre.toLowerCase() === genre.toLowerCase())
        genreErrors.splice(error, 1)
        this.setState({ genreErrors })
    }

    closeTvShowGenresInputError() {
        this.setState({ tvShowGenreInputError: '' })
    }

    // HANDLE NEW GENRE NAME CHANGE
    handleNewTvShowGenreChange(selectedOption) {
        this.setState({
            tvShowSelectedOption: selectedOption,
            newTvShowGenre: selectedOption.label
        });
    }

    onInsertTvShowGenre(genre, fromInputElement, e) {
        if (e) { e.preventDefault() }

        if (genre.length > 0) {
            //don't allow duplicates
            if (this.state.inputs.TvShowPreferences.GenreRankings.find(it => it.Genre.Name.toLowerCase() === genre.toLowerCase())) {
                if (fromInputElement) {
                    this.setState({ tvShowGenreInputError: "This genre is already ranked." });
                    return;
                } else if (this.state.displayTvShowGenres.find(it => it.toLowerCase() === genre.toLowerCase())) {
                    let genreErrors = this.state.tvShowGenreErrors;
                    genreErrors.push({ genre: genre.toLowerCase(), error: "This genre is already ranked.", show: true })
                    this.setState({ genreErrors });
                    return;
                }
            }
            else if (this.state.inputs.TvShowPreferences.GenreRankings.length >= 5) {
                if (fromInputElement) {
                    this.setState({ tvShowGenreInputError: "You may only select up to 5 genres. Please remove an existing selection and try again." });
                    return;
                }
                else if (this.state.displayTvShowGenres.find(it => it.toLowerCase() === genre.toLowerCase())) {
                    let genreErrors = this.state.tvShowGenreErrors;
                    genreErrors.push({ genre: genre.toLowerCase(), error: "You may only select up to 5 genres. Please remove an existing selection and try again.", show: true })
                    this.setState({ genreErrors });
                    return;
                }
            }

            //add to displayed genres
            let displayGenres = this.state.displayTvShowGenres;
            if (!this.state.displayTvShowGenres.find(it => it.toLowerCase() === genre.toLowerCase())) { displayGenres.push(genre) }

            const fullGenre = {
                Ranking: this.state.inputs.TvShowPreferences.GenreRankings.length + 1, Genre: { Name: genre }
            }

            if (this.state.tvShowGenreOptions.find(it => it.Name.toLowerCase() === genre.toLowerCase())) {
                fullGenre.Genre.TMDbId = this.state.tvShowGenreOptions.find(it => it.Name.toLowerCase() === genre.toLowerCase()).Id;
            }

            this.handleTvShowGenreRankingChange([...this.state.inputs.TvShowPreferences.GenreRankings, fullGenre])

            //reset
            this.setState({
                newTvShowGenre: '',
                tvShowSelectedOption: '',
                displayGenres
            })
        }

    }

    removeTvShowGenre(id, name) {
        let tempArray = this.state.inputs.TvShowPreferences.GenreRankings;

        let matchingGenre;
        if (id && id !== "0") {
            matchingGenre = tempArray.findIndex(it => it.Genre.TMDbId === id)
        } else {
            matchingGenre = tempArray.findIndex(it => it.Genre.Name.toLowerCase() === name.toLowerCase())
        }

        if (matchingGenre !== undefined && matchingGenre !== -1) {
            tempArray.splice(matchingGenre, 1);
            //rank
            tempArray.sort((a, b) => a.Ranking > b.Ranking).forEach((item, index) => {
                item.Ranking = index + 1;
            })
            this.setState({ tempArray })
        }

        this.setState({ tvShowGenreErrors: [], tvShowGenreInputError: '' })
    }

    handleFavoriteMovieActorsChange(index, id, name) {
        let favoriteActors = this.state.inputs.MoviePreferences.FavoriteActors;
        if (!favoriteActors[index]) {
            const objsNeeded = (index + 1) - favoriteActors.length;
            for (let i = 0; i < objsNeeded; i++) {
                favoriteActors.push({})
            }
        }
        favoriteActors[index].Name = name;
        favoriteActors[index].TMDbId = parseInt(id) || 0;
        this.setState({ favoriteActors })
    }

    handleFavoriteMoviesChange(index, id, displayTitle, realTitle) {
        let favoriteMovies = this.state.inputs.MoviePreferences.FavoriteMovies;
        if (!favoriteMovies[index]) {
            const objsNeeded = (index + 1) - favoriteMovies.length;
            for (let i = 0; i < objsNeeded; i++) {
                favoriteMovies.push({})
            }
        }
        favoriteMovies[index].Title = realTitle;
        favoriteMovies[index].DisplayTitle = displayTitle;
        favoriteMovies[index].TMDbId = parseInt(id) || 0;
        this.setState({ favoriteMovies })
    }

    handleFavoriteTvShowsChange(index, id, displayTitle, realTitle) {
        let favoriteShows = this.state.inputs.TvShowPreferences.FavoriteShows;
        if (!favoriteShows[index]) {
            const objsNeeded = (index + 1) - favoriteShows.length;
            for (let i = 0; i < objsNeeded; i++) {
                favoriteShows.push({})
            }
        }
        favoriteShows[index].Title = realTitle;
        favoriteShows[index].DisplayTitle = displayTitle;
        favoriteShows[index].TMDbId = parseInt(id) || 0;
        this.setState({ favoriteShows })
    }

    handleValidation() {
        //nothing for now
        return true;
    }

    getSimilarMovies() {
        if (!this.state.inputs.MoviePreferences.FavoriteMovies.length || !this.state.inputs.MoviePreferences.FavoriteMovies.find(it => it.Title || it.TMDbId)) {
            this.setState({
                fetchingSimilarMoviesError: 'You must provide your favorite movie(s) in order to be recommended similar movies.',
                recommendations: {
                    ...this.state.recommendations,
                    similarMovies: []
                }
            })
            return;
        }

        this.setState({
            recommendations: {
                ...this.state.recommendations,
                similarMovies: []
            },
            fetchingSimilarMovies: true,
            fetchingSimilarMoviesError: '',
        }, () => {
            const favoriteMovies = JSON.parse(JSON.stringify(this.state.inputs.MoviePreferences.FavoriteMovies)) //make a deep copy so as to not update the state when updating the variable
            this.props.actions.getSimilarMovies(favoriteMovies.filter(movie => movie.Title))
                .then(() => {
                    let similarMovies = [...this.props.television.similarMovies];
                    let movieRecommendations = this.state.recommendations;
                    if (similarMovies.length === 0) { this.setState({ fetchingSimilarMoviesError: "Unable to find any similar movies", fetchingSimilarMovies: false }); return; }
                    movieRecommendations.similarMovies = similarMovies;
                    this.setState({
                        fetchingSimilarMovies: false,
                        movieRecommendations
                    })

                    localStorage.setItem("MovieGenreRankings", JSON.stringify(this.state.inputs.MoviePreferences.GenreRankings))
                })
                .catch(err => {
                    if (err === 'Aborted') {
                        return;
                    }
                    this.setState({
                        fetchingSimilarMovies: false,
                        fetchingSimilarMoviesError: err
                    });
                });
        })
    }

    getSimilarShows() {
        if (!this.state.inputs.TvShowPreferences.FavoriteShows.length || !this.state.inputs.TvShowPreferences.FavoriteShows.find(it => it.Title || it.TMDbId)) {
            this.setState({
                fetchingSimilarShowsError: 'You must provide your favorite TV show(s) in order to be recommended similar TV shows.',
                recommendations: {
                    ...this.state.recommendations,
                    similarShows: []
                }
            })
            return;
        }

        this.setState({
            recommendations: {
                ...this.state.recommendations,
                similarShows: []
            },
            fetchingSimilarShows: true,
            fetchingSimilarShowsError: '',
        }, () => {
            const favoriteShows = JSON.parse(JSON.stringify(this.state.inputs.TvShowPreferences.FavoriteShows)) //make a deep copy so as to not update the state when updating the variable
            this.props.actions.getSimilarShows(favoriteShows.filter(show => show.Title))
                .then(() => {
                    let similarShows = [...this.props.television.similarShows];
                    let showRecommendations = this.state.recommendations;
                    if (similarShows.length === 0) { this.setState({ fetchingSimilarShowsError: "Unable to find any similar shows", fetchingSimilarShows: false }); return; }
                    showRecommendations.similarShows = similarShows;
                    this.setState({
                        fetchingSimilarShows: false,
                        showRecommendations
                    })

                    localStorage.setItem("TvShowGenreRankings", JSON.stringify(this.state.inputs.TvShowPreferences.GenreRankings))
                })
                .catch(err => {
                    if (err === 'Aborted') {
                        return;
                    }
                    this.setState({
                        fetchingSimilarShows: false,
                        fetchingSimilarShowsError: err
                    });
                });
        })
    }

    getMoviesByActor() {
        if (!this.state.inputs.MoviePreferences.FavoriteActors.length || !this.state.inputs.MoviePreferences.FavoriteActors.find(it => it.Name || it.TMDbId)) {
            this.setState({
                fetchingMoviesByActorError: 'You must provide your favorite actor(s) in order to be recommended movies based on the actor(s).',
                recommendations: {
                    ...this.state.recommendations,
                    moviesByActor: []
                }
            })
            return;
        }

        this.setState({
            recommendations: {
                ...this.state.recommendations,
                moviesByActor: []
            },
            fetchingMoviesByActor: true,
            fetchingMoviesByActorError: '',
        }, () => {
            const favoriteActors = JSON.parse(JSON.stringify(this.state.inputs.MoviePreferences.FavoriteActors)) //make a deep copy so as to not update the state when updating the variable
            this.props.actions.getMoviesByActor(favoriteActors.filter(actor => actor.Name))
                .then(() => {
                    let moviesByActor = [...this.props.television.moviesByActor];
                    let movieRecommendations = this.state.recommendations;
                    if (moviesByActor.length === 0) { this.setState({ fetchingMoviesByActorError: "Unable to find any recommendations", fetchingMoviesByActor: false }); return; }
                    movieRecommendations.moviesByActor = moviesByActor;
                    this.setState({
                        fetchingMoviesByActor: false,
                        movieRecommendations
                    })

                    localStorage.setItem("MovieGenreRankings", JSON.stringify(this.state.inputs.MoviePreferences.GenreRankings))
                })
                .catch(err => {
                    if (err === 'Aborted') {
                        return;
                    }
                    this.setState({
                        fetchingMoviesByActor: false,
                        fetchingMoviesByActorError: err
                    });
                });
        })
    }

    getMoviesByGenre() {
        if (!this.state.inputs.MoviePreferences.GenreRankings.length || !this.state.inputs.MoviePreferences.GenreRankings.find(it => it.Ranking || (it.Genre && (it.Genre.Name || it.Genre.TMDbId)))) {
            this.setState({
                fetchingMoviesByGenreError: 'You must provide your favorite movie genre(s) in order to be recommended movies based on the genre(s).',
                recommendations: {
                    ...this.state.recommendations,
                    moviesByGenre: []
                }
            })
            return;
        }

        this.setState({
            recommendations: {
                ...this.state.recommendations,
                moviesByGenre: []
            },
            fetchingMoviesByGenre: true,
            fetchingMoviesByGenreError: '',
        }, () => {
            const movieGenreRankings = JSON.parse(JSON.stringify(this.state.inputs.MoviePreferences.GenreRankings)) //make a deep copy so as to not update the state when updating the variable
            this.props.actions.getMoviesByGenre(movieGenreRankings)
                .then(() => {
                    let moviesByGenre = [...this.props.television.moviesByGenre];
                    let movieRecommendations = this.state.recommendations;
                    if (moviesByGenre.length === 0) { this.setState({ fetchingMoviesByGenreError: "Unable to find any recommendations", fetchingMoviesByGenre: false }); return; }
                    movieRecommendations.moviesByGenre = moviesByGenre;
                    this.setState({
                        fetchingMoviesByGenre: false,
                        movieRecommendations
                    })

                    localStorage.setItem("MovieGenreRankings", JSON.stringify(this.state.inputs.MoviePreferences.GenreRankings))
                })
                .catch(err => {
                    if (err === 'Aborted') {
                        return;
                    }
                    this.setState({
                        fetchingMoviesByGenre: false,
                        fetchingMoviesByGenreError: err
                    });
                });
        })
    }

    getShowsByGenre() {
        if (!this.state.inputs.TvShowPreferences.GenreRankings.length || !this.state.inputs.TvShowPreferences.GenreRankings.find(it => it.Ranking || (it.Genre && (it.Genre.Name || it.Genre.TMDbId)))) {
            this.setState({
                fetchingShowsByGenreError: 'You must provide your favorite TV show genre(s) in order to be recommended TV shows based on the genre(s).',
                recommendations: {
                    ...this.state.recommendations,
                    showsByGenre: []
                }
            })
            return;
        }

        this.setState({
            recommendations: {
                ...this.state.recommendations,
                showsByGenre: []
            },
            fetchingShowsByGenre: true,
            fetchingShowsByGenreError: ''
        }, () => {
            const tvShowGenreRankings = JSON.parse(JSON.stringify(this.state.inputs.TvShowPreferences.GenreRankings)) //make a deep copy so as to not update the state when updating the variable
            this.props.actions.getShowsByGenre(tvShowGenreRankings)
                .then(() => {
                    let showsByGenre = [...this.props.television.showsByGenre];
                    let showRecommendations = this.state.recommendations;
                    if (showsByGenre.length === 0) { this.setState({ fetchingShowsByGenreError: "Unable to find any recommendations", fetchingShowsByGenre: false }); return; }
                    showRecommendations.showsByGenre = showsByGenre;
                    this.setState({
                        fetchingShowsByGenre: false,
                        showRecommendations
                    })

                    localStorage.setItem("TvShowGenreRankings", JSON.stringify(this.state.inputs.TvShowPreferences.GenreRankings))
                })
                .catch(err => {
                    if (err === 'Aborted') {
                        return;
                    }
                    this.setState({
                        fetchingShowsByGenre: false,
                        fetchingShowsByGenreError: err
                    });
                });
        })
    }

    async getRecommendations(e) {
        e.preventDefault();

        if (!this.handleValidation()) {
            return;
        }

        this.next();

        this.getSimilarMovies();
        this.getSimilarShows();
        this.getMoviesByActor();
        this.getMoviesByGenre();
        this.getShowsByGenre();
    }

    next(index) {
        const self = this;
        const { changingCriteria } = this.state;

        this.setState({ transitioning: true }, () => {
            let sections = document.getElementsByClassName('television-section');
            if (sections && sections.length) {
                if (!index && index !== 0) { index = sections.length - 1; }
                sections = Array.from(sections);
                const currentContent = sections[index];

                if (currentContent) {
                    currentContent.classList.remove('fade-in-delayed-2', 'fade-in-delayed-1');
                    currentContent.classList.add('fade-out');
                }

                const nextButton = document.getElementsByClassName('next-button');
                let cover = document.getElementsByClassName('cover');
                let newCoverElement;
                if (cover && cover.length) {
                    cover = Array.from(cover);
                    newCoverElement = cover.find(el => el.classList.contains('d-none'));
                }
                if (nextButton && nextButton.length) {
                    if (nextButton[0].classList.contains('fade-in-delayed-2')) {
                        nextButton[0].classList.remove('fade-in-delayed-2');
                        nextButton[0].classList.add(!changingCriteria ? 'fade-out-and-in' : 'fade-out-and-in-fast');
                    } else {
                        nextButton[0].classList.remove("fade-out-and-in", "fade-out-and-in-fast");
                        setTimeout(function () {
                            nextButton[0].classList.add(!newCoverElement || changingCriteria ? 'fade-out-and-in-fast' : "fade-out-and-in");
                        }, 100);
                    }
                }

                if (newCoverElement && !changingCriteria) {
                    newCoverElement.classList.remove('d-none');
                    newCoverElement.classList.add('fade-in-and-out-delayed');
                }

                if (sections && sections.length && sections[index + 1]) {
                    const nextSection = sections[index + 1]
                    nextSection.classList.remove('d-none');
                    nextSection.classList.add(!changingCriteria ? 'fade-in-delayed-2' : 'fade-in-delayed-1');
                }

                if (sections.length === index + 2) {
                    setTimeout(function () {
                        if (currentContent) {
                            currentContent.classList.add('d-none');
                        }
                        self.setState({ transitioning: false, ready: true, changingCriteria: false, pageIndex: index + 1 })
                    }, 2000);
                } else {
                    setTimeout(function () {
                        if (currentContent) {
                            currentContent.classList.add('d-none');
                        }
                        self.setState({ transitioning: false, ready: false, nextSection: sections.length > index + 1, pageIndex: index + 1 })
                    }, 2000);
                }
            } else {
                setTimeout(function () {
                    self.setState({ transitioning: false, ready: false, nextSection: false, pageIndex: index + 1 })
                }, 2000);
            }
        })
    }

    changeCriteria() {
        const self = this;
        this.setState({ changingCriteria: true }, () => {
            const resultSection = document.getElementById('result-section');
            if (resultSection) {
                const resultFades = resultSection.getElementsByClassName('fade-in-delayed-1');
                for (const el of resultFades) {
                    el.classList.remove('fade-in-delayed-1');
                    el.classList.add('fade-out');
                }

                const nextButton = document.getElementsByClassName('next-button');
                if (nextButton && nextButton.length) {
                    nextButton[0].classList.remove("fade-out-and-in", 'fade-out-and-in-fast', 'fade-out-and-in');
                    setTimeout(function () {
                        nextButton[0].classList.add('fade-out-and-in-fast');
                    }, 100);
                }

                let sections = document.getElementsByClassName('television-section');
                if (sections && sections.length) {
                    for (const section of sections) {
                        section.classList.add('d-none');
                    }
                    setTimeout(function () {
                        self.setState({
                            recommendations: {
                                ...self.state.recommendations,
                                similarMovies: [],
                                similarShows: [],
                                moviesByGenre: [],
                                showsByGenre: [],
                                moviesByActor: []
                            },
                            fetchingSimilarMovies: false,
                            fetchingSimilarMoviesError: '',
                            fetchingSimilarShows: false,
                            fetchingSimilarShowsError: '',
                            fetchingMoviesByActor: false,
                            fetchingMoviesByActorError: '',
                            fetchingMoviesByGenre: false,
                            fetchingMoviesByGenreError: '',
                            fetchingShowsByGenre: false,
                            fetchingShowsByGenreError: ''
                        }, () => {
                            self.next(-1);
                        });
                    }, 2000);
                }

            }
        })
    }

    render() {

        const renderMovieTooltip = (info, genre) => (
            <Tooltip id="button-tooltip" hidden={genre ? !this.state.movieGenreErrors.find(it => it.genre.toLowerCase() === genre.toLowerCase()) : !this.state.movieGenreInputError}>
                <span>{info}</span>
                <br />
                <button type="button" aria-label="Close" className="btn btn-secondary btn-sm btn-block p-0 mt-1" onClick={() => genre ? this.closeMovieGenresError(genre) : this.closeMovieGenresInputError()}>
                    <span aria-hidden="true">Close</span>
                </button>
            </Tooltip>
        );

        const renderTvShowTooltip = (info, genre) => (
            <Tooltip id="button-tooltip" hidden={genre ? !this.state.tvShowGenreErrors.find(it => it.genre.toLowerCase() === genre.toLowerCase()) : !this.state.tvShowGenreInputError}>
                <span>{info}</span>
                <br />
                <button type="button" aria-label="Close" className="btn btn-secondary btn-sm btn-block p-0 mt-1" onClick={() => genre ? this.closeTvShowGenresError(genre) : this.closeTvShowGenresInputError()}>
                    <span aria-hidden="true">Close</span>
                </button>
            </Tooltip>
        );

        const customStyles = {
            option: (base, state) => ({
                ...base,
                fontSize: '1.0em',
                color: '#0792A0',
                background: state.isFocused ? '#49759d30' : 'inherit',
            }),
            container: (base) => ({
                ...base,
                minWidth: '200px',
                maxWidth: '250px',
                margin: 'auto',
                display: 'inline-block'
            }),
            control: (base) => ({
                ...base,
                background: '#f1f1f1',
                border: '1px solid #d8d8d8',
                borderRadius: '0 0 5px 5px',
                minWidth: '200px',
                maxWidth: '250px',
                margin: 'auto'
            }),
            menu: (base) => ({
                ...base,
                width: '215px',
                marginTop: '1px',
                marginBottom: '70px',
                position: 'relative'
            }),
            menuList: (base) => ({
                ...base,
                marginTop: '1px',
            }),
            indicatorSeparator: (base) => ({
                ...base,
                display: "none"
            }),
            valueContainer: (base) => ({
                ...base,
                textAlign: 'left'
            })
        }

        return (
            <div>
                <div id="television" className="text-color-burlywood">
                    <div id="cover-container">
                        <h1 className="cover television component-header fade-in-and-out">TELEVISION</h1>
                        <div className="cover fade-in-and-out-delayed"><h1 className="circle-container">1</h1><h2>Choose up to 5 movie genres to be used in gathering your recommendations</h2></div>
                        <div className="cover d-none"><h1 className="circle-container">2</h1><h2>Choose up to 3 of your favorite movie actors/actresses</h2></div>
                        <div className="cover d-none"><h1 className="circle-container">3</h1><h2>Choose up to 3 of your favorite movies</h2></div>
                        <div className="cover d-none"><h1 className="circle-container">4</h1><h2>Choose up to 5 TV show genres to be used in gathering your recommendations</h2></div>
                        <div className="cover d-none"><h1 className="circle-container">5</h1><h2>Choose up to 3 of your favorite TV shows</h2></div>
                    </div>
                    <div id="content" className="fade-in-delayed-2">
                        <div className="center-form">
                            <ul className="padding-inline-start-0 sections">
                                <div className="television-section">
                                    <h2 className="component-header">TELEVISION</h2>
                                    <div className="text-center criteria-info">
                                        <h6 className="mb-3 font-italic"><strong>1. Choose up to 5 genres to be used in gathering your recommendations</strong></h6>
                                        <TelevisionGenreDraggableComponent
                                            name="movie-genre"
                                            onChange={this.handleMovieGenreRankingChange}
                                            items={this.state.inputs.MoviePreferences.GenreRankings}
                                            genreOptions={this.state.movieGenreOptions}
                                            removeGenre={this.removeMovieGenre}
                                        />
                                        <div className="mx-auto mt-2" style={{ maxWidth: '500px' }}>
                                            {
                                                this.state.displayMovieGenres.sort().map((option, i) =>
                                                    <OverlayTrigger
                                                        key={i}
                                                        show={this.state.movieGenreErrors.find(error => error.genre.toLowerCase() === option.toLowerCase()) !== undefined}
                                                        placement="bottom"
                                                        overlay={renderMovieTooltip(this.state.movieGenreErrors.find(error => error.genre.toLowerCase() === option.toLowerCase()) ? this.state.movieGenreErrors.find(error => error.genre.toLowerCase() === option.toLowerCase()).error : '', option)}
                                                    >
                                                        <button type="button"
                                                            className={`btn badge p-2 m-1${this.state.inputs.MoviePreferences.GenreRankings.find(ranking => ranking.Genre.Name.toLowerCase() === option.toLowerCase()) ? ' badge-info text-light' : ' badge-light'}`}
                                                            onClick={() => this.state.inputs.MoviePreferences.GenreRankings.find(ranking => ranking.Genre.Name.toLowerCase() === option.toLowerCase()) ? this.removeMovieGenre(null, option) : this.onInsertMovieGenre(option)}>{option.toLowerCase()} <span
                                                                className={`fa${this.state.inputs.MoviePreferences.GenreRankings.find(ranking => ranking.Genre.Name.toLowerCase() === option.toLowerCase()) ? ' fa-minus text-danger' : ' fa-plus text-primary'}`} />
                                                        </button>
                                                    </OverlayTrigger>
                                                )
                                            }
                                            <div className="mt-1 mb-3">
                                                <span className="h6 font-italic text-primary">Additional Genres</span><br />
                                                <form onSubmit={(e) => this.onInsertMovieGenre(this.state.newMovieGenre, true, e)}>
                                                    <OverlayTrigger
                                                        show={this.state.movieGenreInputError !== ''}
                                                        placement="bottom"
                                                        overlay={renderMovieTooltip(this.state.movieGenreInputError)}
                                                    >
                                                        <Select
                                                            menuPortalTarget={document.querySelector('body')}
                                                            options={this.state.movieSelectOptions.filter(genre => !this.state.inputs.MoviePreferences.GenreRankings.map(a => a.Genre.Name.toLowerCase()).includes(genre.label.toLowerCase()) && !this.state.displayMovieGenres.map(a => a.toLowerCase()).includes(genre.label.toLowerCase()))}
                                                            noOptionsMessage={() => { return (<span>No genres found</span>) }}
                                                            placeholder={"Search..."}
                                                            styles={customStyles}
                                                            onChange={this.handleNewMovieGenreChange}
                                                            value={this.state.movieSelectedOption}
                                                        />
                                                    </OverlayTrigger>
                                                    <button type="button" id={'insert-button'} disabled={!this.state.newMovieGenre} className="button btn-outline-primary ml-2" onClick={() => this.onInsertMovieGenre(this.state.newMovieGenre, true)}>Insert</button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="television-section d-none">
                                    <h2 className="component-header">TELEVISION</h2>
                                    <div className="text-center criteria-info">
                                        <h6 className="mb-3 font-italic"><strong>2. Choose up to 3 of your favorite movie actors/actresses</strong></h6>
                                        <div>
                                            {Array.from({ length: this.state.numActors }, (_, i) =>
                                                <React.Fragment key={i}>
                                                    <SearchActors
                                                        index={i}
                                                        onChange={this.handleFavoriteMovieActorsChange.bind(this)}
                                                        value={this.state.inputs.MoviePreferences.FavoriteActors[i] ? this.state.inputs.MoviePreferences.FavoriteActors[i].Name : ""}
                                                        id={this.state.inputs.MoviePreferences.FavoriteActors[i] ? this.state.inputs.MoviePreferences.FavoriteActors[i].TMDbId : ""}
                                                        searchActors={this.props.actions.searchActors}
                                                        actors={this.props.television.actors}
                                                    />
                                                </React.Fragment>
                                            )}
                                            <button type="button" disabled={this.state.numActors >= 3} className="btn btn-sm btn-primary mt-1" onClick={this.addActor}>Add Another</button>
                                            {
                                                this.state.numActors >= 3 &&
                                                <p>(Max of 3 allowed)</p>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="television-section d-none">
                                    <h2 className="component-header">TELEVISION</h2>
                                    <div className="text-center criteria-info">
                                        <h6 className="mb-3 font-italic"><strong>3. Choose up to 3 of your favorite movies</strong></h6>
                                        <div>
                                            {Array.from({ length: this.state.numMovies }, (_, i) =>
                                                <React.Fragment key={i}>
                                                    <SearchMovies
                                                        index={i}
                                                        onChange={this.handleFavoriteMoviesChange.bind(this)}
                                                        value={this.state.inputs.MoviePreferences.FavoriteMovies[i] ? this.state.inputs.MoviePreferences.FavoriteMovies[i].DisplayTitle || this.state.inputs.MoviePreferences.FavoriteMovies[i].Title : ""}
                                                        id={this.state.inputs.MoviePreferences.FavoriteMovies[i] ? this.state.inputs.MoviePreferences.FavoriteMovies[i].TMDbId : ""}
                                                        searchMovies={this.props.actions.searchMovies}
                                                        movies={this.props.television.movies}
                                                    />
                                                </React.Fragment>
                                            )}
                                            <button type="button" disabled={this.state.numMovies >= 3} className="btn btn-sm btn-primary mt-1" onClick={this.addMovie}>Add Another</button>
                                            {
                                                this.state.numMovies >= 3 &&
                                                <p>(Max of 3 allowed)</p>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="television-section d-none">
                                    <h2 className="component-header">TELEVISION</h2>
                                    <div className="text-center criteria-info">
                                        <h6 className="mb-3 font-italic"><strong>4. Choose up to 5 TV show genres to be used in gathering your recommendations</strong></h6>
                                        <TelevisionGenreDraggableComponent
                                            name="tv-show-genre"
                                            onChange={this.handleTvShowGenreRankingChange}
                                            items={this.state.inputs.TvShowPreferences.GenreRankings}
                                            genreOptions={this.state.tvShowGenreOptions}
                                            removeGenre={this.removeTvShowGenre}
                                        />
                                        <div className="mx-auto mt-2" style={{ maxWidth: '500px' }}>
                                            {
                                                this.state.displayTvShowGenres.sort().map((option, i) =>
                                                    <OverlayTrigger
                                                        key={i}
                                                        show={this.state.tvShowGenreErrors.find(error => error.genre.toLowerCase() === option.toLowerCase()) !== undefined}
                                                        placement="bottom"
                                                        overlay={renderTvShowTooltip(this.state.tvShowGenreErrors.find(error => error.genre.toLowerCase() === option.toLowerCase()) ? this.state.tvShowGenreErrors.find(error => error.genre.toLowerCase() === option.toLowerCase()).error : '', option)}
                                                    >
                                                        <button type="button"
                                                            className={`btn badge p-2 m-1${this.state.inputs.TvShowPreferences.GenreRankings.find(ranking => ranking.Genre.Name.toLowerCase() === option.toLowerCase()) ? ' badge-info text-light' : ' badge-light'}`}
                                                            onClick={() => this.state.inputs.TvShowPreferences.GenreRankings.find(ranking => ranking.Genre.Name.toLowerCase() === option.toLowerCase()) ? this.removeTvShowGenre(null, option) : this.onInsertTvShowGenre(option)}>{option.toLowerCase()} <span
                                                                className={`fa${this.state.inputs.TvShowPreferences.GenreRankings.find(ranking => ranking.Genre.Name.toLowerCase() === option.toLowerCase()) ? ' fa-minus text-danger' : ' fa-plus text-primary'}`} />
                                                        </button>
                                                    </OverlayTrigger>
                                                )
                                            }
                                            <div className="mt-1">
                                                <span className="h6 font-italic text-primary">Additional Genres</span><br />
                                                <form onSubmit={(e) => this.onInsertTvShowGenre(this.state.newTvShowGenre, true, e)}>
                                                    <OverlayTrigger
                                                        show={this.state.tvShowGenreInputError !== ''}
                                                        placement="bottom"
                                                        overlay={renderTvShowTooltip(this.state.tvShowGenreInputError)}
                                                    >
                                                        <Select
                                                            menuPortalTarget={document.querySelector('body')}
                                                            options={this.state.tvShowSelectOptions.filter(genre => !this.state.inputs.TvShowPreferences.GenreRankings.map(a => a.Genre.Name.toLowerCase()).includes(genre.label.toLowerCase()) && !this.state.displayTvShowGenres.map(a => a.toLowerCase()).includes(genre.label.toLowerCase()))}
                                                            noOptionsMessage={() => { return (<span>No genres found</span>) }}
                                                            placeholder={"Search..."}
                                                            styles={customStyles}
                                                            onChange={this.handleNewTvShowGenreChange}
                                                            value={this.state.tvShowSelectedOption}
                                                        />
                                                    </OverlayTrigger>
                                                    <button type="button" id={'insert-button'} disabled={!this.state.newTvShowGenre} className="button btn-outline-primary ml-2" onClick={() => this.onInsertTvShowGenre(this.state.newTvShowGenre, true)}>Insert</button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="television-section d-none">
                                    <h2 className="component-header">TELEVISION</h2>
                                    <div className="text-center criteria-info">
                                        <h6 className="mb-3 font-italic"><strong>Choose up to 3 of your favorite TV shows</strong></h6>
                                        <div>
                                            {Array.from({ length: this.state.numTvShows }, (_, i) =>
                                                <React.Fragment key={i}>
                                                    <SearchShows
                                                        index={i}
                                                        onChange={this.handleFavoriteTvShowsChange.bind(this)}
                                                        value={this.state.inputs.TvShowPreferences.FavoriteShows[i] ? this.state.inputs.TvShowPreferences.FavoriteShows[i].DisplayTitle || this.state.inputs.TvShowPreferences.FavoriteShows[i].Title : ""}
                                                        id={this.state.inputs.TvShowPreferences.FavoriteShows[i] ? this.state.inputs.TvShowPreferences.FavoriteShows[i].TMDbId : ""}
                                                        searchShows={this.props.actions.searchShows}
                                                        shows={this.props.television.shows}
                                                    />
                                                    <br />
                                                </React.Fragment>
                                            )}
                                            <button type="button" disabled={this.state.numTvShows >= 3} className="btn btn-sm btn-primary" onClick={this.addTvShow}>Add Another</button>
                                            {
                                                this.state.numTvShows >= 3 &&
                                                <p>(Max of 3 allowed)</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </ul>
                            <div className="fade-in-delayed-2 next-button">
                                {this.state.ready ?
                                    <button className="btn btn-primary btn-lg btn-block" type="button" onClick={this.getRecommendations.bind(this)} disabled={this.state.transitioning || this.state.fetchingMoviesByGenre}>Recommend Me!</button>
                                    :
                                    this.state.nextSection ?
                                        <button type="button" className="btn btn-primary btn-lg btn-block shadow-sm" onClick={() => this.next(this.state.pageIndex)} disabled={this.state.transitioning}>Next</button>
                                        :
                                        this.state.recommendations.moviesByGenre.length || this.state.fetchingMoviesByGenreError ?
                                            <button type="button" className="btn btn-primary btn-lg btn-block shadow-sm" onClick={this.changeCriteria} disabled={this.state.transitioning}>Change Criteria</button>
                                            : null
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div id="result-section">
                    {
                        (this.state.fetchingMoviesByActor || this.state.fetchingMoviesByGenre || this.state.fetchingShowsByGenre || this.state.fetchingSimilarMovies || this.state.fetchingSimilarShows ||
                            this.state.recommendations.moviesByActor.length || this.state.recommendations.moviesByGenre.length || this.state.recommendations.showsByGenre.length || this.state.recommendations.similarMovies.length || this.state.recommendations.similarShows.length ||
                            this.state.fetchingMoviesByActorError || this.state.fetchingMoviesByGenreError || this.state.fetchingShowsByGenreError || this.state.fetchingSimilarMoviesError || this.state.fetchingSimilarShowsError) &&
                        <div className="row justify-content-center text-monospace fade-in-delayed-1">
                            <div className="table-responsive fake-table">
                                <div className="justify-content-center">
                                    <Accordion defaultActiveKey="0">
                                        {
                                            <MovieTable
                                                eventKey="0"
                                                retrieving={this.state.fetchingSimilarMovies}
                                                error={this.state.fetchingSimilarMoviesError && this.state.fetchingSimilarMoviesError}
                                                movies={this.state.recommendations.similarMovies}
                                                title="Movies similar to your favorites"
                                                refresh={this.getSimilarMovies}
                                                genreOptions={this.state.movieGenreOptions}
                                            />
                                        }
                                        {
                                            <MovieTable
                                                eventKey="1"
                                                retrieving={this.state.fetchingMoviesByActor}
                                                error={this.state.fetchingMoviesByActorError && this.state.fetchingMoviesByActorError}
                                                movies={this.state.recommendations.moviesByActor}
                                                title="Recommended movies based on your favorite actors"
                                                refresh={this.getMoviesByActor}
                                                genreOptions={this.state.movieGenreOptions}
                                            />
                                        }
                                        {
                                            <MovieTable
                                                eventKey="2"
                                                retrieving={this.state.fetchingMoviesByGenre}
                                                error={this.state.fetchingMoviesByGenreError && this.state.fetchingMoviesByGenreError}
                                                movies={this.state.recommendations.moviesByGenre}
                                                title="Recommended movies based on your favorite genres"
                                                refresh={this.getMoviesByGenre}
                                                genreOptions={this.state.movieGenreOptions}
                                            />
                                        }
                                        {
                                            <TvShowTable
                                                eventKey="3"
                                                retrieving={this.state.fetchingSimilarShows}
                                                error={this.state.fetchingSimilarShowsError && this.state.fetchingSimilarShowsError}
                                                shows={this.state.recommendations.similarShows}
                                                title="TV shows similar to your favorites"
                                                refresh={this.getSimilarShows}
                                                genreOptions={this.state.tvShowGenreOptions}
                                            />
                                        }
                                        {
                                            <TvShowTable
                                                eventKey="4"
                                                retrieving={this.state.fetchingShowsByGenre}
                                                error={this.state.fetchingShowsByGenreError && this.state.fetchingShowsByGenreError}
                                                shows={this.state.recommendations.showsByGenre}
                                                title="Recommended TV shows based on your favorite genres"
                                                refresh={this.getShowsByGenre}
                                                genreOptions={this.state.tvShowGenreOptions}
                                            />
                                        }
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <br />
                <br />
            </div >
        );
    }
}

export default connect(
    (state) => {
        const { television } = state;
        return {
            television
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, televisionActionCreators), dispatch)
        }
    }
)(SlowTelevision)