import React from 'react';
import AsyncSelect from 'react-select/async';

let searchDelayTimer;

class SearchActors extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            query: '',
            results: [],
            loading: false,
            message: '',
        };

        this.handleOnInputBlur = this.handleOnInputBlur.bind(this)
    }

    promiseOptions = (inputValue) => {
        if (searchDelayTimer) {
            clearTimeout(searchDelayTimer)
        }
        return new Promise((resolve) => {
            searchDelayTimer = setTimeout(() => {
                resolve(this.handleOnInputChange(inputValue));
            }, 100);
        });
    }


    handleOnInputChange = (query) => {
        this.props.onChange(this.props.index, 0, query); //default ID to 0, once they click out, the onBlur will take care of the ID
        this.setState({ query, loading: query, message: '' })
        let promise = new Promise((resolve) => {
            setTimeout(() => {
                resolve(
                    this.fetchSearchResults(query)
                )
            }, 10)
        })

        return promise
    };

    fetchSearchResults = async (query) => {
        const res = await new Promise((resolve) => {
            setTimeout(() => {
                resolve(this.props.searchActors(`?query=${query}`)
                    .then(() => {
                        let results = [...this.props.actors];
                        const resultNotFoundMsg = !results.length
                            ? 'There are no more search results. Please try a new search.'
                            : '';
                        this.setState({
                            loading: false,
                            message: resultNotFoundMsg,
                            results: results
                        })

                        return results
                    })
                    .catch(err => {
                        if (err === 'Aborted') {
                            return;
                        }
                        this.setState({
                            loading: false,
                        });

                        return this.state.results
                    })
                )
            }, 10);
        })

        return res
    };

    handleOnInputBlur(selectedOption) {
        this.setState({ selectedOption })
        this.props.onChange(this.props.index, selectedOption ? selectedOption.Id : null, selectedOption ? selectedOption.Name : null)
    }

    getOptionValue = (option) => option.Name;

    getOptionLabel = (option) => option.Name;

    render() {
        const customStyles = {
            option: (base, state) => ({
                ...base,
                fontSize: '1.0em',
                color: '#0792A0',
                background: state.isFocused ? '#49759d30' : 'inherit',
            }),
            control: (base) => ({
                ...base,
                background: '#f1f1f1',
                border: '1px solid #d8d8d8',
                borderRadius: '0 0 5px 5px',
                maxWidth: '250px',
                margin: 'auto',
                marginBottom: '10px'
            }),
            menu: (base) => ({
                ...base,
                width: '250px',
                marginTop: '1px'
            }),
            menuList: (base) => ({
                ...base,
                marginTop: '1px'
            }),
            indicatorSeparator: (base) => ({
                ...base,
                display: "none"
            }),
            valueContainer: (base) => ({
                ...base,
                textAlign: 'left'
            })
        }

        return (
            <AsyncSelect id={`actors-${this.props.index}`}
                menuPortalTarget={document.querySelector('body')}
                styles={customStyles}
                loadOptions={this.promiseOptions}
                noOptionsMessage={() => { return (<span>{this.state.query ? 'No shows found' : 'Begin your search'}</span>) }}
                onChange={this.handleOnInputBlur}
                value={this.state.selectedOption || ''}
                getOptionValue={this.getOptionValue}
                getOptionLabel={this.getOptionLabel}
                placeholder='Search...'
                isClearable
            />
        )
    }
}
export default SearchActors;