import React, { Component } from 'react';
import { Accordion } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Collapsible from 'react-collapsible';
import { createTheme } from '@material-ui/core/styles';
import MusicGenreDraggableComponent from '../../music/music-genre-dnd';
import { musicActionCreators } from '../../../stores/music-store';
import SearchArtists from '../../music/SearchArtists';
import MusicTable from '../../music/MusicTable';
import RelatedArtistsTable from '../../music/RelatedArtistsTable';
import { Checkbox } from '@material-ui/core';
import ChartsModal from '../../music/ChartsModal';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import Select from 'react-select';
import CircularSlider from '@fseehawer/react-circular-slider';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const gradientColorFrom = '#5af507';
const gradientColorTo = '#027324';

class SlowMusic extends Component {
    static displayName = SlowMusic.name;

    constructor(props) {
        super(props);

        let storedGenreRankings = localStorage.getItem("MusicGenreRankings");
        if (storedGenreRankings) {
            try {
                storedGenreRankings = JSON.parse(storedGenreRankings)

                if (storedGenreRankings && (!storedGenreRankings[0] || storedGenreRankings.find(it => !it.Genre || !it.Genre.Name || !it.Ranking))) {
                    //the object isn't how it should be
                    storedGenreRankings = null
                }

            } catch (e) {
                //likely not a proper json object, but regardless, just don't use it
                storedGenreRankings = null
            }
        }

        this.state = {
            access_token: null,
            gettingMusic: false,
            gettingArtists: false,
            musicError: '',
            artistsError: '',
            createPlaylistSuccess: null,
            creatingPlaylist: false,
            savingMusicPlaylist: false,
            savingArtistPlaylist: false,
            userPlaylists: [],
            musicPlaylistId: '',
            artistPlaylistId: '',
            selectOptions: [],
            genreOptions: [],
            displayGenreOptions: [...new Set(["Pop", "Country", "Hip-Hop", "Indie", "Party", "Rock", "Work-Out", "Classical", "Jazz", "Holidays"].concat(storedGenreRankings ? storedGenreRankings.map(it => it.Genre.Name) : []))],
            genreInputError: '', //error that will display on the genre input
            genreErrors: [], //error that will display on a bubble genre option
            showCharts: false,
            fetchingCharts: false,
            chartsError: '',
            charts: [],
            showFeaturedPlaylists: false,
            fetchingFeaturedPlaylists: false,
            featuredPlaylistsError: '',
            featuredPlaylists: [],
            numArtists: 1,
            nextSection: true,
            playlistSongs: {
                music: [],
                artists: []
            },
            savedPlaylistSongs: {
                music: [],
                artists: []
            },
            recommendations: {
                music: [],
                artists: []
            },
            fullArtists: [
                { artistId: '', artist: null, error: '', fetching: false, show: false, followError: '' }
            ],
            userFollows: [],
            searchLessPopularArtists: false,
            newGenre: '',
            inputs: {
                GenreRankings: storedGenreRankings ? storedGenreRankings :
                    [
                        {
                            Genre: { Name: "Pop" }, Ranking: 1
                        },
                        {
                            Genre: { Name: "Country" }, Ranking: 2
                        },
                        {
                            Genre: { Name: "Hip-Hop" }, Ranking: 3
                        }
                    ],
                FavoriteArtists: [
                    { Name: "", SpotifyId: "" }
                ],
                Mood: {
                    Danceability: 0,
                    Speechiness: 0,
                    Energy: 0,
                    Tempo: 0,
                    Liveness: 0,
                    Acousticness: 0,
                    Popularity: 0,
                    Valence: 0,
                    Loudness: 0,
                    Instrumentalness: 0
                }
            },
            MoodDescriptions: {
                Danceability: "How suitable a track is for dancing based on a combination of musical elements including tempo, rhythm stability, beat strength, and overall regularity",
                Valence: "The musical positiveness conveyed by a track. Tracks with high valence sound more positive (e.g. happy, cheerful, euphoric), while tracks with low valence sound more negative (e.g. sad, depressed, angry)",
                Energy: "A perceptual measure of intensity and activity. Typically, energetic tracks feel fast, loud, and noisy. For example, death metal has high energy, while a Bach prelude scores low on the scale",
                Tempo: "The speed or pace of a track",
                Liveness: "The presence of an audience in the recording. Higher liveness values represent an increased probability that the track was performed live",
                Acousticness: "Whether the track is acoustic",
                Instrumentalness: "Whether a track contains no vocals",
                Speechiness: "The presence of spoken words in a track. The more exclusively speech-like the recording (e.g. talk show, audio book, poetry), the higher the value",
                Loudness: "The overall loudness of a track in decibels (dB) averaged across the entire track",
                Popularity: "Calculated by algorithm and is based, in the most part, on the total number of plays the track has had and how recent those plays are"
            },
            newPlaylist: {
                PlaylistName: "RecommendMe",
                IsPublic: true
            },
            CircleWidth: window.innerWidth < 600 ? 170 : 240,
            KnobRadius: 45,
            pageIndex: 0,
            noArtistSubmissionError: false
        };

        this.handleMoodChange = this.handleMoodChange.bind(this)
        this.handleGenreRankingChange = this.handleGenreRankingChange.bind(this)
        this.getGenreOptions = this.getGenreOptions.bind(this)
        this.handlePopularityRadio = this.handlePopularityRadio.bind(this)
        this.openSpotify = this.openSpotify.bind(this)
        this.createPlaylist = this.createPlaylist.bind(this)
        this.addToMusicPlaylist = this.addToMusicPlaylist.bind(this)
        this.removeFromMusicPlaylist = this.removeFromMusicPlaylist.bind(this)
        this.addAllToMusicPlaylist = this.addAllToMusicPlaylist.bind(this)
        this.haveAddedAllSongsToMusicPlaylist = this.haveAddedAllSongsToMusicPlaylist.bind(this)
        this.removeAllFromMusicPlaylist = this.removeAllFromMusicPlaylist.bind(this)
        this.saveMusicPlaylist = this.saveMusicPlaylist.bind(this)
        this.selectMusicPlaylist = this.selectMusicPlaylist.bind(this)
        this.addToArtistPlaylist = this.addToArtistPlaylist.bind(this)
        this.removeFromArtistPlaylist = this.removeFromArtistPlaylist.bind(this)
        this.addAllToArtistPlaylist = this.addAllToArtistPlaylist.bind(this)
        this.removeAllFromArtistPlaylist = this.removeAllFromArtistPlaylist.bind(this)
        this.haveAddedAllSongsToArtistPlaylist = this.haveAddedAllSongsToArtistPlaylist.bind(this)
        this.saveArtistPlaylist = this.saveArtistPlaylist.bind(this)
        this.selectArtistPlaylist = this.selectArtistPlaylist.bind(this)
        this.getMusicRecommendations = this.getMusicRecommendations.bind(this)
        this.getArtistRecommendations = this.getArtistRecommendations.bind(this)
        this.handleNewPlaylistChange = this.handleNewPlaylistChange.bind(this)
        this.getMoodDescription = this.getMoodDescription.bind(this)
        this.showFullArtist = this.showFullArtist.bind(this)
        this.getFullArtists = this.getFullArtists.bind(this)
        this.handleCloseAboutArtist = this.handleCloseAboutArtist.bind(this)
        this.handleCloseCharts = this.handleCloseCharts.bind(this)
        this.getUserFollows = this.getUserFollows.bind(this)
        this.followArtist = this.followArtist.bind(this)
        this.unfollowArtist = this.unfollowArtist.bind(this)
        this.getCharts = this.getCharts.bind(this)
        this.getFeaturedPlaylists = this.getFeaturedPlaylists.bind(this)
        this.handleCloseFeaturedPlaylists = this.handleCloseFeaturedPlaylists.bind(this)
        this.addArtist = this.addArtist.bind(this)
        this.handleNewGenreChange = this.handleNewGenreChange.bind(this);
        this.onInsert = this.onInsert.bind(this)
        this.removeGenre = this.removeGenre.bind(this)
        this.closeGenresError = this.closeGenresError.bind(this);
        this.next = this.next.bind(this);
        this.changeCriteria = this.changeCriteria.bind(this);
        this.toggleDefinitions = this.toggleDefinitions.bind(this);
    }

    componentDidMount() {
        if (this.props.title) { document.title = this.props.title }

        const code = new URLSearchParams(window.location.search).get('code')
        if (code) {
            window.opener.spotifyCallback(code);
        } else {
            let currentUser = localStorage.getItem("access_token");
            if (currentUser && currentUser !== 'undefined' && currentUser !== '') {
                //try to refresh the access token for the current user
                let refreshToken = localStorage.getItem("refresh_token")
                if (refreshToken && refreshToken !== 'undefined' && refreshToken !== '') {
                    this.refreshUserAccessToken(refreshToken);
                } else {
                    this.setState({ "access_token": currentUser }, () => { this.getUserPlaylists(currentUser); this.getUserFollows(); });
                }
            } else {
                //if there isn't an access token, there shouldn't be a refresh token, but we can try anyways
                let refreshToken = localStorage.getItem("refresh_token")
                if (refreshToken && refreshToken !== 'undefined' && refreshToken !== '') {
                    this.refreshUserAccessToken(refreshToken);
                }
            }
        }

        this.getGenreOptions();
    }

    getMoodDescription(mood) {
        return this.state.MoodDescriptions[mood]
    }

    getUserPlaylists() {
        this.props.actions.getUserPlaylists()
            .then(() => {
                let playlists = [...this.props.music.userPlaylists];
                this.setState({
                    userPlaylists: playlists
                })
            })
            .catch(err => {
                if (err === 'Aborted') {
                    return;
                }
            });
    }

    getUserFollows() {
        this.props.actions.getUserFollows()
            .then(() => {
                let follows = [...this.props.music.userFollows];
                this.setState({ userFollows: follows })
            })
            .catch(err => {
                if (err === 'Aborted') {
                    return;
                }
            })
    }

    getPlaylist(id) {
        return new Promise((resolve, reject) => {
            this.props.actions.getPlaylist(id)
                .then(() => {
                    let playlist = [...this.props.music.playlistTracks];
                    resolve(playlist)
                })
                .catch(err => {
                    if (err === 'Aborted') {
                        return;
                    }
                    reject(err)
                })
        })
    }

    getGenreOptions() {
        this.props.actions.getGenres()
            .then(() => {
                let genres = [...this.props.music.genres];
                let selectOptions = [];
                genres.forEach(genre => {
                    selectOptions.push({ label: genre, value: genre })
                })
                this.setState({
                    genreOptions: genres,
                    selectOptions
                })
            })
            .catch(err => {
                if (err === 'Aborted') {
                    return;
                }
            });
    }

    handlePopularityRadio() {
        this.setState(prevState => ({ searchLessPopularArtists: !prevState.searchLessPopularArtists }))
    }

    handleMoodChange(field, val) {
        let mood = this.state.inputs.Mood;

        mood[field] = parseInt(val);

        this.setState({ mood });
    }

    handleGenreRankingChange(genreRankings) {
        let musicPreferences = this.state.inputs
        musicPreferences.GenreRankings = genreRankings;
        this.setState({ musicPreferences, genreError: "", genreErrors: [], genreInputError: '' })
    }

    removeGenre(id, name) {
        let tempArray = this.state.inputs.GenreRankings;

        let matchingGenre;
        if (id && id !== "0") {
            matchingGenre = tempArray.findIndex(it => it.Genre.Id === id)
        } else {
            matchingGenre = tempArray.findIndex(it => it.Genre.Name.toLowerCase() === name.toLowerCase())
        }

        if (matchingGenre !== undefined && matchingGenre !== -1) {
            tempArray.splice(matchingGenre, 1);
            //rank
            tempArray.sort((a, b) => a.Ranking > b.Ranking).forEach((item, index) => {
                item.Ranking = index + 1;
            })
            this.setState({ tempArray })
        }

        this.setState({ genreErrors: [], genreInputError: '' })
    }

    handleFavoriteArtistsChange(index, id, name) {
        let favoriteArtists = this.state.inputs.FavoriteArtists;
        if (!favoriteArtists[index]) {
            const objsNeeded = (index + 1) - favoriteArtists.length;
            for (let i = 0; i < objsNeeded; i++) {
                favoriteArtists.push({})
            }
        }
        favoriteArtists[index].Name = name || '';
        favoriteArtists[index].SpotifyId = id || '';
        this.setState({ favoriteArtists })
    }

    addArtist() {
        this.setState(prevState => ({ numArtists: prevState.numArtists + 1 }))
    }

    // HANDLE NEW GENRE NAME CHANGE
    handleNewGenreChange(selectedOption) {
        this.setState({
            selectedOption,
            newGenre: selectedOption.label
        });
    }

    // ADD TO THE GRID
    onInsert(genre, fromInputElement, e) {
        if (e) { e.preventDefault() }
        if (genre.length > 0) {
            //don't allow duplicates
            if (this.state.inputs.GenreRankings.find(it => it.Genre.Name.toLowerCase() === genre.toLowerCase())) {
                if (fromInputElement) {
                    this.setState({ genreInputError: "This genre is already ranked." });
                    return;
                }
                else if (this.state.displayGenreOptions.find(it => it.toLowerCase() === genre.toLowerCase())) {
                    let genreErrors = this.state.genreErrors;
                    genreErrors.push({ genre: genre.toLowerCase(), error: "This genre is already ranked.", show: true })
                    this.setState({ genreErrors });
                    return;
                }
            }
            else if (this.state.inputs.GenreRankings.length >= 3) {
                if (fromInputElement) {
                    this.setState({ genreInputError: "You may only select up to 3 genres. Please remove an existing selection and try again." });
                    return;
                }
                else if (this.state.displayGenreOptions.find(it => it.toLowerCase() === genre.toLowerCase())) {
                    let genreErrors = this.state.genreErrors;
                    genreErrors.push({ genre: genre.toLowerCase(), error: "You may only select up to 3 genres. Please remove an existing selection and try again.", show: true })
                    this.setState({ genreErrors });
                    return;
                }
            }

            //add to displayed genres
            let displayGenres = this.state.displayGenreOptions;
            if (!this.state.displayGenreOptions.find(it => it.toLowerCase() === genre.toLowerCase())) { displayGenres.push(genre) }

            const fullGenre = {
                Ranking: this.state.inputs.GenreRankings.length + 1, Genre: { Name: genre }
            }

            this.handleGenreRankingChange([...this.state.inputs.GenreRankings, fullGenre])

            //reset
            this.setState({
                newGenre: '',
                selectedOption: '',
                displayGenres
            })
        }
    }

    closeGenresError(genre) {
        let genreErrors = this.state.genreErrors
        const error = genreErrors.findIndex(it => it.genre.toLowerCase() === genre.toLowerCase())
        genreErrors.splice(error, 1)
        this.setState({ genreErrors })
    }

    closeGenresInputError() {
        this.setState({ genreInputError: '' })
    }

    openSpotify() {
        let currentUrl = window.location.href;
        let popup = window.open(`https://accounts.spotify.com/authorize?client_id=2bd8171fceb44153b8b43977586bce86&response_type=code&redirect_uri=${currentUrl}&scope=user-read-private,playlist-modify-public,playlist-modify-private,playlist-read-private,user-follow-read,user-follow-modify&show_dialog=true`, '_blank', 'width=800,height=600')

        window.spotifyCallback = (payload) => {
            this.props.actions.loginToSpotify({ "code": payload, "redirectUri": currentUrl })
                .then(() => {
                    const accessToken = this.props.music.spotifyAccessToken
                    if (accessToken) {
                        popup.close()
                        localStorage.setItem("access_token", accessToken)
                        this.setState({ access_token: accessToken })
                        this.getUserPlaylists();
                        this.getUserFollows();
                    } else {
                        popup.close()
                        alert("Unable to login to spotify.")
                        localStorage.setItem("access_token", '')
                        this.setState({ access_token: '' })
                    }
                    const refreshToken = this.props.music.spotifyRefreshToken
                    if (refreshToken) {
                        localStorage.setItem("refresh_token", refreshToken)
                    }
                }).catch(() => {
                    popup.close()
                    alert("Unable to login to spotify.")
                    localStorage.setItem("access_token", '')
                    this.setState({ access_token: '' })
                })
        }

    }

    refreshUserAccessToken(refreshToken) {
        this.props.actions.refreshAccessToken({ "refreshToken": refreshToken })
            .then(() => {
                const accessToken = this.props.music.spotifyAccessToken
                if (accessToken) {
                    localStorage.setItem("access_token", accessToken)
                    this.setState({ access_token: accessToken })
                    this.getUserPlaylists();
                    this.getUserFollows();
                } else {
                    localStorage.setItem("access_token", '')
                    this.setState({ access_token: '' })
                }
                const newRefreshToken = this.props.music.spotifyRefreshToken
                if (newRefreshToken) {
                    localStorage.setItem("refresh_token", newRefreshToken)
                }
            }).catch(() => {
                localStorage.setItem("access_token", '')
                this.setState({ access_token: '' })
            })
    }

    createPlaylist() {
        if (!this.state.newPlaylist.PlaylistName || this.state.newPlaylist.PlaylistName.length === 0) { alert("Invalid playlist name."); return; }
        this.setState({ createPlaylistSuccess: null, creatingPlaylist: true })
        this.props.actions.createPlaylist("?playlistName=" + this.state.newPlaylist.PlaylistName + "&isPublic=" + this.state.newPlaylist.IsPublic)
            .then(() => {
                let playlist = this.props.music.createPlaylist
                if (playlist && playlist.Id != null) {
                    let userPlaylistsState = this.state.userPlaylists
                    userPlaylistsState.unshift({ "Id": playlist.Id, "Name": playlist.Name })
                    this.setState({ createPlaylistSuccess: true, creatingPlaylist: false, userPlaylists: userPlaylistsState })
                } else {
                    this.setState({ createPlaylistSuccess: false, creatingPlaylist: false })
                }
            }).catch((err) => {
                this.setState({ createPlaylistSuccess: false, creatingPlaylist: false })
            })
    }

    addToMusicPlaylist(uri) {
        let playlist = this.state.playlistSongs.music
        playlist.push(uri)

        this.setState({ playlist })
    }

    removeFromMusicPlaylist(uri) {
        let playlist = this.state.playlistSongs.music
        const index = playlist.indexOf(uri);
        if (index !== -1) {
            playlist.splice(index, 1);
        }

        this.setState({ playlist })
    }

    addAllToMusicPlaylist() {
        let playlist = this.state.playlistSongs
        let recommendations = this.state.recommendations.music
        playlist.music = recommendations.filter(music => !this.state.savedPlaylistSongs.music.includes(music.Uri)).map(music => music.Uri)

        this.setState({ playlist })
    }

    haveAddedAllSongsToMusicPlaylist() {
        if (this.state.playlistSongs.music.length !== (this.state.recommendations.music.length - this.state.savedPlaylistSongs.music.length)) { return false; }

        let allSongs = this.state.recommendations.music
        let allUnsavedSongUris = allSongs.filter(it => !this.state.savedPlaylistSongs.music.includes(it.Uri)).map(option => option.Uri)
        let allPlaylistSongUris = this.state.playlistSongs.music
        for (let i = 0; i < allUnsavedSongUris.length; i++) {
            if (!allPlaylistSongUris.includes(allUnsavedSongUris[i])) { return false; }
        }
        return true;
    }

    removeAllFromMusicPlaylist() {
        let playlist = this.state.playlistSongs
        playlist.music = []

        this.setState({ playlist })
    }

    selectMusicPlaylist(id) {
        this.setState({ musicPlaylistId: id, musicPlaylistSaveError: null })

        if (id === "") {
            this.setState(prevState => ({ savedPlaylistSongs: { ...prevState.savedPlaylistSongs, music: [] } }))
        }
        else {
            this.getPlaylist(id).then(res => {
                if (this.state.recommendations.music.length > 0) {
                    let savedPlaylists = this.state.savedPlaylistSongs;
                    let alreadySavedRecommendations = this.state.recommendations.music.filter(song => res.map(opt => opt.Id).includes(song.Id))
                    if (alreadySavedRecommendations && alreadySavedRecommendations.length > 0) {
                        const alreadySavedUris = alreadySavedRecommendations.map(song => song.Uri)
                        savedPlaylists.music = alreadySavedUris
                    } else {
                        savedPlaylists.music = []
                    }
                    this.setState({ savedPlaylists })
                }
            })
        }
    }

    saveMusicPlaylist() {
        const saveToast = toast.loading("Saving playlist...", { type: "info" })
        this.setState({ savingMusicPlaylist: true, musicPlaylistSaveError: null })
        let playlistSongs = this.state.playlistSongs
        this.props.actions.savePlaylist({ "PlaylistId": this.state.musicPlaylistId, "Uris": playlistSongs.music })
            .then(() => {
                let savedPlaylists = this.state.savedPlaylistSongs;
                savedPlaylists.music = savedPlaylists.music.concat(playlistSongs.music);

                //empty playlist songs so it doesn't keep saving songs repeatedly each click
                playlistSongs.music = [];
                this.setState({ savingMusicPlaylist: false, savedPlaylists, playlistSongs })
                toast.update(saveToast, { render: "Success!", type: "success", autoClose: 5000, isLoading: false })
            }).catch((e) => {
                this.setState({ savingMusicPlaylist: false, musicPlaylistSaveError: e })
                toast.update(saveToast, { render: "Failed to save playlist", type: "error", autoClose: 5000, isLoading: false })
            })
    }

    addToArtistPlaylist(uri) {
        let playlist = this.state.playlistSongs.artists
        playlist.push(uri)

        this.setState({ playlist })
    }

    removeFromArtistPlaylist(uri) {
        let playlist = this.state.playlistSongs.artists
        const index = playlist.indexOf(uri);
        if (index !== -1) {
            playlist.splice(index, 1);
        }

        this.setState({ playlist })
    }

    addAllToArtistPlaylist(artist) {
        let playlist = this.state.playlistSongs
        let recommendations = this.state.recommendations.artists[artist]
        playlist.artists = [...new Set(playlist.artists.concat(recommendations.TopTracks.Tracks.filter(music => music.Uri && !this.state.savedPlaylistSongs.artists.includes(music.Uri)).map(music => music.Uri)))]

        this.setState({ playlist })
    }

    removeAllFromArtistPlaylist(artist) {
        let playlist = this.state.playlistSongs
        let recommendations = this.state.recommendations.artists[artist]
        playlist.artists = playlist.artists.filter(uri => !recommendations.TopTracks.Tracks.map(track => track.Uri).includes(uri))

        this.setState({ playlist })
    }

    haveAddedAllSongsToArtistPlaylist(artist) {
        let allSavableSongs = this.state.recommendations.artists[artist].TopTracks.Tracks.filter(songs => songs.Uri)
        let allSavableSongUris = allSavableSongs.map(track => track.Uri)
        if (this.state.playlistSongs.artists.filter(uri => allSavableSongUris.includes(uri)).length !== (allSavableSongUris.length - this.state.savedPlaylistSongs.artists.filter(uri => allSavableSongUris.includes(uri)).length)) { return false; }

        let allUnsavedSongUris = allSavableSongs.filter(it => !this.state.savedPlaylistSongs.artists.includes(it.Uri)).map(option => option.Uri)
        let allPlaylistSongUris = this.state.playlistSongs.artists
        for (let i = 0; i < allUnsavedSongUris.length; i++) {
            if (!allPlaylistSongUris.includes(allUnsavedSongUris[i])) { return false; }
        }
        return true;
    }

    selectArtistPlaylist(id) {
        this.setState({ artistPlaylistId: id, artistPlaylistSaveError: null })

        if (id === "") {
            this.setState(prevState => ({ savedPlaylistSongs: { ...prevState.savedPlaylistSongs, artists: [] } }))
        }
        else {
            let savedPlaylists = this.state.savedPlaylistSongs;
            savedPlaylists.artists = [];
            this.getPlaylist(id).then(res => {
                this.state.recommendations.artists.forEach(artist => {
                    let alreadySavedRecommendations = artist.TopTracks.Tracks.filter(song => res.map(opt => opt.Id).includes(song.Id))
                    if (alreadySavedRecommendations && alreadySavedRecommendations.length > 0) {
                        const alreadySavedUris = alreadySavedRecommendations.map(song => song.Uri)
                        savedPlaylists.artists = savedPlaylists.artists.concat(alreadySavedUris)
                    }
                    this.setState({ savedPlaylists })
                })
            })
        }
    }

    saveArtistPlaylist() {
        const saveToast = toast.loading("Saving playlist...", { type: "info" })
        this.setState({ savingArtistPlaylist: true, artistPlaylistSaveError: null })
        let playlistSongs = this.state.playlistSongs
        this.props.actions.savePlaylist({ "PlaylistId": this.state.artistPlaylistId, "Uris": playlistSongs.artists })
            .then(() => {
                let savedPlaylists = this.state.savedPlaylistSongs;
                savedPlaylists.artists = savedPlaylists.artists.concat(playlistSongs.artists);

                //empty playlist songs so it doesn't keep saving songs repeatedly each click
                playlistSongs.artists = [];

                this.setState({ savingArtistPlaylist: false, savedPlaylists, playlistSongs })
                toast.update(saveToast, { render: "Success!", type: "success", autoClose: 5000, isLoading: false })
            }).catch((e) => {
                this.setState({ savingArtistPlaylist: false, artistPlaylistSaveError: e })
                toast.update(saveToast, { render: "Failed to save playlist", type: "error", autoClose: 5000, isLoading: false })
            })
    }

    handleNewPlaylistChange(field, e) {
        let value = ""
        const isCheckbox = e.target.type === 'checkbox';
        if (isCheckbox) {
            value = e.target.checked;
        } else {
            value = e.target.value;
        }

        let newPlaylist = this.state.newPlaylist;
        newPlaylist[field] = value;

        this.setState({ newPlaylist })
    }

    handleValidation() {
        if (!this.state.inputs.FavoriteArtists || !this.state.inputs.FavoriteArtists.length || !this.state.inputs.FavoriteArtists.find(a => a.Name)) {
            this.setState({ noArtistSubmissionError: true })
            return false;
        }
        return true;
    }

    getFullArtists(artists) {
        let fullArtistsState = this.state.fullArtists;

        //Remove artists that are already retrieved (we use this method for both song and artist recommendations)
        artists = artists.filter(artist => !fullArtistsState.map(artists => artists.artistId).includes(artist))

        artists.forEach(artist => {
            fullArtistsState.push({ artistId: artist, artist: null, error: '', fetching: true, show: false })
        })

        this.setState({
            fullArtistsState
        })

        this.props.actions.getArtists(artists)
            .then(() => {
                let artistsResp = [...this.props.music.artists];
                artistsResp.forEach(artist => {
                    let artistState = fullArtistsState.find(it => it.artistId === artist.Id);
                    if (artistState) {
                        artistState.artist = artist; artistState.fetching = false;
                    }
                })

                this.setState({ fullArtistsState })
            })
            .catch(err => {
                if (err === 'Aborted') {
                    return;
                }

                fullArtistsState.forEach(fullArtist => {
                    fullArtist.fetching = false;
                    fullArtist.error = err;
                })

                this.setState({
                    fullArtistsState
                });
            })
    }

    showFullArtist(artist) {
        let fullArtistsState = this.state.fullArtists;
        let fullArtist = fullArtistsState.find(it => it.artistId === artist)
        if (fullArtist) { fullArtist.show = true; this.setState({ fullArtistsState }) }
    }

    handleCloseAboutArtist(artist) {
        let fullArtistsState = this.state.fullArtists;
        let artistState = fullArtistsState.find(it => it.artistId === artist)
        artistState.show = false;

        this.setState({ fullArtistsState })
    }

    handleCloseCharts() {
        this.setState({ showCharts: false })
    }

    handleCloseFeaturedPlaylists() {
        this.setState({ showFeaturedPlaylists: false })
    }

    followArtist(artist) {
        let fakeSuccess = this.state.userFollows;
        fakeSuccess.push(artist);
        this.setState({ fakeSuccess })

        this.props.actions.followArtist(artist)
            .then(() => {
            })
            .catch(err => {
                if (err === 'Aborted') {
                    return;
                }

                let fullArtist = this.state.fullArtists.find(it => it.artistId === artist)
                fullArtist.followError = err;
                const indexOfFakeSuccessfulFollow = fakeSuccess.indexOf(artist)
                fakeSuccess.splice(indexOfFakeSuccessfulFollow, 1)
                this.setState({
                    fullArtist,
                    fakeSuccess
                });
            })
    }

    unfollowArtist(artist) {
        let fakeSuccess = this.state.userFollows;
        const indexOfFakeSuccessfulUnfollow = fakeSuccess.indexOf(artist)

        if (indexOfFakeSuccessfulUnfollow === -1) {
            let fullArtist = this.state.fullArtists.find(it => it.artistId === artist)
            fullArtist.followError = err;
            this.setState({
                fullArtist
            });

            return;
        }

        fakeSuccess.splice(indexOfFakeSuccessfulUnfollow, 1);
        this.setState({ fakeSuccess })

        this.props.actions.unfollowArtist(artist)
            .then(() => {
            })
            .catch(err => {
                if (err === 'Aborted') {
                    return;
                }

                let fullArtist = this.state.fullArtists.find(it => it.artistId === artist)
                fullArtist.followError = err;
                fakeSuccess.push(artist)
                this.setState({
                    fullArtist,
                    fakeSuccess
                });
            })
    }

    getCharts() {
        if (this.state.charts.length) {
            this.setState({ showCharts: true }); return;
        }
        this.setState({ showCharts: true, fetchingCharts: true })

        this.props.actions.getCharts()
            .then(() => {
                let charts = [...this.props.music.charts];
                if (charts.length === 0) { this.setState({ chartsError: "Unable to get charts", fetchingCharts: false }); return; }

                this.setState({ charts: charts, fetchingCharts: false })
            })
            .catch(err => {
                if (err === 'Aborted') {
                    return;
                }
                this.setState({
                    fetchingCharts: false,
                    chartsError: err
                });
            });
    }

    getFeaturedPlaylists() {
        if (this.state.featuredPlaylists.length) {
            this.setState({ showFeaturedPlaylists: true }); return;
        }
        this.setState({ showFeaturedPlaylists: true, fetchingFeaturedPlaylists: true, featuredPlaylistsError: '' })

        this.props.actions.getFeaturedPlaylists()
            .then(() => {
                let featuredPlaylists = [...this.props.music.featuredPlaylists];
                if (featuredPlaylists.length === 0) { this.setState({ featuredPlaylistsError: "Unable to get featured playlists", fetchingFeaturedPlaylists: false }); return; }

                this.setState({ featuredPlaylists: featuredPlaylists, fetchingFeaturedPlaylists: false })
            })
            .catch(err => {
                if (err === 'Aborted') {
                    return;
                }
                this.setState({
                    fetchingFeaturedPlaylists: false,
                    featuredPlaylistsError: err
                });
            });
    }

    getMusicRecommendations() {
        this.setState({
            playlistSongs: {
                ...this.state.savedPlaylistSongs,
                music: []
            },
            savedPlaylistSongs: {
                ...this.state.savedPlaylistSongs,
                music: []
            },
            recommendations: {
                ...this.state.recommendations,
                music: []
            },
            musicError: '',
            savingMusicPlaylist: false,
            gettingMusic: true
        }, () => {
            let inputs = JSON.parse(JSON.stringify(this.state.inputs)) //make a deep copy so as to not update the state when updating the variable
            inputs.FavoriteArtists = inputs.FavoriteArtists.filter(artist => artist.Name)
            this.props.actions.getMusicRecommendations(inputs)
                .then(() => {
                    let musicRecommendations = [...this.props.music.musicRecommendations];
                    if (musicRecommendations.length === 0) { this.setState({ musicError: "Unable to find any recommendations", gettingMusic: false }); return; }
                    let recommendations = this.state.recommendations;
                    recommendations.music = musicRecommendations;
                    this.setState({
                        gettingMusic: false,
                        recommendations
                    })

                    if (this.state.musicPlaylistId) {
                        this.selectMusicPlaylist(this.state.musicPlaylistId)
                    }

                    localStorage.setItem("MusicGenreRankings", JSON.stringify(this.state.inputs.GenreRankings))

                    //get the full artist for each rather than getting one at a time as they click on "More about ..."
                    this.getFullArtists(musicRecommendations.map(song => song.Artists[0].Id));
                })
                .catch(err => {
                    if (err === 'Aborted') {
                        return;
                    }
                    this.setState({
                        gettingMusic: false,
                        musicError: err
                    });
                });
        })
    }

    getArtistRecommendations() {
        this.setState({
            playlistSongs: {
                ...this.state.playlistSongs,
                artists: []
            },
            savedPlaylistSongs: {
                ...this.state.savedPlaylistSongs,
                artists: []
            },
            recommendations: {
                ...this.state.recommendations,
                artists: []
            },
            artistsError: '',
            savingArtistPlaylist: false,
            gettingArtists: true,
        }, () => {
            let artists = JSON.parse(JSON.stringify(this.state.inputs.FavoriteArtists)) //make a deep copy so as to not update the state when updating the variable
            artists = artists.filter(artist => artist.Name)
            this.props.actions.getRelatedArtists(artists, this.state.searchLessPopularArtists)
                .then(() => {
                    let relatedArtists = [...this.props.music.relatedArtists];
                    if (relatedArtists.length === 0) { this.setState({ artistsError: "Unable to find any related artists", gettingArtists: false }); return; }
                    let recommendations = this.state.recommendations;
                    recommendations.artists = relatedArtists;
                    this.setState({
                        gettingArtists: false,
                        recommendations
                    })

                    if (this.state.artistPlaylistId) {
                        this.selectArtistPlaylist(this.state.artistPlaylistId)
                    }

                    localStorage.setItem("MusicGenreRankings", JSON.stringify(this.state.inputs.GenreRankings))

                    //get the full artist for each rather than getting one at a time as they click on "More about ..."
                    this.getFullArtists(relatedArtists.map(artist => artist.Id));
                })
                .catch(err => {
                    if (err === 'Aborted') {
                        return;
                    }
                    this.setState({
                        gettingArtists: false,
                        artistsError: err
                    });
                });
        })
    }

    async getRecommendations(e) {
        e.preventDefault();

        if (!this.handleValidation()) {
            return;
        }

        this.next();

        this.setState({
            createPlaylistSuccess: null,
            creatingPlaylist: false
        }, () => {
            this.getMusicRecommendations();
            this.getArtistRecommendations();
        })
    }

    next(index) {
        const self = this;
        const { changingCriteria } = this.state;

        this.setState({ transitioning: true }, () => {
            let sections = document.getElementsByClassName('music-section');
            if (sections && sections.length) {
                if (!index && index !== 0) { index = sections.length - 1; }
                sections = Array.from(sections);
                const currentContent = sections[index];

                if (currentContent) {
                    currentContent.classList.remove('fade-in-delayed-2', 'fade-in-delayed-1');
                    currentContent.classList.add('fade-out');
                }

                const nextButton = document.getElementsByClassName('next-button');
                let cover = document.getElementsByClassName('cover');
                let newCoverElement;
                if (cover && cover.length) {
                    cover = Array.from(cover);
                    newCoverElement = cover.find(el => el.classList.contains('d-none'));
                }
                if (nextButton && nextButton.length) {
                    if (nextButton[0].classList.contains('fade-in-delayed-2')) {
                        nextButton[0].classList.remove('fade-in-delayed-2');
                        nextButton[0].classList.add(!changingCriteria ? 'fade-out-and-in' : 'fade-out-and-in-fast');
                    } else {
                        nextButton[0].classList.remove("fade-out-and-in", "fade-out-and-in-fast");
                        setTimeout(function () {
                            nextButton[0].classList.add(!newCoverElement || changingCriteria ? 'fade-out-and-in-fast' : "fade-out-and-in");
                        }, 100);
                    }
                }

                if (newCoverElement && !changingCriteria) {
                    newCoverElement.classList.remove('d-none');
                    newCoverElement.classList.add('fade-in-and-out-delayed');
                }

                if (sections && sections.length && sections[index + 1]) {
                    const nextSection = sections[index + 1]
                    nextSection.classList.remove('d-none');
                    nextSection.classList.add(!changingCriteria ? 'fade-in-delayed-2' : 'fade-in-delayed-1');
                }

                if (sections.length === index + 2) {
                    setTimeout(function () {
                        if (currentContent) {
                            currentContent.classList.add('d-none');
                        }
                        self.setState({ transitioning: false, ready: true, changingCriteria: false, pageIndex: index + 1 })
                    }, 2000);
                } else {
                    setTimeout(function () {
                        if (currentContent) {
                            currentContent.classList.add('d-none');
                        }
                        self.setState({ transitioning: false, ready: false, nextSection: sections.length > index + 1, pageIndex: index + 1 })
                    }, 2000);
                }
            } else {
                setTimeout(function () {
                    self.setState({ transitioning: false, ready: false, nextSection: false, pageIndex: index + 1 })
                }, 2000);
            }
        })
    }

    changeCriteria() {
        const self = this;
        this.setState({ changingCriteria: true }, () => {
            const resultSection = document.getElementById('result-section');
            if (resultSection) {
                let resultFades = resultSection.getElementsByClassName('fade-in-delayed-1');
                for (const el of resultFades) {
                    el.classList.remove('fade-in-delayed-1');
                    el.classList.add('fade-out');
                }
                resultFades = resultSection.getElementsByClassName('fade-in-delayed-half');
                for (const el of resultFades) {
                    el.classList.remove('fade-in-delayed-half');
                    el.classList.add('fade-out');
                }

                const nextButton = document.getElementsByClassName('next-button');
                if (nextButton && nextButton.length) {
                    nextButton[0].classList.remove("fade-out-and-in", 'fade-out-and-in-fast', 'fade-out-and-in');
                    setTimeout(function () {
                        nextButton[0].classList.add('fade-out-and-in-fast');
                    }, 100);
                }

                let sections = document.getElementsByClassName('music-section');
                if (sections && sections.length) {
                    for (const section of sections) {
                        if (!section.classList.contains('d-none')) {
                            section.classList.add('d-none');
                        }
                    }
                    setTimeout(function () {
                        self.setState({
                            playlistSongs: {
                                ...self.state.savedPlaylistSongs,
                                music: [],
                                artists: []
                            },
                            savedPlaylistSongs: {
                                ...self.state.savedPlaylistSongs,
                                music: [],
                                artists: []
                            },
                            recommendations: {
                                ...self.state.recommendations,
                                music: [],
                                artists: []
                            },
                            artistsError: '',
                            savingArtistPlaylist: false,
                            gettingArtists: false,
                            musicError: '',
                            savingMusicPlaylist: false,
                            gettingMusic: false
                        }, () => {
                            self.next(-1);
                        });
                    }, 2000);
                }

            }
        })
    }

    toggleDefinitions() {
        this.setState({ showDefinitions: !this.state.showDefinitions })
    }

    render() {
        const sliderTheme = createTheme({
            overrides: {
                MuiSlider: {
                    markLabel: {
                        fontSize: ".77rem !important"
                    },
                    mark: {
                        width: '3px',
                        height: '4px',
                    },
                    valueLabel: {
                        left: "calc(- 50 % - -2px)"
                    },
                    thumb: {
                        color: "#1DB954",
                        height: 18,
                        width: 18,
                        marginTop: -6,
                        marginLeft: -9,
                        '&:focus, &:hover, &$active': {
                            boxShadow: 'inherit',
                        },
                        zIndex: 5
                    },
                    track: {
                        height: 4,
                        borderRadius: 4,
                        color: '#0792A0'
                    },
                    rail: {
                        width: '102%',
                        height: 4,
                        borderRadius: 4,
                        color: 'black'
                    }
                }
            }
        });

        const renderTooltip = (info, genre) => (
            <Tooltip id="button-tooltip" hidden={genre ? !this.state.genreErrors.find(it => it.genre.toLowerCase() === genre.toLowerCase()) : !this.state.genreInputError}>
                <span>{info}</span>
                <br />
                <button type="button" aria-label="Close" className="btn btn-secondary btn-sm btn-block p-0 mt-1" onClick={() => genre ? this.closeGenresError(genre) : this.closeGenresInputError()}>
                    <span aria-hidden="true">Close</span>
                </button>
            </Tooltip>
        );

        const customStyles = {
            option: (base, state) => ({
                ...base,
                fontSize: '1.0em',
                color: '#0792A0',
                background: state.isFocused ? '#49759d30' : 'inherit',
            }),
            container: (base) => ({
                ...base,
                minWidth: '200px',
                maxWidth: '215px',
                margin: 'auto',
                display: 'inline-block'
            }),
            control: (base) => ({
                ...base,
                background: '#f1f1f1',
                border: '1px solid #d8d8d8',
                borderRadius: '0 0 5px 5px',
                minWidth: '200px',
                maxWidth: '215px',
                margin: 'auto'
            }),
            menu: (base) => ({
                ...base,
                width: '215px',
                marginTop: '1px',
                marginBottom: '70px',
                position: 'relative'
            }),
            menuList: (base) => ({
                ...base,
                marginTop: '1px'
            }),
            indicatorSeparator: (base) => ({
                ...base,
                display: "none"
            }),
            valueContainer: (base) => ({
                ...base,
                textAlign: 'left'
            })
        }

        const { CircleWidth, KnobRadius } = this.state;

        return (
            <React.Fragment>
                <Modal show={this.state.showDefinitions} onHide={this.toggleDefinitions}>
                    <Modal.Header closeButton>
                        <Modal.Title>Mood Definitions</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {Object.keys(this.state.inputs.Mood).map((opt, i) =>
                            <React.Fragment key={i}>
                                <div className="mood-description text-monospace">
                                    <small className="text-muted font-italic">{opt}: {this.getMoodDescription(opt)}</small>
                                </div>
                                {i !== Object.keys(this.state.inputs.Mood).length - 1 &&
                                    <hr />
                                }
                            </React.Fragment>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.toggleDefinitions}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>

                <ChartsModal
                    title="Charts"
                    handleClose={this.handleCloseCharts}
                    data={this.state.charts}
                    show={this.state.showCharts}
                    error={this.state.chartsError}
                    fetching={this.state.fetchingCharts}
                    iOS={this.props.iOS}
                />
                <ChartsModal
                    title="Featured Playlists"
                    handleClose={this.handleCloseFeaturedPlaylists}
                    data={this.state.featuredPlaylists}
                    show={this.state.showFeaturedPlaylists}
                    error={this.state.featuredPlaylistsError}
                    fetching={this.state.fetchingFeaturedPlaylists}
                    iOS={this.props.iOS}
                />
                {(this.state.gettingMusic || this.state.gettingArtists || this.state.recommendations.music.length || this.state.recommendations.artists.length || this.state.musicError || this.state.artistsError) ?
                    <div className={!this.state.changingCriteria ? "fade-in-delayed-1" : "fade-out"}>
                        <div className="bd spotify-charts-div position-absolute">
                            <button
                                title="Charts"
                                className="spotify-charts"
                                type="button"
                                onClick={this.getCharts.bind(this)}>
                                <span className="fa fa-spotify mr-2 spotify-icon" />
                                <span>Charts</span>
                            </button>
                        </div>
                        <div className="bd spotify-featured-playlists-div position-absolute">
                            <button
                                title="Featured Playlists"
                                className="spotify-featured-playlists"
                                type="button"
                                onClick={this.getFeaturedPlaylists.bind(this)}>
                                <span className="fa fa-spotify mr-2 spotify-icon" />
                                <span>Playlists</span>
                            </button>
                        </div>
                    </div>
                    : null
                }
                <div>
                    <div id="music" className="slow-component text-color-burlywood">
                        <div id="cover-container">
                            <h1 className="cover component-header fade-in-and-out">MUSIC</h1>
                            <div className="cover fade-in-and-out-delayed"><h1 className="circle-container">1</h1><h2>Describe the music you're looking for</h2></div>
                            <div className="cover d-none"><h1 className="circle-container">2</h1><h2>Choose up to 3 genres to be used in gathering your recommendations</h2></div>
                            <div className="cover d-none"><h1 className="circle-container">3</h1><h2>Choose up to 3 of your favorite artists/bands</h2></div>
                        </div>
                        <div id="content" className="fade-in-delayed-2">
                            <div className="center-form">
                                <ul className="padding-inline-start-0 sections">
                                    <div className="music-section">
                                        <h2 className="component-header">MUSIC</h2>
                                        <div className="text-center criteria-info">
                                            <h6 className="font-italic"><strong>1. Describe the music you're looking for</strong><small className="text-muted small-info">*Set any option to 0 if you do not wish to filter based on that criterion at all.</small></h6>
                                            <button type="button" id="definitions-button" className="btn btn-link spotify-background-color" onClick={this.toggleDefinitions}>Definitions</button>
                                            <div id="moods">
                                                <div className="bd mood-buttons-container d-flex">
                                                    {Object.keys(this.state.inputs.Mood).map((opt, i) =>
                                                        <div key={i} className="slider-container">
                                                            <div className="textContainer">
                                                                {this.state.inputs.Mood[opt]}
                                                                <div className="minute">{opt}</div>
                                                            </div>
                                                            <CircularSlider
                                                                value={this.state.inputs.Mood[opt]}
                                                                hideLabelValue
                                                                stepSize={1}
                                                                onChange={val => this.handleMoodChange(opt, val)}
                                                                max={5}
                                                                progressColorFrom={gradientColorFrom}
                                                                progressColorTo={gradientColorTo}
                                                                knobSize={KnobRadius}
                                                                width={CircleWidth}
                                                                knobPosition="bottom"
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="music-section d-none">
                                        <h2 className="component-header">MUSIC</h2>
                                        <h6 className="font-italic"><strong>2. Choose up to 3 genres to be used in gathering your recommendations</strong></h6>
                                        <div className="box-container">
                                            <div className="text-center criteria-info">
                                                <MusicGenreDraggableComponent
                                                    onChange={this.handleGenreRankingChange}
                                                    items={this.state.inputs.GenreRankings}
                                                    genreOptions={this.state.genreOptions}
                                                    removeGenre={this.removeGenre}
                                                />
                                                <div className="mx-auto mt-2" style={{ maxWidth: '500px' }}>
                                                    {
                                                        this.state.displayGenreOptions.sort().map((option, i) =>
                                                            <OverlayTrigger
                                                                key={i}
                                                                show={this.state.genreErrors.find(error => error.genre.toLowerCase() === option.toLowerCase()) !== undefined}
                                                                placement="bottom"
                                                                overlay={renderTooltip(this.state.genreErrors.find(error => error.genre.toLowerCase() === option.toLowerCase()) ? this.state.genreErrors.find(error => error.genre.toLowerCase() === option.toLowerCase()).error : '', option)}
                                                            >
                                                                <button type="button"
                                                                    className={`btn badge p-2 m-1${this.state.inputs.GenreRankings.find(ranking => ranking.Genre.Name.toLowerCase() === option.toLowerCase()) ? ' badge-info text-light' : ' badge-light'}`}
                                                                    onClick={() => this.state.inputs.GenreRankings.find(ranking => ranking.Genre.Name.toLowerCase() === option.toLowerCase()) ? this.removeGenre(null, option) : this.onInsert(option)}>{option.toLowerCase()} <span
                                                                        className={`fa${this.state.inputs.GenreRankings.find(ranking => ranking.Genre.Name.toLowerCase() === option.toLowerCase()) ? ' fa-minus text-danger' : ' fa-plus text-primary'}`} />
                                                                </button>
                                                            </OverlayTrigger>
                                                        )
                                                    }
                                                    <div className="mt-1">
                                                        <span className="h6 font-italic text-primary">Additional Genres</span><br />
                                                        <form onSubmit={(e) => this.onInsert(this.state.newGenre, true, e)}>
                                                            <OverlayTrigger
                                                                show={this.state.genreInputError !== ''}
                                                                placement="bottom"
                                                                overlay={renderTooltip(this.state.genreInputError)}
                                                            >
                                                                <Select
                                                                    menuPortalTarget={document.querySelector('body')}
                                                                    options={this.state.selectOptions.filter(genre => !this.state.inputs.GenreRankings.map(a => a.Genre.Name.toLowerCase()).includes(genre.label.toLowerCase()) && !this.state.displayGenreOptions.map(a => a.toLowerCase()).includes(genre.label.toLowerCase()))}
                                                                    noOptionsMessage={() => { return (<span>No genres found</span>) }}
                                                                    placeholder={"Search..."}
                                                                    styles={customStyles}
                                                                    onChange={this.handleNewGenreChange}
                                                                    value={this.state.selectedOption}
                                                                />
                                                            </OverlayTrigger>
                                                            <button type="button" id={'insert-button'} disabled={!this.state.newGenre} className="button btn-outline-primary ml-2" onClick={() => this.onInsert(this.state.newGenre, true)}>Insert</button>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="music-section d-none">
                                        <h2 className="component-header">MUSIC</h2>
                                        <h6 className="font-italic"><strong>3. Choose up to 3 of your favorite artist(s)/band(s)</strong></h6>
                                        <div className="box-container">
                                            <div className="text-center criteria-info">
                                                <div>
                                                    {Array.from({ length: this.state.numArtists }, (_, i) =>
                                                        <div key={i} className="mb-1">
                                                            <SearchArtists
                                                                index={i}
                                                                onChange={this.handleFavoriteArtistsChange.bind(this)}
                                                                value={this.state.inputs.FavoriteArtists[i] ? this.state.inputs.FavoriteArtists[i].Name : ""}
                                                                id={this.state.inputs.FavoriteArtists[i] ? this.state.inputs.FavoriteArtists[i].SpotifyId : ""}
                                                                searchArtists={this.props.actions.searchArtists}
                                                                artists={this.props.music.artists}
                                                            />
                                                        </div>
                                                    )}
                                                    <button type="button" disabled={this.state.numArtists >= 3} className="btn btn-sm btn-primary" onClick={this.addArtist}>Add Another</button>
                                                    {
                                                        this.state.noArtistSubmissionError && (!this.state.inputs.FavoriteArtists || !this.state.inputs.FavoriteArtists.length || !this.state.inputs.FavoriteArtists.find(a => a.Name)) ?
                                                            <p className="text-danger">You must provide at least one favorite artist/band</p>
                                                            :
                                                            this.state.numArtists >= 3 ?
                                                                <p>(Max of 3 allowed)</p>
                                                                : null
                                                    }
                                                </div>
                                                <div className="row mx-auto mt-2 px-4" style={{ justifyContent: 'center', textAlign: 'left' }}>
                                                    <div className="custom-control custom-switch switch-div">
                                                        <input className="custom-control-input" type="checkbox" id="flexRadio" onChange={this.handlePopularityRadio} checked={this.state.searchLessPopularArtists} />
                                                        <label className="custom-control-label smallish-text" htmlFor="flexRadio">For artist recommendations, search for the less popular</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ul>
                                <div className="fade-in-delayed-2 next-button music">
                                    {this.state.ready ?
                                        <button className="spotify-background-color btn btn-lg btn-block" type="button" onClick={this.getRecommendations.bind(this)} disabled={this.state.transitioning || this.state.gettingArtists || this.state.gettingMusic}>Recommend Me!</button>
                                        :
                                        this.state.nextSection ?
                                            <button type="button" className="spotify-background-color btn btn-lg btn-block shadow-sm" onClick={() => this.next(this.state.pageIndex)} disabled={this.state.transitioning}>Next</button>
                                            :
                                            this.state.recommendations.music.length || this.state.musicError ?
                                                <button type="button" className="spotify-background-color btn btn-lg btn-block shadow-sm" onClick={this.changeCriteria} disabled={this.state.transitioning}>Change Criteria</button>
                                                : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div >
                    <br />
                    <div id="result-section" className="music">
                        {
                            ((!this.state.gettingMusic && this.state.musicError === "" && this.state.recommendations.music.length > 0) || (!this.state.gettingArtists && this.state.artistsError === "" && this.state.recommendations.artists.length > 0)) &&
                            <div className="fade-in-delayed-half">
                                {
                                    (!this.state.access_token || this.state.access_token === "undefined" || this.state.access_token === "") ?
                                        <div className="text-center">
                                            <button className="btn spotify-background-color text-light text-capitalize" type="button" onClick={this.openSpotify}>
                                                <span className="fa fa-spotify mr-2 spotify-icon" />
                                                Login to Spotify to create a playlist
                                            </button>
                                        </div>
                                        :
                                        <div className="spotify-collapse">
                                            <Collapsible triggerClassName="CustomTriggerCSS" trigger="Create a Spotify playlist">
                                                <div className="container spotify-text-color text-monospace">
                                                    <div className="row">
                                                        <div className="col-sm-9 col-md-11 col-lg-11 col-centered">
                                                            <div className="row">
                                                                <div className="col-8 px-2 text-center">
                                                                    <label htmlFor="playlist-name" className="font-weight-bold">Name:</label>
                                                                    <input type="text" id="playlist-name" className="form-control px-2" onChange={this.handleNewPlaylistChange.bind(this, "PlaylistName")} value={this.state.newPlaylist.PlaylistName} />
                                                                </div>
                                                                <div className="col-4 px-2 text-center">
                                                                    <label htmlFor="is-public" className="font-weight-bold mb-0">Public:</label>
                                                                    <Checkbox color="primary" style={{ color: "#1DB954" }} id="is-public" className="form-control" onChange={this.handleNewPlaylistChange.bind(this, "IsPublic")} checked={this.state.newPlaylist.IsPublic} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <button className="col-sm-9 col-md-11 col-lg-11 col-centered btn spotify-background-color text-light" onClick={this.createPlaylist} type="button" disabled={this.state.creatingPlaylist} >{this.state.creatingPlaylist ? "Creating Playlist..." : "Create Spotify Playlist"}</button>
                                                    </div>
                                                </div>
                                                {this.state.createPlaylistSuccess && <div className="text-center spotify-font spotify-text-color"><strong>Success!</strong></div>}
                                                {this.state.createPlaylistSuccess === false && <div className="text-center spotify-font"><strong>Failed to create playlist.</strong></div>}
                                            </Collapsible>
                                        </div>
                                }
                                <br />
                            </div>
                        }

                        {
                            (this.state.gettingMusic || this.state.gettingArtists || this.state.recommendations.music.length || this.state.recommendations.artists.length || this.state.musicError || this.state.artistsError) ?

                                <div className={`row justify-content-center text-monospace ${!this.state.changingCriteria ? 'fade-in-delayed-1' : 'fade-out'}`}>
                                    <div className="table-responsive fake-table">
                                        <div className="table max-width-100">
                                            <div className="justify-content-center">
                                                <Accordion defaultActiveKey="0">

                                                    {
                                                        <MusicTable
                                                            retrieving={this.state.gettingMusic}
                                                            error={this.state.musicError}
                                                            musicPlaylistSaveError={this.state.musicPlaylistSaveError}
                                                            openSpotify={this.openSpotify}
                                                            music={this.state.recommendations.music}
                                                            refresh={this.getMusicRecommendations}
                                                            loggedIn={this.state.access_token && this.state.access_token !== "undefined" && this.state.access_token !== ""}
                                                            userPlaylists={this.state.userPlaylists}
                                                            playlistSongs={this.state.playlistSongs.music}
                                                            addToPlaylist={this.addToMusicPlaylist}
                                                            removeFromPlaylist={this.removeFromMusicPlaylist}
                                                            addAllToPlaylist={this.addAllToMusicPlaylist}
                                                            removeAllFromPlaylist={this.removeAllFromMusicPlaylist}
                                                            haveAddedAllToPlaylist={this.haveAddedAllSongsToMusicPlaylist()}
                                                            savePlaylist={this.saveMusicPlaylist}
                                                            playlistId={this.state.musicPlaylistId}
                                                            selectPlaylist={this.selectMusicPlaylist}
                                                            savedSongs={this.state.savedPlaylistSongs.music}
                                                            savingPlaylist={this.state.savingMusicPlaylist}
                                                            modalArtist={this.state.fullArtists.find(it => it.show) ? this.state.fullArtists.find(it => it.show) : null}
                                                            showFullArtist={this.showFullArtist}
                                                            handleCloseAboutArtist={this.handleCloseAboutArtist}
                                                            followArtist={this.followArtist}
                                                            unfollowArtist={this.unfollowArtist}
                                                            userFollows={this.state.userFollows}
                                                            iOS={this.props.iOS}
                                                        />
                                                    }

                                                    {
                                                        <RelatedArtistsTable
                                                            retrieving={this.state.gettingArtists}
                                                            error={this.state.artistsError}
                                                            artistPlaylistSaveError={this.state.artistPlaylistSaveError}
                                                            openSpotify={this.openSpotify}
                                                            music={this.state.recommendations.artists}
                                                            refresh={this.getArtistRecommendations}
                                                            loggedIn={this.state.access_token && this.state.access_token !== "undefined" && this.state.access_token !== ""}
                                                            userPlaylists={this.state.userPlaylists}
                                                            playlistSongs={this.state.playlistSongs.artists}
                                                            addToPlaylist={this.addToArtistPlaylist}
                                                            removeFromPlaylist={this.removeFromArtistPlaylist}
                                                            addAllToPlaylist={this.addAllToArtistPlaylist}
                                                            removeAllFromPlaylist={this.removeAllFromArtistPlaylist}
                                                            haveAddedAllToPlaylist={this.haveAddedAllSongsToArtistPlaylist}
                                                            savePlaylist={this.saveArtistPlaylist}
                                                            playlistId={this.state.artistPlaylistId}
                                                            selectPlaylist={this.selectArtistPlaylist}
                                                            savedSongs={this.state.savedPlaylistSongs.artists}
                                                            savingPlaylist={this.state.savingArtistPlaylist}
                                                            modalArtist={this.state.fullArtists.find(it => it.show) ? this.state.fullArtists.find(it => it.show) : null}
                                                            showFullArtist={this.showFullArtist}
                                                            handleCloseAboutArtist={this.handleCloseAboutArtist}
                                                            followArtist={this.followArtist}
                                                            unfollowArtist={this.unfollowArtist}
                                                            userFollows={this.state.userFollows}
                                                            iOS={this.props.iOS}
                                                        />
                                                    }
                                                </Accordion>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                        }
                    </div>
                    <br />
                    <br />
                </div >
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    draggable
                />
            </React.Fragment >
        );
    }
}

export default connect(
    (state) => {
        const { music } = state;
        return {
            music
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, musicActionCreators), dispatch)
        }
    }
)(SlowMusic)