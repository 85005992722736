import React, { Component } from 'react';
import { CSSTransition } from 'react-transition-group';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { TiNotesOutline } from "react-icons/ti";
import { BiCameraMovie, BiRun } from "react-icons/bi";
import { FaPodcast } from "react-icons/fa";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        if (this.props.title) { document.title = this.props.title }
    }

    render() {
        return (
            <div className="fade-in">
                <CSSTransition
                    in={!this.state.loading}
                    timeout={300}
                    classNames="fade"
                    unmountOnExit
                >
                    <div id="home" className="hide-overflow">
                        <div className="text-center">
                            <div className="welcome"><h1 className="display-5">Welcome to </h1><h1 className="app-name">RecommendMe!</h1></div>
                            <h5 id="allow-us">Allow us to provide you with recommendations for any of the following:</h5>
                        </div>

                        <Carousel autoPlay={true} interval={9250} infiniteLoop={true} showStatus={false} stopOnHover={true} showThumbs={false}>
                            <div>
                                <a role="button" href="#" onClick={() => this.props.handleNav("/music")}>
                                    <TiNotesOutline size={250} />
                                    <br />
                                    <span><h2>Music</h2></span>
                                    <span><h6 className="text-muted text-monospace">All we need is your mood and favorite genres/artists, and in return, we'll give you great music curated just for you! You can even connect us to your Spotify to create and/or add to your existing playlists!</h6></span>
                                </a>
                                <br />
                            </div>
                            <div>
                                <a role="button" href="#" onClick={() => this.props.handleNav("/television")}>
                                    <BiCameraMovie size={250} />
                                    <br />
                                    <span><h2>Movies & TV Shows</h2></span>
                                    <span><h6 className="text-muted text-monospace">We receive: your mood and television favorites...<br />You receive: recommendations for both movies and TV shows!</h6></span>
                                </a>
                                <br />
                            </div>
                            <div>
                                <br />
                                <a role="button" href="#" onClick={() => this.props.handleNav("/podcasts")}>
                                    <FaPodcast size={200} />
                                    <br />
                                    <br />
                                    <span><h2>Podcasts</h2></span>
                                    <span><h6 className="text-muted text-monospace">Using just a few of your favorite podcasts and genres, we can expand your library with loads of new recommendations!</h6></span>
                                </a>
                                <br />
                            </div>
                        </Carousel>
                        {/*                        <div>
                            <Link to="/activities">
                                <BiRun size={250} />
                                <br />
                                <span><h3>Activities</h3></span>
                            </Link>
                            <br />
                        </div>
*/}
                    </div>
                </CSSTransition >
                <div className="home-footer"><h4>Created by <a href="https://cameronjones.tech" target="_blank" rel="noopener noreferrer">Cam Jones</a></h4></div>
            </div>
        );
    }
}

export default connect(
    (state) => {
        const { movie } = state;
        return {
            movie
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}), dispatch)
        }
    }
)(Home)