import { publicRequest } from '../services/request.service'

const API_URL = window.env ? window.env.API_SERVER : '';
const NO_CONTENT = 2;
const REQUEST_FAILURE = 1;
const REQUEST_SUCCESS = 0;

const getGenresSuccess = "GET_GENRES_SUCCESS";
const getGenresFailure = "GET_GENRES_FAILURE";

const searchArtistsSuccess = "SEARCH_ARTISTS_SUCCESS";
const searchArtistsFailure = "SEARCH_ARTISTS_FAILURE";

const musicRecommendationsSuccess = "MUSIC_RECOMMENDATIONS_SUCCESS";
const musicRecommendationsFailure = "MUSIC_RECOMMENDATIONS_FAILURE";

const relatedArtistsSuccess = "RELATED_ARTISTS_SUCCESS";
const relatedArtistsFailure = "RELATED_ARTISTS_FAILURE";

const artistSuccess = "ARTIST_SUCCESS";
const artistFailure = "ARTIST_FAILURE";

const artistsSuccess = "ARTISTS_SUCCESS";
const artistsFailure = "ARTISTS_FAILURE";

const spotifyLoginSuccess = "SPOTIFY_LOGIN_SUCCESS";
const spotifyLoginFailure = "SPOTIFY_LOGIN_FAILURE";

const createPlaylistSuccess = "CREATE_PLAYLIST_SUCCESS";
const createPlaylistFailure = "CREATE_PLAYLIST_FAILURE";

const savePlaylistSuccess = "SAVE_PLAYLIST_SUCCESS";
const savePlaylistFailure = "SAVE_PLAYLIST_FAILURE";

const userPlaylistsSuccess = "GET_USER_PLAYLISTS_SUCCESS";
const userPlaylistsFailure = "GET_USER_PLAYLISTS_FAILURE";

const userFollowsSuccess = "GET_USER_FOLLOWS_SUCCESS";
const userFollowsFailure = "GET_USER_FOLLOWS_FAILURE";

const playlistSuccess = "PLAYLIST_SUCCESS";
const playlistFailure = "PLAYLIST_FAILURE";

const chartsSuccess = "CHARTS_SUCCESS";
const chartsFailure = "CHARTS_FAILURE";

const featuredPlaylistsSuccess = "FEATURED_PLAYLISTS_SUCCESS";
const featuredPlaylistsFailure = "FEATURED_PLAYLISTS_FAILURE";

const initialState = {
    genres: [],
    getGenresError: '',
    artists: [],
    searchArtistsError: '',
    musicRecommendations: [],
    musicRecommendationsError: '',
    relatedArtists: [],
    relatedArtistsError: '',
    artist: [],
    artistError: '',
    artists: [],
    artistsError: '',
    spotifyAccessToken: '',
    spotifyRefreshToken: '',
    spotifyLoginError: '',
    createPlaylist: {},
    createPlaylistError: '',
    savePlaylist: {},
    savePlaylistError: '',
    userPlaylists: [],
    userPlaylistsError: '',
    userFollows: [],
    userFollowsError: '',
    playlistTracks: [],
    playlistTracksError: '',
    chats: [],
    chartsError: '',
    featuredPlaylists: [],
    featuredPlaylistsError: ''
};

export const musicActionCreators = {

    receiveGetGenres: resObj => ({
        type: getGenresSuccess,
        genres: resObj
    }),

    getGenresError: (message) => ({
        type: getGenresFailure,
        getGenresError: message
    }),

    getGenres: () => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_MUSIC_API_URL) + '/Music/api/GetGenres', 'GET', headers, '', '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(musicActionCreators.getGenresError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(musicActionCreators.receiveGetGenres(result[1]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(musicActionCreators.receiveGetGenres([]))
                        return Promise.reject('No genres found');
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveSearchArtists: resObj => ({
        type: searchArtistsSuccess,
        artists: resObj
    }),

    searchArtistsError: (message) => ({
        type: searchArtistsFailure,
        searchArtistsError: message
    }),

    searchArtists: (params) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_MUSIC_API_URL) + '/Music/api/SearchArtists', 'GET', headers, '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(musicActionCreators.searchArtistsError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(musicActionCreators.receiveSearchArtists(result[1]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(musicActionCreators.receiveSearchArtists([]))
                        return Promise.reject('No artists found');
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveMusicRecommendations: resObj => ({
        type: musicRecommendationsSuccess,
        musicRecommendations: resObj
    }),

    musicRecommendationsError: (message) => ({
        type: musicRecommendationsFailure,
        musicRecommendationsError: message
    }),

    getMusicRecommendations: (body) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_MUSIC_API_URL) + '/Music/api/GetMusicRecommendations', 'POST', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(musicActionCreators.musicRecommendationsError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(musicActionCreators.receiveMusicRecommendations(result[1].MusicRecommendations))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(musicActionCreators.musicRecommendationsError('No recommendations found'))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveRelatedArtists: resObj => ({
        type: relatedArtistsSuccess,
        relatedArtists: resObj
    }),

    relatedArtistsError: (message) => ({
        type: relatedArtistsFailure,
        relatedArtistsError: message
    }),

    getRelatedArtists: (body, searchLessPopularArtists) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_MUSIC_API_URL) + '/Music/api/GetRelatedArtists', 'POST', headers, body, `?getUnpopularArtists=${searchLessPopularArtists}`)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(musicActionCreators.relatedArtistsError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(musicActionCreators.receiveRelatedArtists(result[1].Artists))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(musicActionCreators.relatedArtistsError('No related artists found'))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveArtist: resObj => ({
        type: artistSuccess,
        artist: resObj
    }),

    artistError: (message) => ({
        type: artistFailure,
        artistError: message
    }),

    getArtist: (params) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_MUSIC_API_URL) + '/Music/api/GetArtist', 'GET', headers, null, params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(musicActionCreators.artistError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(musicActionCreators.receiveArtist(result[1]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(musicActionCreators.artistError('No tracks found'))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveArtists: resObj => ({
        type: artistsSuccess,
        artists: resObj
    }),

    artistsError: (message) => ({
        type: artistsFailure,
        artistsError: message
    }),

    getArtists: (body) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_MUSIC_API_URL) + '/Music/api/GetArtists', 'POST', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(musicActionCreators.artistsError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(musicActionCreators.receiveArtists(result[1]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(musicActionCreators.artistsError('No tracks found'))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveSpotifyLogin: resObj => ({
        type: spotifyLoginSuccess,
        spotifyAccessToken: resObj.AccessToken,
        spotifyRefreshToken: resObj.RefreshToken
    }),

    spotifyLoginError: (message) => ({
        type: spotifyLoginFailure,
        spotifyLoginError: message
    }),

    loginToSpotify: (body) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_MUSIC_API_URL) + '/Music/api/LoginToSpotify', 'POST', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(musicActionCreators.spotifyLoginError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(musicActionCreators.receiveSpotifyLogin(result[1]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(musicActionCreators.spotifyLoginError('No content'))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    refreshAccessToken: (body) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_MUSIC_API_URL) + '/Music/api/RefreshSpotifyLogin', 'POST', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(musicActionCreators.spotifyLoginError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(musicActionCreators.receiveSpotifyLogin(result[1]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(musicActionCreators.spotifyLoginError('No content'))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveCreatePlaylist: resObj => ({
        type: createPlaylistSuccess,
        createPlaylist: resObj
    }),

    createPlaylistError: (message) => ({
        type: createPlaylistFailure,
        createPlaylistError: message
    }),

    createPlaylist: (params) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            headers.access_token = localStorage.getItem("access_token");
            return publicRequest((API_URL || process.env.REACT_APP_MUSIC_API_URL) + '/Music/api/CreatePlaylist', 'POST', headers, null, params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(musicActionCreators.createPlaylistError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(musicActionCreators.receiveCreatePlaylist(result[1]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(musicActionCreators.createPlaylistError('No content'))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveSavePlaylist: resObj => ({
        type: savePlaylistSuccess,
        savePlaylist: resObj
    }),

    savePlaylistError: (message) => ({
        type: savePlaylistFailure,
        savePlaylistError: message
    }),

    savePlaylist: (body) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8",
            }
            headers.access_token = localStorage.getItem("access_token");
            return publicRequest((API_URL || process.env.REACT_APP_MUSIC_API_URL) + '/Music/api/AddSongsToPlaylist', 'POST', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(musicActionCreators.savePlaylistError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(musicActionCreators.receiveSavePlaylist(result[1]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(musicActionCreators.savePlaylistError('No content'))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveUserPlaylists: resObj => ({
        type: userPlaylistsSuccess,
        userPlaylists: resObj
    }),

    userPlaylistsError: (message) => ({
        type: userPlaylistsFailure,
        userPlaylistsError: message
    }),

    getUserPlaylists: () => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            headers.access_token = localStorage.getItem("access_token");
            return publicRequest((API_URL || process.env.REACT_APP_MUSIC_API_URL) + '/Music/api/GetUserPlaylists', 'GET', headers, null, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(musicActionCreators.userPlaylistsError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(musicActionCreators.receiveUserPlaylists(result[1]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(musicActionCreators.userPlaylistsError('No content'))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveUserFollows: resObj => ({
        type: userFollowsSuccess,
        userFollows: resObj
    }),

    userFollowsError: (message) => ({
        type: userFollowsFailure,
        userFollowsError: message
    }),

    getUserFollows: () => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            headers.access_token = localStorage.getItem("access_token");
            return publicRequest((API_URL || process.env.REACT_APP_MUSIC_API_URL) + '/Music/api/GetUserFollows', 'GET', headers, null, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(musicActionCreators.userFollowsError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(musicActionCreators.receiveUserFollows(result[1]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(musicActionCreators.userFollowsError('No content'))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    followArtist: (id) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            headers.access_token = localStorage.getItem("access_token");
            return publicRequest((API_URL || process.env.REACT_APP_MUSIC_API_URL) + '/Music/api/FollowArtist', 'POST', headers, null, `/${id}`)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                    } else if (result[0] === NO_CONTENT) {
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    unfollowArtist: (id) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            headers.access_token = localStorage.getItem("access_token");
            return publicRequest((API_URL || process.env.REACT_APP_MUSIC_API_URL) + '/Music/api/UnfollowArtist', 'POST', headers, null, `/${id}`)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                    } else if (result[0] === NO_CONTENT) {
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receivePlaylist: resObj => ({
        type: playlistSuccess,
        playlistTracks: resObj
    }),

    playlistError: (message) => ({
        type: playlistFailure,
        playlistTracksError: message
    }),

    getPlaylist: (id) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            headers.access_token = localStorage.getItem("access_token");
            return publicRequest((API_URL || process.env.REACT_APP_MUSIC_API_URL) + '/Music/api/GetPlaylist/' + id, 'GET', headers, null, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(musicActionCreators.playlistError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(musicActionCreators.receivePlaylist(result[1]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(musicActionCreators.playlistError('No content'))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveCharts: resObj => ({
        type: chartsSuccess,
        charts: resObj
    }),

    chartsError: (message) => ({
        type: chartsFailure,
        chartsError: message
    }),

    getCharts: () => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_MUSIC_API_URL) + '/Music/api/GetCharts', 'GET', headers, null, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(musicActionCreators.chartsError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(musicActionCreators.receiveCharts(result[1]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(musicActionCreators.chartsError('No content'))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveFeaturedPlaylists: resObj => ({
        type: featuredPlaylistsSuccess,
        featuredPlaylists: resObj
    }),

    featuredPlaylistsError: (message) => ({
        type: featuredPlaylistsFailure,
        featuredPlaylistsError: message
    }),

    getFeaturedPlaylists: () => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_MUSIC_API_URL) + '/Music/api/GetFeaturedPlaylists', 'GET', headers, null, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(musicActionCreators.featuredPlaylistsError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(musicActionCreators.receiveFeaturedPlaylists(result[1]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(musicActionCreators.featuredPlaylistsError('No content'))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    }
};


export const reducer = (state, action) => {

    state = state || initialState;
    switch (action.type) {
        case getGenresSuccess:
            return Object.assign({}, state, {
                genres: action.genres,
                getGenresError: action.getGenresError
            })
        case getGenresFailure:
            return Object.assign({}, state, {
                getGenresError: action.getGenresError
            })
        case searchArtistsSuccess:
            return Object.assign({}, state, {
                artists: action.artists,
                searchArtistsError: action.searchArtistsError
            })
        case searchArtistsFailure:
            return Object.assign({}, state, {
                searchArtistsError: action.searchArtistsError
            })
        case musicRecommendationsSuccess:
            return Object.assign({}, state, {
                musicRecommendations: action.musicRecommendations,
                musicRecommendationsError: action.musicRecommendationsError
            })
        case musicRecommendationsFailure:
            return Object.assign({}, state, {
                musicRecommendationsError: action.musicRecommendationsError
            })
        case relatedArtistsSuccess:
            return Object.assign({}, state, {
                relatedArtists: action.relatedArtists,
                relatedArtistsError: action.relatedArtistsError
            })
        case relatedArtistsFailure:
            return Object.assign({}, state, {
                relatedArtistsError: action.relatedArtistsError
            })
        case artistSuccess:
            return Object.assign({}, state, {
                artist: action.artist,
                artistError: action.artistError
            })
        case artistFailure:
            return Object.assign({}, state, {
                artistError: action.artistError
            })
        case artistsSuccess:
            return Object.assign({}, state, {
                artists: action.artists,
                artistsError: action.artistsError
            })
        case artistsFailure:
            return Object.assign({}, state, {
                artistsError: action.artistsError
            })
        case spotifyLoginSuccess:
            return Object.assign({}, state, {
                spotifyAccessToken: action.spotifyAccessToken,
                spotifyRefreshToken: action.spotifyRefreshToken,
                spotifyLoginError: action.spotifyLoginError
            })
        case spotifyLoginFailure:
            return Object.assign({}, state, {
                spotifyLoginError: action.spotifyLoginError
            })
        case createPlaylistSuccess:
            return Object.assign({}, state, {
                createPlaylist: action.createPlaylist,
                createPlaylistError: action.createPlaylistError
            })
        case createPlaylistFailure:
            return Object.assign({}, state, {
                createPlaylistError: action.createPlaylistError
            })
        case savePlaylistSuccess:
            return Object.assign({}, state, {
                savePlaylist: action.savePlaylist,
                savePlaylistError: action.savePlaylistError
            })
        case savePlaylistFailure:
            return Object.assign({}, state, {
                savePlaylistError: action.savePlaylistError
            })
        case userPlaylistsSuccess:
            return Object.assign({}, state, {
                userPlaylists: action.userPlaylists,
                userPlaylistsError: action.userPlaylistsError
            })
        case userPlaylistsFailure:
            return Object.assign({}, state, {
                userPlaylistsError: action.userPlaylistsError
            })
        case userFollowsSuccess:
            return Object.assign({}, state, {
                userFollows: action.userFollows,
                userFollowsError: action.userFollowsError
            })
        case userFollowsFailure:
            return Object.assign({}, state, {
                userFollowsError: action.userFollowsError
            })
        case playlistSuccess:
            return Object.assign({}, state, {
                playlistTracks: action.playlistTracks,
                playlistTracksError: action.playlistTracksError
            })
        case playlistFailure:
            return Object.assign({}, state, {
                playlistTracksError: action.playlistTracksError
            })
        case chartsSuccess:
            return Object.assign({}, state, {
                charts: action.charts,
                chartsError: action.chartsError
            })
        case chartsFailure:
            return Object.assign({}, state, {
                chartsError: action.chartsError
            })
        case featuredPlaylistsSuccess:
            return Object.assign({}, state, {
                featuredPlaylists: action.featuredPlaylists,
                featuredPlaylistsError: action.featuredPlaylistsError
            })
        case featuredPlaylistsFailure:
            return Object.assign({}, state, {
                featuredPlaylistsError: action.featuredPlaylistsError
            })
        default:
            return state
    }
};