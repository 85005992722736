import { Accordion, Card } from 'react-bootstrap';
import React from 'react';
import { TiRefresh } from 'react-icons/ti';
import { Spinner } from 'react-bootstrap';
import AboutArtistModal from './AboutArtistModal';

class MusicTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    getChunkedRecommendations(recs) {
        const groupedRecs = recs
            .reduce((groups, curr) => {
                const arr = groups[groups.length - 1];
                arr.push(curr);
                if (arr.length === 3) groups.push([]);
                return groups;
            }, [[]])
            .filter((chunk) => chunk.length);

        return groupedRecs
    }

    render() {
        const { modalArtist } = this.props;

        return (
            <Card style={{ overflowX: 'auto' }}>
                {
                    modalArtist &&
                    <AboutArtistModal {...this.props} modalArtist={modalArtist} iOS={this.props.iOS} />
                }
                <Accordion.Toggle as={Card.Header} variant="link" eventKey="0" className="bg-dark text-center">
                    <button type="button" className="btn btn-link text-light">Recommended Songs</button>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                    <React.Fragment>
                        {
                            this.props.retrieving ?
                                <div className="spinner-collapse text-center">
                                    <Spinner animation="border" variant="primary" />
                                </div>
                                :
                                <div className="bg-light">
                                    <span className="d-block" style={{ zIndex: '2' }}><TiRefresh size={35} onClick={this.props.refresh} className="refresh-icon text-primary" /></span>
                                    <Card.Body className="text-center align-middle py-1">
                                        {this.props.error ?
                                            <p className="card-text m-auto error-message">{this.props.error}</p>
                                            :
                                            <React.Fragment>
                                                {
                                                    !this.props.loggedIn ?
                                                        <React.Fragment>
                                                            <div>
                                                                <button className="btn spotify-background-color text-light text-capitalize font-sm mb-3" type="button" onClick={this.props.openSpotify} style={{ maxWidth: "300px" }}>
                                                                    <span className="fa fa-spotify mr-2 spotify-icon" />
                                                                    Login to Spotify to add to a playlist
                                                                </button>
                                                            </div>
                                                        </React.Fragment>
                                                        :
                                                        <React.Fragment>
                                                            <div className="row mx-auto mb-3">
                                                                <div className="col-10 text-right border-right pr-3">
                                                                    <strong>Playlist:&nbsp;</strong>
                                                                    <select className="rounded-pill text-center max-width-100" onChange={(e) => this.props.selectPlaylist(e.target.value)} value={this.props.playlistId}>
                                                                        <option value="">--Select--</option>
                                                                        {this.props.userPlaylists.map((option, i) =>
                                                                            <option key={i} value={option.Id}>{option.Name}</option>
                                                                        )}
                                                                    </select>
                                                                    <br />
                                                                    <button disabled={!this.props.playlistId || this.props.savingPlaylist || this.props.playlistSongs.length === 0} type="button" className="btn btn-block btn-sm btn-primary mt-2" onClick={this.props.savePlaylist}>Save</button>
                                                                    {
                                                                        this.props.musicPlaylistSaveError &&
                                                                        <p className="text-center text-danger font-weight-bold mb-0">{this.props.musicPlaylistSaveError}</p>
                                                                    }
                                                                </div>
                                                                <div className="col-2 text-center m-auto p-0">
                                                                    {
                                                                        this.props.music.filter(item => this.props.savedSongs.find(savedSongUri => savedSongUri === item.Uri)).length !== this.props.music.length &&
                                                                        <button type="button"
                                                                            disabled={!this.props.playlistId || this.props.savingPlaylist}
                                                                            className="btn p-0"
                                                                            style={{ fontSize: "37.5px", lineHeight: "20px" }}
                                                                            onClick={this.props.haveAddedAllToPlaylist ? this.props.removeAllFromPlaylist : this.props.addAllToPlaylist}>
                                                                            <span className={`fa ${this.props.haveAddedAllToPlaylist ? 'fa-minus-circle text-danger' : 'fa-plus-circle spotify-text-color'}`} />
                                                                            <br />
                                                                            <span style={{ fontSize: "15px" }} className='text-muted'>(All)</span>
                                                                        </button>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                }
                                                {this.getChunkedRecommendations(this.props.music.sort((a, b) => a.Sequence - b.Sequence).slice(0, 9)).map((group, n) =>
                                                    <div key={n} className={`row row-striped m-auto${n === 0 ? " no-border" : ""}`} >
                                                        {group.map((option, i) =>
                                                            <div key={i} className="col-sm-4 px-1 row-striped bordered" >
                                                                <div className="row m-auto">
                                                                    <div className={`m-auto ${this.props.loggedIn ? "col-10 col-md-12 pr-3 border-right" : ""}`}>
                                                                        {
                                                                            option.ExternalUrls && option.ExternalUrls.spotify ?
                                                                                <iframe className="card-img-top" src={option.ExternalUrls.spotify.replace("/track", "/embed/track")} width="300" height="80" frameBorder="0" allow="encrypted-media"></iframe>
                                                                                :
                                                                                <small className="text-muted">Preview Unavailable ({option.Name})</small>
                                                                        }
                                                                        <br />
                                                                        <button className="btn btn-secondary btn-sm btn-block" type="button" onClick={() => this.props.showFullArtist(option.Artists[0].Id)}>More from {option.Artists[0].Name}</button>
                                                                    </div>
                                                                    {
                                                                        this.props.loggedIn &&
                                                                        <div className="col-2 col-md-6 m-auto p-0">
                                                                            {
                                                                                this.props.savedSongs.find(songUri => songUri === option.Uri) ?
                                                                                    <strong>SAVED</strong>
                                                                                    :
                                                                                    <button type="button"
                                                                                        disabled={!this.props.playlistId || this.props.savingPlaylist}
                                                                                        className={`btn p-0 fa ${this.props.playlistSongs.find(songUri => songUri === option.Uri) ? 'fa-minus-circle text-danger' : 'fa-plus-circle spotify-text-color'}`}
                                                                                        style={{ fontSize: "25px" }}
                                                                                        onClick={() => this.props.playlistSongs.find(songUri => songUri === option.Uri) ? this.props.removeFromPlaylist(option.Uri) : this.props.addToPlaylist(option.Uri)}
                                                                                    />
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </React.Fragment>
                                        }
                                    </Card.Body>
                                </div>
                        }
                    </React.Fragment>
                </Accordion.Collapse>
            </Card >
        )
    }
}
export default MusicTable;