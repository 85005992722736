import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { NavLink as RRNavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './NavMenu.css';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    toggleNavbar(component) {
        this.setState({
            collapsed: window.innerWidth < 576 ? !this.state.collapsed : this.state.collapsed
        }, () => {
            if (component) {
                this.props.handleNav(component);
            }
        });
    }

    render() {
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" dark>
                    <Container>
                        <NavbarBrand tag={Link} to="/">RecommendMe</NavbarBrand>
                        <NavbarToggler onClick={() => this.toggleNavbar()} className="mr-2" />
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                            <ul className="navbar-nav flex-grow">
                                <NavItem>
                                    <button type="button" onClick={() => this.toggleNavbar('/')} aria-current={'/' === window.location.pathname ? 'page' : null} className={`nav-link text-light${'/' === window.location.pathname ? ' active' : ''}`}>Home</button>
                                </NavItem>
                                <NavItem>
                                    <button type="button" onClick={() => this.toggleNavbar('/music')} aria-current={'/music' === window.location.pathname ? 'page' : null} className={`nav-link text-light${'/music' === window.location.pathname ? ' active' : ''}`}>Music</button>
                                </NavItem>
                                <NavItem>
                                    <button type="button" onClick={() => this.toggleNavbar('/television')} aria-current={'/television' === window.location.pathname ? 'page' : null} className={`nav-link text-light${'/television' === window.location.pathname ? ' active' : ''}`}>Television</button>
                                </NavItem>
                                <NavItem>
                                    <button type="button" onClick={() => this.toggleNavbar('/podcasts')} aria-current={'/podcasts' === window.location.pathname ? 'page' : null} className={`nav-link text-light${'/podcasts' === window.location.pathname ? ' active' : ''}`}>Podcasts</button>
                                </NavItem>
                                {/*                                <NavItem>
                                    <NavLink onClick={this.toggleNavbar} tag={RRNavLink} activeClassName="active" className="text-light" to="/activities">Activities</NavLink>
                                </NavItem>
*/}                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }
}
