import { Accordion, Card, DropdownButton, Dropdown } from 'react-bootstrap';
import React from 'react';
import { TiRefresh } from 'react-icons/ti';
import { Spinner } from 'react-bootstrap';

class RelatedArtistsTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showPlaylistData: []
        };
    }

    getChunkedRecommendations(recs) {
        const groupedRecs = recs
            .reduce((groups, curr) => {
                const arr = groups[groups.length - 1];
                arr.push(curr);
                if (arr.length === 3) groups.push([]);
                return groups;
            }, [[]])
            .filter((chunk) => chunk.length);

        return groupedRecs
    }

    togglePlaylistData(i) {
        let playlistData = this.state.showPlaylistData
        playlistData[i] = !playlistData[i]
        this.setState({ playlistData })
    }

    getTrackNameText(name) {
        if (name.includes("(feat")) { name = name.split("(feat")[0] }
        if (name.includes("(ft")) { name = name.split("(ft")[0] }
        if (name.includes("(with ")) { name = name.split("(with ")[0] }

        return name
    }

    render() {
        return (
            <Card style={{ overflowX: 'auto' }}>
                <Accordion.Toggle as={Card.Header} variant="link" eventKey="1" className="bg-dark text-center">
                    <button type="button" className="btn btn-link text-light">Recommended Artists</button>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                    <React.Fragment>
                        {
                            this.props.retrieving ?
                                <div className="spinner-collapse text-center">
                                    <Spinner animation="border" variant="primary" />
                                </div>
                                :
                                <div className="bg-light">
                                    <span className="d-block" style={{ zIndex: '2' }}><TiRefresh size={35} onClick={this.props.refresh} className="refresh-icon text-primary" /></span>
                                    <Card.Body className="text-center align-middle py-1">
                                        {
                                            this.props.error ?
                                                <p className="card-text m-auto error-message">{this.props.error}</p>
                                                :
                                                <React.Fragment>
                                                    {
                                                        !this.props.loggedIn ?
                                                            <React.Fragment>
                                                                <div>
                                                                    <button className="btn spotify-background-color text-light text-capitalize font-sm mb-3" type="button" onClick={this.props.openSpotify} style={{ maxWidth: "300px" }}>
                                                                        <span className="fa fa-spotify mr-2 spotify-icon" />
                                                                        Login to Spotify to add to a playlist
                                                                    </button>
                                                                </div>
                                                            </React.Fragment>
                                                            :
                                                            <React.Fragment>
                                                                <div className="col-12 mb-3 text-center">
                                                                    <strong>Playlist:&nbsp;</strong>
                                                                    <select className="rounded-pill text-center max-width-100" onChange={(e) => this.props.selectPlaylist(e.target.value)} value={this.props.playlistId}>
                                                                        <option value="">--Select--</option>
                                                                        {this.props.userPlaylists.map((option, i) =>
                                                                            <option key={i} value={option.Id}>{option.Name}</option>
                                                                        )}
                                                                    </select>
                                                                    <br />
                                                                    <button disabled={!this.props.playlistId || this.props.savingPlaylist || this.props.playlistSongs.length === 0} type="button" className="btn btn-block btn-sm btn-primary mt-2" onClick={this.props.savePlaylist}>Save</button>
                                                                    {
                                                                        this.props.artistPlaylistSaveError &&
                                                                        <p className="text-center text-danger font-weight-bold mb-0">{this.props.artistPlaylistSaveError}</p>
                                                                    }
                                                                </div>
                                                            </React.Fragment>
                                                    }
                                                    {
                                                        this.getChunkedRecommendations(this.props.music.sort((a, b) => a.Sequence - b.Sequence).slice(0, 9)).map((group, n) =>
                                                            <div key={n} className={`row row-striped m-auto${n === 0 ? " no-border" : ""}`} >
                                                                {group.map((option, i) =>
                                                                    <div key={i} className="col-sm-4 px-1 row-striped bordered" >
                                                                        <div className="row m-auto">
                                                                            <div className={`col-12 m-auto`}>
                                                                                <a className="btn spotify-background-color artist-name" href={this.props.iOS ? option.Uri : `https://open.spotify.com/artist/${option.Id}`} target="_blank" rel="noopener noreferrer">
                                                                                    <span className="fa fa-spotify mr-2 spotify-icon" />
                                                                                    {`${option.Name}`}
                                                                                </a>
                                                                                {
                                                                                    option.ExternalUrls && option.ExternalUrls.spotify &&
                                                                                    <React.Fragment>
                                                                                        <br />
                                                                                        <br />
                                                                                        <iframe className='card-img-top' src={option.ExternalUrls.spotify.replace("/artist", "/embed/artist")} width="300" height="200" frameBorder="0" allow="encrypted-media"></iframe>
                                                                                        <br />
                                                                                    </React.Fragment>
                                                                                }
                                                                                {
                                                                                    this.props.loggedIn &&
                                                                                    <React.Fragment>
                                                                                        <DropdownButton
                                                                                            disabled={!this.props.playlistId || this.props.savingPlaylist}
                                                                                            title={`Add ${option.Name} Songs to Playlist`}
                                                                                            id="dropdown-menu-align-right"
                                                                                        >
                                                                                            {option.TopTracks.Tracks.filter(item => this.props.savedSongs.find(savedSongUri => savedSongUri === item.Uri)).length !== option.TopTracks.Tracks.length &&
                                                                                                <React.Fragment>
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        disabled={!this.props.playlistId || this.props.savingPlaylist}
                                                                                                        className={`dropdown-item text-light ${this.props.haveAddedAllToPlaylist(i) ? "bg-danger" : "bg-info"}`}
                                                                                                        onClick={() => this.props.haveAddedAllToPlaylist(i) ? this.props.removeAllFromPlaylist(i) : this.props.addAllToPlaylist(i)}
                                                                                                    >{this.props.haveAddedAllToPlaylist(i) ? "Remove All" : "Add All"}</button>
                                                                                                    <Dropdown.Divider />
                                                                                                </React.Fragment>
                                                                                            }
                                                                                            {option.TopTracks.Tracks.map((track, n) =>
                                                                                                <button
                                                                                                    key={n}
                                                                                                    type="button"
                                                                                                    disabled={this.props.savedSongs.find(songUri => songUri === track.Uri)}
                                                                                                    className={`dropdown-item ${this.props.savedSongs.find(songUri => songUri === track.Uri) ? "" : (this.props.playlistSongs.find(songUri => songUri === track.Uri) ? "spotify-background-color text-light font-weight-bold" : "text-dark font-weight-bold")}`}
                                                                                                    onClick={() => this.props.playlistSongs.find(songUri => songUri === track.Uri) ? this.props.removeFromPlaylist(track.Uri) : this.props.addToPlaylist(track.Uri)}
                                                                                                >{this.getTrackNameText(track.Name)}{this.props.savedSongs.find(songUri => songUri === track.Uri) ? " (saved)" : ""}</button>
                                                                                            )}
                                                                                        </DropdownButton>
                                                                                        <br />
                                                                                    </React.Fragment>
                                                                                }
                                                                                <button className="btn btn-secondary btn-sm btn-block" type="button" onClick={() => this.props.showFullArtist(option.Id)}>More from {option.Name}</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )}
                                                </React.Fragment>
                                        }
                                    </Card.Body>
                                </div>
                        }
                    </React.Fragment>
                </Accordion.Collapse >
            </Card >
        )
    }
}
export default RelatedArtistsTable;