import { publicRequest } from '../services/request.service'

const API_URL = window.env ? window.env.API_SERVER : '';
const NO_CONTENT = 2;
const REQUEST_FAILURE = 1;
const REQUEST_SUCCESS = 0;

const searchActorsSuccess = "SEARCH_ACTORS_SUCCESS";
const searchActorsFailure = "SEARCH_ACTORS_FAILURE";

const searchMoviesSuccess = "SEARCH_MOVIES_SUCCESS";
const searchMoviesFailure = "SEARCH_MOVIES_FAILURE";

const searchShowsSuccess = "SEARCH_SHOWS_SUCCESS";
const searchShowsFailure = "SEARCH_SHOWS_FAILURE";

const getMovieGenresSuccess = "GET_MOVIE_GENRES_SUCCESS";
const getMovieGenresFailure = "GET_MOVIE_GENRES_FAILURE";

const getTvShowGenresSuccess = "GET_TV_SHOW_GENRES_SUCCESS";
const getTvShowGenresFailure = "GET_TV_SHOW_GENRES_FAILURE";

const similarMoviesSuccess = "SIMILAR_MOVIES_SUCCESS";
const similarMoviesFailure = "SIMILAR_MOVIES_FAILURE";

const similarShowsSuccess = "SIMILAR_SHOWS_SUCCESS";
const similarShowsFailure = "SIMILAR_SHOWS_FAILURE";

const moviesByGenreSuccess = "MOVIES_BY_GENRE_SUCCESS";
const moviesByGenreFailure = "MOVIES_BY_GENRE_FAILURE";

const showsByGenreSuccess = "SHOWS_BY_GENRE_SUCCESS";
const showsByGenreFailure = "SHOWS_BY_GENRE_FAILURE";

const moviesByActorSuccess = "MOVIES_BY_ACTOR_SUCCESS";
const moviesByActorFailure = "MOVIES_BY_ACTOR_FAILURE";

const initialState = {
    movies: [],
    searchMoviesError: '',
    shows: [],
    searchShowsError: '',
    movieGenres: [],
    getMovieGenresError: '',
    tvShowGenres: [],
    getTvShowGenresError: '',
    similarMovies: [],
    similarMoviesError: '',
    similarShows: [],
    similarShowsError: '',
    moviesByGenre: [],
    moviesByGenreError: '',
    showsByGenre: [],
    showsByGenreError: '',
    moviesByActor: [],
    moviesByActorError: ''
};

export const televisionActionCreators = {

    receiveSearchMovies: resObj => ({
        type: searchMoviesSuccess,
        movies: resObj
    }),

    searchMoviesError: (message) => ({
        type: searchMoviesFailure,
        searchMoviesError: message
    }),

    searchMovies: (params) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_TELEVISION_API_URL) + '/Television/api/SearchMovies', 'GET', headers, '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(televisionActionCreators.searchMoviesError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(televisionActionCreators.receiveSearchMovies(result[1]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(televisionActionCreators.receiveSearchMovies([]))
                        return Promise.reject('No movies found');
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveSearchShows: resObj => ({
        type: searchShowsSuccess,
        shows: resObj
    }),

    searchShowsError: (message) => ({
        type: searchShowsFailure,
        searchShowsError: message
    }),

    searchShows: (params) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_TELEVISION_API_URL) + '/Television/api/SearchTvShows', 'GET', headers, '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(televisionActionCreators.searchShowsError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(televisionActionCreators.receiveSearchShows(result[1]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(televisionActionCreators.receiveSearchShows([]))
                        return Promise.reject('No shows found');
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveSearchActors: resObj => ({
        type: searchActorsSuccess,
        actors: resObj
    }),

    searchActorsError: (message) => ({
        type: searchActorsFailure,
        searchActorsError: message
    }),

    searchActors: (params) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_TELEVISION_API_URL) + '/Television/api/SearchActors', 'GET', headers, '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(televisionActionCreators.searchActorsError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(televisionActionCreators.receiveSearchActors(result[1]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(televisionActionCreators.receiveSearchActors([]))
                        return Promise.reject('No actors found');
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveGetMovieGenres: resObj => ({
        type: getMovieGenresSuccess,
        movieGenres: resObj
    }),

    getMovieGenresError: (message) => ({
        type: getMovieGenresFailure,
        searchMoviesError: message
    }),

    getMovieGenres: () => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_TELEVISION_API_URL) + '/Television/api/GetMovieGenres', 'GET', headers, '', '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(televisionActionCreators.getMovieGenresError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(televisionActionCreators.receiveGetMovieGenres(result[1]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(televisionActionCreators.receiveGetMovieGenres([]))
                        return Promise.reject('No genres found');
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveGetTvShowGenres: resObj => ({
        type: getTvShowGenresSuccess,
        tvShowGenres: resObj
    }),

    getTvShowGenresError: (message) => ({
        type: getTvShowGenresFailure,
        searchMoviesError: message
    }),

    getTvShowGenres: () => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_TELEVISION_API_URL) + '/Television/api/GetTvShowGenres', 'GET', headers, '', '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(televisionActionCreators.getTvShowGenresError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(televisionActionCreators.receiveGetTvShowGenres(result[1]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(televisionActionCreators.receiveGetTvShowGenres([]))
                        return Promise.reject('No genres found');
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveSimilarMovies: resObj => ({
        type: similarMoviesSuccess,
        similarMovies: resObj
    }),

    similarMoviesError: (message) => ({
        type: similarMoviesFailure,
        similarMoviesError: message
    }),

    getSimilarMovies: (body) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_TELEVISION_API_URL) + '/Television/api/GetSimilarMovies', 'POST', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(televisionActionCreators.similarMoviesError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(televisionActionCreators.receiveSimilarMovies(result[1].MovieRecommendations))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(televisionActionCreators.similarMoviesError("No similar movies found"))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveSimilarShows: resObj => ({
        type: similarShowsSuccess,
        similarShows: resObj
    }),

    similarShowsError: (message) => ({
        type: similarShowsFailure,
        similarShowsError: message
    }),

    getSimilarShows: (body) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_TELEVISION_API_URL) + '/Television/api/GetSimilarTvShows', 'POST', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(televisionActionCreators.similarShowsError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(televisionActionCreators.receiveSimilarShows(result[1].TvShowRecommendations))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(televisionActionCreators.similarShowsError("No similar shows found"))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveMoviesByGenre: resObj => ({
        type: moviesByGenreSuccess,
        moviesByGenre: resObj
    }),

    moviesByGenreError: (message) => ({
        type: moviesByGenreFailure,
        moviesByGenreError: message
    }),

    getMoviesByGenre: (body) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_TELEVISION_API_URL) + '/Television/api/GetMoviesByGenres', 'POST', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(televisionActionCreators.moviesByGenreError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(televisionActionCreators.receiveMoviesByGenre(result[1].MovieRecommendations))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(televisionActionCreators.moviesByGenreError('No movies found'))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveShowsByGenre: resObj => ({
        type: showsByGenreSuccess,
        showsByGenre: resObj
    }),

    showsByGenreError: (message) => ({
        type: showsByGenreFailure,
        showsByGenreError: message
    }),

    getShowsByGenre: (body) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_TELEVISION_API_URL) + '/Television/api/GetTvShowsByGenres', 'POST', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(televisionActionCreators.showsByGenreError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(televisionActionCreators.receiveShowsByGenre(result[1].TvShowRecommendations))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(televisionActionCreators.showsByGenreError('No shows found'))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveMoviesByActor: resObj => ({
        type: moviesByActorSuccess,
        moviesByActor: resObj
    }),

    moviesByActorError: (message) => ({
        type: moviesByActorFailure,
        moviesByActorError: message
    }),

    getMoviesByActor: (body) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_TELEVISION_API_URL) + '/Television/api/GetMoviesByActors', 'POST', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(televisionActionCreators.moviesByActorError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(televisionActionCreators.receiveMoviesByActor(result[1].MovieRecommendations))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(televisionActionCreators.moviesByActorError('No movies found'))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    }
};


export const reducer = (state, action) => {

    state = state || initialState;
    switch (action.type) {
        case searchActorsSuccess:
            return Object.assign({}, state, {
                actors: action.actors,
                searchActorsError: action.searchActorsError
            })
        case searchActorsFailure:
            return Object.assign({}, state, {
                searchActorsError: action.searchActorsError
            })
        case searchMoviesSuccess:
            return Object.assign({}, state, {
                movies: action.movies,
                searchMoviesError: action.searchMoviesError
            })
        case searchMoviesFailure:
            return Object.assign({}, state, {
                searchMoviesError: action.searchMoviesError
            })
        case searchShowsSuccess:
            return Object.assign({}, state, {
                shows: action.shows,
                searchShowsError: action.searchShowsError
            })
        case searchShowsFailure:
            return Object.assign({}, state, {
                searchShowsError: action.searchShowsError
            })
        case getMovieGenresSuccess:
            return Object.assign({}, state, {
                movieGenres: action.movieGenres,
                getMovieGenresError: action.getMovieGenresError
            })
        case getMovieGenresFailure:
            return Object.assign({}, state, {
                getMovieGenresError: action.getMovieGenresError
            })
        case getTvShowGenresSuccess:
            return Object.assign({}, state, {
                tvShowGenres: action.tvShowGenres,
                getTvShowGenresError: action.getTvShowGenresError
            })
        case getTvShowGenresFailure:
            return Object.assign({}, state, {
                getTvShowGenresError: action.getTvShowGenresError
            })
        case similarMoviesSuccess:
            return Object.assign({}, state, {
                similarMovies: action.similarMovies,
                similarMoviesError: action.similarMoviesError
            })
        case similarMoviesFailure:
            return Object.assign({}, state, {
                similarMoviesError: action.similarMoviesError
            })
        case similarShowsSuccess:
            return Object.assign({}, state, {
                similarShows: action.similarShows,
                similarShowsError: action.similarShowsError
            })
        case similarShowsFailure:
            return Object.assign({}, state, {
                similarShowsError: action.similarShowsError
            })
        case moviesByGenreSuccess:
            return Object.assign({}, state, {
                moviesByGenre: action.moviesByGenre,
                moviesByGenreError: action.moviesByGenreError
            })
        case moviesByGenreFailure:
            return Object.assign({}, state, {
                moviesByGenreError: action.moviesByGenreError
            })
        case showsByGenreSuccess:
            return Object.assign({}, state, {
                showsByGenre: action.showsByGenre,
                showsByGenreError: action.showsByGenreError
            })
        case showsByGenreFailure:
            return Object.assign({}, state, {
                showsByGenreError: action.showsByGenreError
            })
        case moviesByActorSuccess:
            return Object.assign({}, state, {
                moviesByActor: action.moviesByActor,
                moviesByActorError: action.moviesByActorError
            })
        case moviesByActorFailure:
            return Object.assign({}, state, {
                moviesByActorError: action.moviesByActorError
            })
        default:
            return state
    }
};