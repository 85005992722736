import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import Home from './components/Home';
import SlowTelevision from './components/slow/television/Television';
import SlowMusic from './components/slow/music/Music';
import Activity from './components/activities/Activity';
import SlowPodcast from './components/slow/podcasts/Podcast';
import './custom.css';
import './non-mobile.css';
import './slow.css';
import './mobile.css';

export default class App extends Component {
    static displayName = App.name;
    constructor(props) {
        super(props);
        this.state = {}

        this.handleNav = this.handleNav.bind(this);
    }

    componentDidMount() {
        this.setState({ iOS: this.iOS() })
    }

    iOS() {
        return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].some(ios => navigator.userAgent.includes(ios))
            // iPad on iOS 13 detection
            || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }

    handleNav(component) {
        if (!this.state.transitioning && component !== window.location.pathname) {
            const self = this;
            this.setState({ transitioning: true }, () => {
                const container = document.getElementById('full-content');
                if (container) {
                    container.classList.add('fade-out');
                }

                setTimeout(function () {
                    self.setState({ transitioning: false })
                    window.location.pathname = component
                }, 2000);
            })
        }
    }

    render() {
        return (
            <Layout handleNav={this.handleNav}>
                <Route
                    exact path='/'
                    render={(props) => (
                        <Home {...props} handleNav={this.handleNav} title="RecommendMe" />
                    )}
                />
                <Route
                    path='/music'
                    render={(props) => (
                        <SlowMusic {...props} title="RecommendMe - Music" iOS={this.state.iOS} />
                    )}
                />
                <Route
                    path='/television'
                    render={(props) => (
                        <SlowTelevision {...props} title="RecommendMe - Television" iOS={this.state.iOS} />
                    )}
                />
                <Route
                    path='/podcasts'
                    render={(props) => (
                        <SlowPodcast {...props} title="RecommendMe - Podcasts" iOS={this.state.iOS} />
                    )}
                />
                {/*                <Route
                    path='/activities'
                    render={(props) => (
                        <Activity {...props} title="RecommendMe - Activities" />
                    )}
                />
*/}            </Layout>
        );
    }
}
