import { publicRequest } from '../services/request.service'

const API_URL = window.env ? window.env.API_SERVER : '';
const NO_CONTENT = 2;
const REQUEST_FAILURE = 1;
const REQUEST_SUCCESS = 0;

const activityRecommendationsSuccess = "ACTIVITY_RECOMMENDATIONS_SUCCESS";
const activityRecommendationsFailure = "ACTIVITY_RECOMMENDATIONS_FAILURE";

const initialState = {
    activityRecommendations: [],
    activityRecommendationsError: ''
};

export const activityActionCreators = {

    receiveActivityRecommendations: resObj => ({
        type: activityRecommendationsSuccess,
        activityRecommendations: resObj
    }),

    activityRecommendationsError: (message) => ({
        type: activityRecommendationsFailure,
        activityRecommendationsError: message
    }),

    getActivityRecommendations: (body) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_ACTIVITY_API_URL) + '/Activity/api/GetActivityRecommendations', 'POST', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(activityActionCreators.activityRecommendationsError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(activityActionCreators.receiveActivityRecommendations(result[1].ActivityRecommendations))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(activityActionCreators.receiveActivityRecommendations([]))
                        return Promise.reject('No recommendations found');
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    }
};


export const reducer = (state, action) => {

    state = state || initialState;
    switch (action.type) {
        case activityRecommendationsSuccess:
            return Object.assign({}, state, {
                activityRecommendations: action.activityRecommendations,
                activityRecommendationsError: action.activityRecommendationsError
            })
        case activityRecommendationsFailure:
            return Object.assign({}, state, {
                activityRecommendationsError: action.activityRecommendationsError
            })
        default:
            return state
    }
};